(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('SampleSubmitController', SampleSubmitController);

    SampleSubmitController.$inject = ['$state', '$rootScope', '$scope', '$localStorage',
                                        'Customer', 'Auth', 'Principal', 'ProfileService',
                                        'LoginService', 'CustomerOrder', 'Contact2ForUser',
                                        'CustomerOrderPdfService', 'AddressList', 'CityList', 'ZipList',
                                        'ProductCategory', 'ProductTypeByCategory', 'StrainNameSearch',
                                        'Assay', 'CustomerByUser', 'CustomPanel', 'ProductType', 'Panel', '$filter', 'SampleFilter', 'SweetAlert', 'AlertService','DataUtils'
                                    ];

    function SampleSubmitController($state, $rootScope, $scope, $localStorage,
                                    Customer, Auth, Principal, ProfileService,
                                    LoginService, CustomerOrder, Contact2ForUser,
                                    CustomerOrderPdfService, AddressList, CityList, ZipList,
                                    ProductCategory, ProductTypeByCategory, StrainNameSearch, Assay,
                                    CustomerByUser, CustomPanel, ProductType, Panel, $filter, SampleFilter, SweetAlert, AlertService, DataUtils
                                    ) {

        var vm = this;
        var isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

        vm.rd = {};
        var rd = {};

        vm.comp = {};
        var comp = {};

        vm.food = {};
        vm.otherData = {}
        var food = {};

        vm.submitData = false;

        vm.company_discount = $rootScope.customer ? ($rootScope.customer.company_discount ? $rootScope.customer.company_discount : null) :  null;
        vm.show_tab = {
            comp: false,
            rd: false,
            analysis: false,
            fda: false
        };

        vm.active_tab = {
            comp: false,
            rd: false,
            analysis: false,
            fda: false
        }


        if($rootScope.customer) {
            vm.customer_type = $rootScope.customer.customer_type.toLowerCase();
            setAvailableTabs(vm.customer_type);
        }
        else {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.customer_type = data.businessType.name.toLowerCase();
                setAvailableTabs(vm.customer_type)
            }
            function onError(e) {
                console.log(e);
            }
        }
        // $('#submittingModal').modal('show'); ======================

        vm.switch_tab = function(tab) {
            angular.forEach(vm.active_tab, function(value, key) {
                vm.active_tab[key] = false;
            });

            vm.active_tab[tab] = true;
        }

        function setAvailableTabs(customer_type) {
                        switch(customer_type) {
                case 'cannabis' :
                    vm.show_tab.comp = true;
                    vm.show_tab.rd = true
                    // vm.show_tab.fda = true
                    // vm.active_tab.comp = true;
                    vm.active_tab.rd = true;
                    break;
                case 'hemp':
                    vm.show_tab.rd = true;
                    vm.active_tab.rd = true;
                    // vm.show_tab.fda = true
                    break;
                case 'food service':
                    vm.show_tab.analysis = true;
                    vm.active_tab.analysis = true;
                    break;
                case 'kratom':
                    vm.show_tab.analysis = true;
                    vm.active_tab.analysis = true;
                    vm.food.turnaround_time = '5 day (standard)';
                    break;
                case 'other':
                    vm.show_tab.analysis = true;
                    vm.active_tab.analysis = true;
                    break;
                case 'fda':
                    vm.show_tab.analysis = true;
                    vm.active_tab.analysis = true;
                    vm.show_tab.fda = true;
                    break;
                default:
                    vm.show_tab.analysis = true;
                    vm.active_tab.analysis = true;
                    break;
            }

            getUserEmails();
        }

        vm.give_focus = function(sample, attribute) {
            sample.focus[attribute] = true;
        }

        vm.lose_focus = function(sample, attribute) {
            sample.focus[attribute] = false;
        }

        vm.set_sample_lot_batch_number = function(sample, lot_batch_number) {
            sample.sample_lot_batch_number = lot_batch_number;
            sample.options.lot_batch_number = false;
            sample.focus.lot_batch_number = false;
        }

        vm.set_sample_name = function(sample, name) {
            sample.sample_name = name;
            sample.options.name = false;
            sample.focus.name = false;
        }

        vm.set_sample_autofill = function(sample, option) {
            if(option.name) {
                sample.sample_name = option.name;
            }

            if(option.product_category_id) {
                var category_index = findWithAttr(vm[sample.sample_order_type].categories, 'id', option.product_category_id)
                if(category_index != -1) {
                    sample.sample_category = vm[sample.sample_order_type].categories[category_index]
                    vm[sample.sample_order_type].populateTypes(sample)
                }
            }
            else {
                sample.sample_category = null;
            }

            if(option.product_type_id) {
                var type_index = findWithAttr(sample.sample_types, 'id', option.product_type_id)
                if(type_index != -1) {
                    sample.sample_type = sample.sample_types[type_index]
                }
            }
            else {
                sample.sample_type = null;
            }

            if(option.inhalable_edible) {
                var type_index = findWithAttr(vm.inhalable_edible_array , 'name', option.inhalable_edible)
                if(type_index != -1) {
                    sample.sample_inhalable = vm.inhalable_edible_array [type_index]
                }
            }
            else {
                sample.sample_inhalable = null;
            }
            sample.focus.autofill = false;
            checkPage(sample.sample_order_type);
        }

        vm.query_autofill = function(sample) {
            if(sample.sample_name.length > 2) {
                SampleFilter.autofill({attribute: 'name', keyword: sample.sample_name},
                    function(data) {
                        if(data.length == 0) {
                            sample.options.autofillSampleInfo = [];
                        }
                        else {
                            sample.options.autofill = data;
                        }
                    },
                    function(e) {
                        console.log(e);
                });
            }

            checkPage(sample.sample_order_type);
        }
        vm.query_sample_lot_batch_number = function(sample) {
            if(sample.sample_lot_batch_number.length > 2) {
                SampleFilter.attribute({attribute: 'lot_batch_number', keyword: sample.sample_lot_batch_number},
                    function(data) {
                        if(data.length == 0) {
                            sample.options.lot_batch_number = [];
                        }
                        else {
                            sample.options.lot_batch_number = data;
                        }
                    },
                    function(e) {
                        console.log(e);
                });
            }

            checkPage(sample.sample_order_type);
        }

        vm.query_sample_name = function(sample) {
            if(sample.sample_name.length > 2) {
                SampleFilter.attribute({attribute: 'name', keyword: sample.sample_name},
                    function(data) {
                        if(data.length == 0) {
                            sample.options.name = [];
                        }
                        else {
                            sample.options.name = data;
                        }
                    },
                    function(e) {
                        console.log(e);
                });
            }

            checkPage(sample.sample_order_type);
        }

        function checkPage(order_type) {
            switch(order_type) {
                case 'rd':
                    vm.rd.checkPageOne();
                    break;
                case 'comp':
                    vm.comp.checkPageOne();
                    break;
                case 'food':
                    vm.food.checkPageOne();
                    break;
            }
        }

        vm.const = {
            PLANT : 'POTENCY_PLANT',
            NON_PLANT: 'POTENCY_LCDAD_NON_PLANT'
        }

        vm.inhalable_edible_array  = [
            {
                'id': 1,
                'name': 'Inhalable',
                'value':'INHALABLE'
            },
            {
                'id': 2,
                'name': 'Non-Inhalable',
                'value':'NONINHALABLE'
            }
        ];

        function getCustomerData() {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
                 vm.company_discount = data.company_discount;
                 vm.order_type = vm.comp.customer_type == 'Cannabis' ? 'Compliance' : 'RnD';
                 // not currently being used
                //  vm.customerData.added_assays = JSON.parse(data.added_assays);

                 if (vm.customerData != null && vm.customerData.address != null) {
                     var partsOfStr = vm.customerData.address.split('\n');

                     vm.customerData.contact = partsOfStr[0];
                     vm.customerData.street = partsOfStr[1];
                     vm.customerData.city_state_zip = partsOfStr[2];
                 }

                 if(vm.customerData.qbAddress) {
                     vm.customerData.qbAddressArray = vm.customerData.qbAddress.split('\n');
                 }

                 if (vm.customerData.privateSampleByDefault == null) {
                	 vm.customerData.privateSampleByDefault = false;
                 }
                 vm.customerData.default_addresses = vm.customerData.default_addresses && JSON.parse(vm.customerData.default_addresses);
                 if(vm.customerData.default_addresses){
                    var addresses = vm.customerData.default_addresses
                    $scope.records.forEach(function(element){
                        if(element.id == addresses.distributionLocation){
                            vm.samplingAddress = createAddress(element.address, element.city, element.zipCode, element.distributorName, element.medicalLicence);                            
                        }

                        if(element.id == addresses.manufacturingLocation){
                            vm.manufacturingAddress = createAddress(element.address, element.city, element.zipCode, element.distributorName, element.medicalLicence);                            
                        }

                        if(element.id == addresses.pickupLocation){
                            vm.pickup_address = createInvoicingAddress(element.address, element.city, element.zipCode, element.distributorName, element.medicalLicence);                            
                        }

                        if(element.id == addresses.samplingLocation){
                            vm.pickup_address = createInvoicingAddress(element.address, element.city, element.zipCode, element.distributorName, element.medicalLicence);                            
                        }

                        if(element.id == addresses.cultivationLocation){
                            vm.manufacturingAddress = createAddress(element.address, element.city, element.zipCode, element.distributorName, element.medicalLicence);                            
                        }
                    })
                    var distribution = $scope.records.find(function(record){
                        return record.id == addresses.distributionLocation
                    })
                    if (distribution) {
                      vm.fullLocationComp = distribution.fullLocation;
                    }
                    var manufacture = $scope.records.find(function(record){
                      return record.id == addresses.manufacturingLocation
                    })
                    if (manufacture) {
                      vm.fullLocationManufactur = manufacture.fullLocation;
                    }

                    var pickup = $scope.records.find(function(record){
                        return record.id == addresses.pickupLocation
                    })
                    if (pickup) {
                      vm.fullLocationPickup = pickup.fullLocation;
                    }

                    var sampling = $scope.records.find(function(record){
                        return record.id == addresses.samplingLocation
                    })
                    if (sampling) {
                      vm.fullLocationSampling = sampling.fullLocation;
                    }

                    var reporting = $scope.records.find(function(record){
                        return record.id == addresses.cultivationLocation
                    })
                    if (reporting) {
                      vm.fullLocationreporting = reporting.fullLocation;
                    }
                  }
                 if(vm.show_tab.rd) {
                     rd.getAssayData();
                     rd.getCategories();
                 }
                 else if(vm.show_tab.analysis) {
                     food.getAssayData();
                }

                getCurrentContact();
            }
            function onError(e) {
                console.log(e);
            }
        }

        rd.getCustomerData = function() {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
                 vm.company_discount = data.company_discount;
                 vm.order_type = vm.comp.customer_type == 'Cannabis' ? 'Compliance' : 'RnD';
                 // not currently being used
                //  vm.customerData.added_assays = JSON.parse(data.added_assays);

                 if (vm.customerData != null && vm.customerData.address != null) {
                     var partsOfStr = vm.customerData.address.split('\n');
                     vm.customerData.contact = partsOfStr[0];
                     vm.customerData.street = partsOfStr[1];
                     vm.customerData.city_state_zip = partsOfStr[2];
                 }

                 if (vm.customerData.privateSampleByDefault == null) {
                	 vm.customerData.privateSampleByDefault = false;
                 }

                rd.getAssayData();
            }
            function onError(e) {
                console.log(e);
            }
        }

        rd.getCategories = function() {
            ProductCategory.query({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.rd.categories = data;
                rd.getTypes();
                return;
            }

            function onError(e) {
                console.log(e);
                return;
            }
        }

        food.getCustomerData = function() {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
                 vm.company_discount = data.company_discount;
                 vm.order_type = vm.comp.customer_type == 'Cannabis' ? 'Compliance' : 'RnD';

                 if (vm.customerData != null && vm.customerData.address != null) {
                     var partsOfStr = vm.customerData.address.split('\n');
                     vm.customerData.contact = partsOfStr[0];
                     vm.customerData.street = partsOfStr[1];
                     vm.customerData.city_state_zip = partsOfStr[2];
                 }

                 if (vm.customerData.privateSampleByDefault == null) {
                     vm.customerData.privateSampleByDefault = false;
                 }

                food.getAssayData();
            }
            function onError(error) {

            }
        }

// **************************** START FOOD FUNCTIONS ****************************

        vm.food.step = 1;
        vm.food.pageOneValid = false;
        vm.food.pageTwoValid = false;
        vm.food.pageThreeValid = false;
        vm.food.customer_type = $rootScope.customer ? ($rootScope.customer.customer_type ? $rootScope.customer.customer_type : 'Cannabis') : 'Cannabis';
        vm.food.company_discount = $rootScope.customer ? ($rootScope.customer.company_discount ? $rootScope.customer.company_discount : null) :  null;
        vm.food.file = null;
        vm.food.specialInstructions = null;
        // vm.food.turnaroundTime = null;
        vm.food.turnaroundTime  = vm.food.turnaroundTimeOptions ? null : '10 Days (standard)';
        vm.food.estimated_report_date = null;
        vm.food.categories = null;
        vm.food.sampleArray = [];
        vm.food.all_types = null;
        vm.food.existing_user_added_assays = [];
        vm.food.manufacturing_address = null;
        vm.food.deliveryPreference = null;
        vm.food.orderPO = null;
        vm.food.pickup_arrival_date = null;
        vm.food.emails = '';
        vm.food.samples = [];
        vm.food.tests = [];
        vm.food.testsRequested = [];
        vm.food.testsRequestedCount = [];
        vm.food.total = 0;
        vm.food.mainCollapse = false;
        vm.food.subtotal = null;
        vm.food.total_discount = 0;
        vm.food.excel_assay_header = null;
        vm.food.processingExcelUpload = false;
        vm.food.assay_map = {};
        vm.food.assay_map_by_index = {};
        vm.food.all_assay_map_by_id = {};
        vm.food.all_tests = [];
        vm.food.showTests = {};
        vm.food.showTestsArray = [];
        vm.food.showTestResults = null;
        vm.food.panels = {};
        vm.food.panels.current_panel_index = null;
        vm.food.panels.new_panel_name = null;
        vm.food.panel_by_id_map = {}
        vm.food.current_sample_name = null;
        vm.food.customPanels = [];
        vm.food.qbenchPanels = [];
        vm.food.test_to_search = '';
        vm.food.other_analyses = [];
        vm.food.user_added_assays = [];
        vm.food.existing_user_added_assays = [];
        vm.food.number_of_freq_assays = 0;
        vm.food.submitting = false;
        vm.food_sample_matrix = null;

        function parseInstrument(instrument) {
            var matching_string = '';
            if(instrument) {
                if(instrument.length > 10) {
                    matching_string = instrument.match(/\(([^)]+)\)/);
                    if(matching_string) {
                        return matching_string[1];
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return instrument;
                }
            }
            else {
                return '';
            }
        }

        function splitString(string) {
            var string_array = null;
            if(string) {
                if(string.includes('|')) {
                    string_array = string.split('|').map( function(item) {
                        return item.trim();
                    });
                }
                else {
                    string_array = string.split(',').map( function(item) {
                        return item.trim();
                    });
                }
                return string_array;
            }
            else {
                return [];
            }
        }

        function addCommasToList(list) {
            if(list) {
                if(list.length > 1) {
                    for(var i = 0; i < list.length - 1; i++) {
                        list[i] += ','
                    }
                }
            }
        }


        function parseAssayInfoModalData(assay) {
            var base_price = null;
            var discount = null;
            var display_price = null;
            var method = null
            var instrument = null;
            var description = null;
            var duration = null;
            var minimum_quantity_required = null;
            var optimum_quantity_required = null;

            if(assay.basePrice) {
                base_price = assay.basePrice
            }

            if(vm.company_discount) {
                discount = vm.company_discount;
            }

            if(assay.duration) {
                duration = assay.duration;
            }

            if(assay.minimum_quantity_required) {
                minimum_quantity_required = assay.minimum_quantity_required;
            }

            if(assay.optimum_quantity_required) {
                optimum_quantity_required = assay.optimum_quantity_required;
            }

            if(assay.assay_customer) {
                if(assay.assay_customer.active) {
                    if(assay.assay_customer.basePrice) {
                        base_price = assay.assay_customer.basePrice;
                    }
                    if(assay.assay_customer.discount) {
                        discount = assay.assay_customer.discount;
                    }
                }
            }

            if(assay.instrument) {
                instrument = splitString(assay.instrument)
                instrument.forEach( function(item, index) {
                    if(parseInstrument(item) != ""){
                        instrument[index] = parseInstrument(item);
                    }
                });
                instrument = null
                addCommasToList(method);
            }

            if(assay.method) {
                method = splitString(assay.method);
                // addCommasToList(method);
            }

            if(assay.analysis_description) {
                description = assay.analysis_description;
            }

            if(base_price) {
                if(discount) {
                    display_price = base_price * (1 - (discount / 100));
                }
                else {
                    display_price = base_price;
                }
            }

            if(base_price == display_price) {
                display_price = null;
            }

            if(base_price) {
                base_price = $filter('currency')(base_price);
            }

            if(display_price) {
                display_price = $filter('currency')(display_price);
            }

            if(base_price || method || instrument || description || duration || minimum_quantity_required || optimum_quantity_required) {
                return({
                    base_price: base_price,
                    discount: discount,
                    display_price: display_price,
                    method: method,
                    instrument: instrument,
                    description: description,
                    duration: duration,
                    minimum_quantity_required: minimum_quantity_required,
                    optimum_quantity_required: optimum_quantity_required
                });
            }
            else {
                return null;
            }
        }

        food.serializeSamples = function(samples) {
            var serializedSamples = [];
            if (samples) {
                samples.forEach( function(sample){
                    serializedSamples.push({
                        description: sample.sample_name,
                        lotBatchNumber: sample.sample_lot_batch_number ? sample.sample_lot_batch_number : null,
                        timeOfCollection: sample.sample_time_of_collection_backend ? sample.sample_time_of_collection_backend : null,
                        comments: sample.sample_comments ? sample.sample_comments : null,
                        otherAnalysis: sample.sample_other_analyses ? formatOtherAnalyses(sample.sample_other_analyses) : null,
                        sampleTests: formatSampleTests(sample),
                        sampleTests_temp: formatTempSampleTests(sample,vm.food.other_analyses),
                        expirationDate: formatDate(sample.sample_expiration_date),
                        upc_number: sample.sample_upc_number ? sample.sample_upc_number : null,
                        number_of_units_submitted: sample.sample_number_of_units_submitted ? sample.sample_number_of_units_submitted : null,
                        sample_condition: sample.sample_condition ? sample.sample_condition : null,
                        sample_matrix: sample.food_sample_matrix ? sample.food_sample_matrix : null,
                        expected_potency: sample.sample_expected_potency ? sample.sample_expected_potency : null
                    });
                });
            }
            return serializedSamples;
        }

        food.serializePayload = function() {
            vm.food.submitting = true;
            var payload = {
                orderType: "FOOD",
                collection_type: null,
                customer_account_id: $localStorage.customerId,
                dateCreated: null,
                dateReceived: null,
                date_submitted: null,
                delivery_preference: formatDeliveryPreference(vm.food.deliveryPreference),
                estimated_arrival_date: formatDate(vm.food.pickup_arrival_date),
                partially_complete: null,
                poNumber: vm.food.orderPO,
                reporting_preference: null,
                sample_condition: null,
                sampledDate: formatDate(vm.food.pickup_arrival_date),
                samplingAddress: vm.manufacturingAddress ? vm.manufacturingAddress : null,
                invoicing_address: vm.invoicing_address ? vm.invoicing_address : vm.customerData.qbAddress,
                shipping_carrier: null,
                specialInstructions: vm.food.specialInstructions,
                state: null,
                pickup_address: vm.pickup_address ? vm.pickup_address : null,
                phone_number: vm.contact.phone ? vm.contact.phone : null,
                submitted_by: vm.food.emailsArray.join(),
                dynamic_turnaround_time: vm.food.turnaroundTime,
                id: null,
                samples: food.serializeSamples(vm.food.samples),
                tracking_number: vm.food.tracking_number
            }

            return payload;
        }
        function formatOtherAnalyses(other_analyses) {
            var requested_tests = [];
            angular.forEach(other_analyses, function(value, key) {
                    requested_tests.push(key);
            });

            return requested_tests;
        }       

        vm.food.submitOrder = function() {
            $rootScope.type = 'FOOD';
            CustomerOrder.save(food.serializePayload(), onSaveSuccess, onSaveError);
        }


        vm.food.cancelOrder = function() {
            location.reload();
        }

        vm.food.addSample = function() {
            vm.food.samples.push({
                sample_expanded: vm.food.mainCollapse,
                sample_order_type: 'food',
                sample_checked: null,
                sample_delete: false,
                sample_name: null,
                sample_lot_batch_number: null,
                sample_time_of_collection: null,
                sample_time_of_collection_frontend: null,
                sample_time_of_collection_backend: null,
                sample_expiration_date: null,
                sample_expiration_date_open: false,
                sample_calendar_open: false,
                sample_tests: [],
                sample_other_analyses: {},
                sample_has_tests: null,
                sample_panel_by_id: null,
                sample_panel_type: null,
                sample_current_panel_title: null,
                sample_upc_number: null,
                sample_number_of_units_submitted: null,
                sample_condition: null,
                focus: {
                    name: false,
                    lot_batch_number: false
                },
                options: {
                    name: [],
                    lot_batch_number: []
                }
            });
            vm.food.checkPageOne();
        }

        function formatDateTimeForOrder(date) {
            var dateObj = new Date(date);

            var months = dateObj.getMonth() + 1;
            var days = dateObj.getDate();
            var year = dateObj.getFullYear();
        
            var hours = dateObj.getHours();
            var minutes = dateObj.getMinutes();
        
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
        
            var formattedDate = `${months.toString().padStart(2, '0')}/${days.toString().padStart(2, '0')}/${year}`;
            var formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        
            return `${formattedDate} ${formattedTime}`;
        }


        food.formatSampleDateTime = function(sample) {
            var formattedDateTime = formatDateTimeForOrder(sample.sample_time_of_collection);
            sample.sample_time_of_collection_frontend = formattedDateTime;
            sample.sample_time_of_collection_backend = formattedDateTime;

            return;
        }

        vm.food.checkPageOne = function() {
            var date = null;
            if (vm.food.samples.length < 1) {
                vm.food.pageOneValid = false;
                return;
            }
            for(var i = 0; i < vm.food.samples.length; i++) {
                date = vm.food.samples[i].sample_time_of_collection;
                if (!vm.food.samples[i].sample_name) {
                    vm.food.pageOneValid = false;
                    return;
                }
            }
            vm.food.pageOneValid = true;
            return;
        }

        vm.food.checkPageTwo = function() {
            var other_analysis_is_empty = null;
            var sample_array_is_empty = null;

            for(var i = 0; i < vm.food.samples.length; i++) {
                if(vm.food.samples[i].sample_time_of_collection) {
                    food.formatSampleDateTime(vm.food.samples[i]);
                }

                other_analysis_is_empty = Object.keys(vm.food.samples[i].sample_other_analyses).length == 0;
                sample_array_is_empty = vm.food.sampleArray[i] ? Object.keys(vm.food.sampleArray[i]).length == 0 : true;

                if (sample_array_is_empty && other_analysis_is_empty){
                    vm.food.pageTwoValid = false;
                    return;
                }
                else {
                    if (sample_array_is_empty) {
                        if (Object.values(vm.food.samples[i].sample_other_analyses).indexOf(true) == -1) {
                            vm.food.pageTwoValid = false;
                            return
                        }
                    }
                    else if (other_analysis_is_empty) {
                        if (Object.values(vm.food.sampleArray[i]).indexOf(true) == -1) {
                            vm.food.pageTwoValid = false;
                            return;
                        }
                    }
                    else {
                        if ((Object.values(vm.food.samples[i].sample_other_analyses).indexOf(true) == -1) && (Object.values(vm.food.sampleArray[i]).indexOf(true) == -1)) {
                            vm.food.pageTwoValid = false;
                            return;
                        }
                    }
                }
            }
            vm.food.pageTwoValid = true;
            return;
        }

        vm.food.checkPageThree = function() {
            if (
                !vm.food.pickup_arrival_date ||
                !vm.food.deliveryPreference ||
                !($('#foodEmailRecepients').val().length > 0) ||
                (vm.food.deliveryPreference==='Anresco Pick-Up' && !vm.fullLocationPickup) ||
                (vm.fullLocationPickup && !vm.contact.phone)
            ) {
                vm.food.pageThreeValid = false;
                return;
            }
            vm.food.pageThreeValid = true;
            return;
        }

        vm.food.removeSample = function(index) {
            vm.food.samples.splice(index, 1);
            vm.food.sampleArray.splice(index, 1);
            food.resetDeleteCheckboxes();
            vm.food.checkPageOne();
        }

        vm.food.openCalendar = function(sample, key) {
            sample[key] = true;
        }

        vm.food.estimateReportDate = function(turnaround_time) {
            var days_length = turnaround_time.split(' days')[0];
            var days = null;

            if(days_length.includes('-')) {
                days = days_length.split('-')[1];
            }
            else {
                days = days_length;
            }

            days = parseInt(days);

            if (days && turnaround_time && vm.food.pickup_arrival_date) {
                var tomorrow = new Date(vm.food.pickup_arrival_date.getTime());
                for (var i = 0; i < days; i++) {
                    tomorrow.setDate(tomorrow.getDate() + 1)
                    if (tomorrow.getDay() == 0) {
                        tomorrow.setDate(tomorrow.getDate() + 1)
                    }
                    else if (tomorrow.getDay() == 6) {
                        tomorrow.setDate(tomorrow.getDate() + 2)
                    }
                }

                vm.food.estimated_report_date = tomorrow;
            }

            if (tomorrow) {
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                vm.food.estimated_report_date.day = days[tomorrow.getDay()]
                vm.food.estimated_report_date.month_day_year = tomorrow.toDateString().substring(4);
            }
            vm.food.checkPageThree();
        }

        vm.food.deleteChecked = function() {
            for(var i = vm.food.samples.length-1; i >= 0; i--) {
                if (vm.food.samples[i].sample_delete) {
                    vm.food.samples.splice(i, 1);
                }
            }
            food.resetDeleteCheckboxes();
            vm.food.checkPageOne();
        }

        food.resetDeleteCheckboxes = function() {
            $(':checkbox.food-delete-checkbox-header')[0].checked = false;
            vm.food.samples.forEach( function(sample) {
                sample.sample_delete = false;
            });
        }

        vm.food.clickCheckAllTest = function(col) {
            var testToAdd = vm.food.assay_map_by_index[col];
            var found = false;
            var selector = ':checkbox.assay-col-header-' + col;
            var test = {};

            vm.food.samples.forEach( function(sample, sample_index) {
                sample.sample_tests.forEach( function(test, test_index) {
                    if (test.id == testToAdd.id) {
                        found = true;
                        sample.sample_tests[test_index].checked = $(selector)[0].checked;
                        vm.food.sampleArray[sample_index][col] = $(selector)[0].checked;
                    }
                });
                if (!found) {
                    if (!vm.food.sampleArray[sample_index]) {
                        vm.food.sampleArray[sample_index] = {};
                        vm.food.sampleArray[sample_index][col] = $(selector)[0].checked;
                    }
                    test = Object.assign({}, testToAdd)
                    vm.food.sampleArray[sample_index][col] = $(selector)[0].checked;
                    test.checked = $(selector)[0].checked;
                    sample.sample_tests.push(test);
                }
                found = false;
               food.checkSampleContainsTrueTests(sample_index);
            });
            vm.food.checkPageTwo();
        }


        vm.food.clickCheckAllDelete = function() {
            vm.food.samples.forEach( function(sample) {
                sample.sample_delete = $(':checkbox.food-delete-checkbox-header')[0].checked;
            });
        }

        vm.food.clickDelete = function(index) {
            vm.food.samples[index].sample_delete = !vm.food.samples[index].sample_delete;
            $(':checkbox.comp-delete-checkbox-header')[0].checked = false;
        }

        vm.food.checkClick = function (sample, testToAdd, row, col) {
            var found = false;
            var test = null;
            $(':checkbox.assay-col-header-' + col)[0].checked = false;
            // check if test exists, if yes then checked = !checked
            for(var i = 0; i < sample.sample_tests.length; i++) {
                if (sample.sample_tests[i].id == testToAdd.id) {
                    found = true;
                    sample.sample_tests[i].checked = vm.food.sampleArray[row][col];
                    break;
                }
            }
            // if no, add that test
            if (!found) {
                test = Object.assign({}, testToAdd)
                test.checked = true;
                sample.sample_tests.push(test);
            }
           food.checkSampleContainsTrueTests(row);
            vm.food.checkPageTwo();
        }

        vm.food.incrementPage = function() {
            vm.food.step = vm.food.step + 1;

            if(vm.food.step == 2) {
                vm.food.checkPageTwo();
                setTimeout( function() {
                    // food.setInfoBoxCoordinates();
                }, 100);
            }
            else if (vm.food.step == 3) {
                vm.generateTurnaroundTimes(vm.food);
            }
            else if(vm.food.step == 4) {
                vm.food.emailsArray = vm.food.emails.split(',');
            }
            food.cleanUncheckedTests();
        }

        vm.food.decrementPage = function() {
            vm.food.step = vm.food.step - 1;

            if(vm.food.step == 2) {
                vm.food.checkPageTwo();
                setTimeout( function() {
                    // food.setInfoBoxCoordinates();
                }, 100);
            }
        }

        vm.food.removeSample = function(index) {
            vm.food.samples.splice(index, 1);
            vm.food.sampleArray.splice(index, 1);
            food.resetDeleteCheckboxes();
            vm.food.checkPageOne();
        }

        food.addAssay = function(test) {
            if(test.id != 13) {
                vm.food.showTests[test.display_name] = true;
                vm.food.showTestsArray.push(test.display_name);
                vm.food.tests.push(test);
            }
        }

        food.createAssayMaps = function() {
            vm.food.tests.forEach( function(test, index) {
                test.index = index;
                vm.food.assay_map[test.display_name] = [index, test]
                vm.food.assay_map_by_index[index] = test;
            });
        }

        food.getAssaysAddedByAnrescoBillingTab = function() {
            vm.customerData.assay_customer.forEach( function(assay_customer) {
                vm.food.all_tests.forEach( function(assay) {
                    if ((assay.id == assay_customer.assay_id) && assay_customer.active) {
                    // if (assay.id == assay_customer.assay_id) {
                        if(findWithAttr(vm.food.tests, 'id', assay.id) == -1) {
                            food.addAssay(assay);
                        }
                    }
                });
            });
        }

        food.getAssayData = function() {
            Assay.query({}, function(data) {
                if (data) {
                    var index = 0;
                    var test = null;
                    var add_assay = true;
                    data.forEach( function(assay) {
                        if(assay.id == 13){
                            vm.otherData = assay
                        }
                        if (add_assay) {
                            for(var i = 0; i < vm.customerData.assay_customer.length; i++) {
                                if (assay.id == vm.customerData.assay_customer[i].assay_id ) {
                                    assay.assay_customer = vm.customerData.assay_customer[i];
                                }
                            }

                            test = {
                                info_modal: parseAssayInfoModalData(assay),
                                portal_identifier: assay.portalIdentifier ? assay.portalIdentifier : null,
                                portal_client_type: assay.portal_client_type ? assay.portal_client_type : null,
                                display_name: assay.title,
                                reporting_title: assay.title ? assay.title : null,
                                base_price: assay.basePrice,
                                assay_customer: assay.assay_customer ? assay.assay_customer : null,
                                analysis_description: assay.analysis_description ? assay.analysis_description : null,
                                id: assay.id,
                                index: index
                            }

                            if(assay.portal_client_type) {
                                if(assay.portal_client_type == 'General') {
                                    food.addAssay(test);
                                    index++
                                }
                                else if ((vm.customer_type.includes('food') && assay.portal_client_type.toLowerCase().includes('food'))) {
                                    food.addAssay(test);
                                    index++;
                                }
                                else if ((vm.customer_type.includes('kratom') && assay.portal_client_type.toLowerCase().includes('kratom'))) {
                                    food.addAssay(test);
                                    index++;
                                }
                                else if ((vm.customer_type.includes('other') && assay.portal_client_type.toLowerCase().includes('other'))) {
                                    food.addAssay(test);
                                    index++;
                                }
                                else if(assay.portal_client_type.toLowerCase().includes('non-cannabis')) {
                                    food.addAssay(test);
                                    index++;
                                }
                            }
                            vm.food.all_assay_map_by_id[assay.id] = test;
                            vm.food.all_tests.push(test)
                        }
                        add_assay = true;
                    });
                }
                // getExistingAssays()
                food.getAssaysAddedByAnrescoBillingTab();

                // sorts tests arrray by how many times each test has been ordered
                vm.food.tests.sort( function(test_a ,test_b) {
                    if (!test_a.assay_customer) {
                        return 1;
                    }
                    else if (!test_b.assay_customer){
                        return -1;
                    }
                    else {
                        return test_b.assay_customer.count - test_a.assay_customer.count;
                    }
                });
                for (var i = 0; i < 5; i++) {
                    if (vm.food.tests[i] && vm.food.tests[i].assay_customer) {
                        if (vm.food.tests[i].assay_customer.count) {
                            vm.food.number_of_freq_assays++;
                        }
                        else {
                            break;
                        }
                    }
                    else {
                        break;
                    }
                }

                if (vm.food.number_of_freq_assays != vm.food.tests.length) {
                    var tests_sorted_by_name = vm.food.tests.splice(vm.food.number_of_freq_assays, vm.food.tests.length).sort( function(name_a, name_b) {
                        var comparison = 0;
                        name_a = name_a.display_name;
                        name_b = name_b.display_name;
                        if(name_a > name_b) {
                            comparison = 1
                        }
                        else if(name_b > name_a) {
                            comparison = -1
                        }
                        return comparison
                    });
                    vm.food.tests = vm.food.tests.concat(tests_sorted_by_name);
                }
                food.createAssayMaps();
            });

            // food.setInfoBoxCoordinates();
        }

        food.checkSampleContainsTrueTests = function(index) {
            if (Object.values(vm.food.sampleArray[index]).indexOf(true) == -1) {
                vm.food.samples[index].sample_has_tests = false;
                vm.food.samples[index].sample_panel_selected = false;
                vm.food.samples[index].sample_panel_type = false;
                vm.food.samples[index].sample_panel_by_id = false;
                vm.food.samples[index].sample_current_panel_title = false;

                return;
            }
            vm.food.samples[index].sample_has_tests = true;
            return;
        }

        // removes tests with checked: false, necessary for table on page 4
        food.cleanUncheckedTests = function() {
            if (vm.food.samples.length > 0) {
                vm.food.samples.forEach (function(sample) {
                    if (sample.sample_tests.length > 0) {
                        for(var i = sample.sample_tests.length - 1; i >= 0; i--){
                            if (!sample.sample_tests[i].checked){
                                sample.sample_tests.splice(i, 1);
                            }
                        }
                    }
                    angular.forEach(sample.sample_other_analyses, function(value, key) {
                        if (!value) {
                            delete sample.sample_other_analyses[key];
                        }
                    });
                });
            }
        }

        vm.food.filterAssays = function() {
            vm.food.showTestsArray = Object.keys(vm.food.showTests);
            vm.food.showTestResults = $filter('filter')(vm.food.showTestsArray, vm.food.test_to_search);
            angular.forEach(vm.food.showTests, function(value, key) {
                vm.food.showTests[key] = false;
            });
            vm.food.showTestResults.forEach( function(test) {
                vm.food.showTests[test] = true;
            });
            // food.setInfoBoxCoordinates();
        }

        //filtertering with the ID
        vm.food.customFilter = function(item) {
            var searchText = vm.food.test_to_search;
            if (/^\d+$/.test(searchText)) {
                var idMatches = (item.id.toString().indexOf(searchText) !== -1);
                var assayIdMatches = (item.assay_id && item.assay_id.toString().indexOf(searchText) !== -1);
                return idMatches || assayIdMatches;
            } else {
                return item.display_name.toLowerCase().includes(searchText.toLowerCase());
            }
        };

        vm.food.clear_test_to_search = function() {
            vm.food.test_to_search = '';
            vm.food.filterAssays();
        }

        vm.food.select_test_to_search = function(name) {
            vm.food.test_to_search = name;
            vm.food.filterAssays();
        }

        vm.food.clickCheckAllAddedTest = function(test, index) {
            var selector = ':checkbox.added-assay-col-header-' + index;
            vm.food.samples.forEach( function(sample) {
                sample.sample_other_analyses[test] = $(selector)[0].checked
            });

            vm.food.checkPageTwo();
        }

        food.showAllTests = function() {
            angular.forEach(vm.food.showTests, function(value, key) {
                vm.food.showTests[key] = true;
            });
            return;
        }

        vm.food.addOtherAnalysis = function() {
            vm.food.other_analyses.push(vm.food.test_to_search);
            vm.food.test_to_search = '';
            food.showAllTests()

            return;
        }

        vm.food.clearOtherAnalysis = function() {
            food.showAllTests();
            vm.food.other_analyses = [];
            vm.food.test_to_search = '';

            return;
        }

        food.getPanels = function(panel) {
            var assays_dict = [];

            if (panel) {
                panel.assays.forEach( function(assay) {
                    assays_dict.push({display_name: assay.title});
                });
            }

            return assays_dict;
        }

        food.setPanelsByIdMap = function() {
            vm.food.qbenchPanels.forEach( function(panel) {
                vm.food.panel_by_id_map[panel.id] = [panel.title, panel.assays];
            });
            vm.food.customPanels.forEach( function(panel) {
                vm.food.panel_by_id_map[panel.id] = [panel.title, panel.assays];
            });
        }

        food.pushPanel = function(panel) {
            var assays_dict = food.getPanels(panel);

            // if (food.allPanelAssaysPresent(assays_dict)) {
                vm.food.qbenchPanels.push({
                    title: panel.title,
                    assays: assays_dict,
                    id: panel.id,
                    type: 'qbench_panel',
                });
            // }
        }

        food.allPanelAssaysPresent = function(assays_dict) {
            var all_assays_present = true;
            assays_dict.forEach( function(assay) {
                if (vm.food.showTestsArray.indexOf(assay.display_name) == -1) {
                    all_assays_present = false;
                }
           });
           return all_assays_present;
        }

        food.getQbenchPanels = function() {
            Panel.query({},
                function(data) {
                    data.forEach( function(panel) {
                        if (panel.portalIdentifier && panel.panel_type) {
                            if (panel.panel_type == 'General') {
                               food.pushPanel(panel);
                            }
                            else if (panel.panel_type.toLowerCase().includes('food')) {
                                food.pushPanel(panel);
                            }
                        }
                    });
                    food.getPanelsByCustomer();

                    return;
                },
                function(e) {
                    console.log(e);
                    return;
                }
            );
        }

        food.getPanelsByCustomer = function() {
            CustomPanel.query({},
                function(data) {
                    vm.food.customPanels = [];
                    data.forEach( function(panel) {
                        vm.food.customPanels.push({
                            title: panel.title,
                            assays: food.getPanels(panel),
                            id: panel.id,
                            type: 'custom_panel'
                        });
                    });
                    food.setPanelsByIdMap();
                },
                function(e){
                    console.log(e);
                }
            );
        }

        vm.food.removePanelFromSample = function(sampleIndex) {

            Object.keys(vm.food.sampleArray[sampleIndex]).forEach( function(key) {
                vm.food.sampleArray[sampleIndex][key] = false;
            });

            vm.food.tests.forEach( function(test, index) {
                $(':checkbox.assay-col-header-' + index)[0].checked = false;
            })

            vm.food.current_sample_name = false;
            vm.food.samples[sampleIndex].sample_current_panel_title = null;
            vm.food.samples[sampleIndex].sample_panel_selected = null;
            vm.food.samples[sampleIndex].sample_panel_by_id = null;
            vm.food.samples[sampleIndex].sample_panel_type = null;
            vm.food.samples[sampleIndex].sample_has_tests = null;
            vm.food.samples[sampleIndex].sample_tests = [];

            vm.food.checkPageTwo();
        }

        vm.food.setPanel = function(sample, panelId, panel_type) {
            var sampleIndex = null;
            vm.food.current_sample_name = sample.sample_name;

            if (vm.food.samples.findIndex(food.isSample) > -1) {
                sampleIndex = vm.food.samples.findIndex(food.isSample);
            }

            var temp = null;
            if (vm.food.sampleArray[sampleIndex]) {
                delete vm.food.sampleArray[sampleIndex];
            }
            vm.food.sampleArray[sampleIndex] = {};

            if (vm.food.samples[sampleIndex].sample_tests) {
                vm.food.samples[sampleIndex].sample_tests = [];
            }
            vm.food.panel_by_id_map[panelId][1].forEach( function(assay) {
                temp = Object.assign({}, vm.food.assay_map[assay.display_name][1]);
                temp.checked = true;
                vm.food.samples[sampleIndex].sample_tests.push(temp);
                vm.food.sampleArray[sampleIndex][vm.food.assay_map[assay.display_name][1].index] = true;
            });

            vm.food.samples[sampleIndex].sample_panel_selected = panelId;
            vm.food.samples[sampleIndex].sample_panel_type = panel_type;
            vm.food.samples[sampleIndex].sample_current_panel_title = vm.food.panel_by_id_map[panelId][0];
            vm.food.current_sample_name = null;
            vm.food.checkPageTwo();

            return;
        }

        vm.food.savePanel = function(sampleIndex, step) {
            if (step == 1) {
                vm.food.panels.current_panel_index = sampleIndex;
                return;
            }

            else if (step == 2) {
                var assays = [];
                Object.keys(vm.food.sampleArray[vm.food.panels.current_panel_index]).forEach( function(key) {
                    if (vm.food.sampleArray[vm.food.panels.current_panel_index][key]) {
                        assays.push({id: vm.food.assay_map_by_index[key].id})
                    }
                });

                if (vm.food.panels.new_panel_name && assays) {
                    CustomPanel.create({
                            title: vm.food.panels.new_panel_name,
                            assays: assays
                        },
                        function() {
                            vm.food.samples[vm.food.panels.current_panel_index].sample_has_tests = true;

                            food.getPanelsByCustomerAndSetPanel(vm.food.panels.current_panel_index);
                        },
                        function(e) {
                            console.log(e);
                        }
                    );
                }
                return;
            }
        }

        food.getPanelsByCustomerAndSetPanel = function(sampleIndex) {
            CustomPanel.query({},
                function(data) {
                    vm.food.customPanels = [];
                    var panel_id = null
                    data.forEach( function(panel) {
                        vm.food.customPanels.push({
                            title: panel.title,
                            assays:food.getPanels(panel),
                            id: panel.id,
                            type: 'custom_panel'
                        });
                    });
                    food.setPanelsByIdMap();
                    panel_id = vm.food.customPanels[vm.food.customPanels.length-1].id;
                    vm.food.setPanel(vm.food.samples[sampleIndex], panel_id, 'custom_panel');
                    vm.food.samples[sampleIndex].sample_panel_by_id = panel_id;
                    vm.food.samples[sampleIndex].sample_has_tests = false;
                    vm.food.panels = {};

                },
                function(e){
                    console.log(e);
                }
            );
        }


        vm.food.updatePanel = function(sampleIndex, panelId) {
            var assays = [];
            Object.keys(vm.food.sampleArray[sampleIndex]).forEach( function(key) {
                if(vm.food.sampleArray[sampleIndex][key]) {
                    assays.push({id: vm.food.assay_map_by_index[key].id})
                }
            });
            CustomPanel.update({
                id: panelId,
                assays: assays
            },
                function() {
                    vm.food.samples[sampleIndex].sample_has_tests = false;
                    food.getPanelsByCustomer();
                },
                function(e) {
                    console.log(e);
                }
            );
            return;
        }

        vm.food.deletePanel = function(panelId) {
            vm.food.customPanels = [];

            CustomPanel.delete({id: panelId},
                function() {
                    food.getPanelsByCustomer();
                },
                function(e) {
                    console.log(e);
                }
            );

            return;
        }

        vm.showQbenchPanels = $localStorage.settings || {
            settings: {
                sample_submit:{
                    show_anresco_panels: true
                }
            }
        }

        vm.toggleQbenchPanelVisibility = function(){
            vm.showQbenchPanels.settings.sample_submit.show_anresco_panels = !vm.showQbenchPanels.settings.sample_submit.show_anresco_panels;
            $localStorage.settings = vm.showQbenchPanels;
        };

        food.isSample = function(object) {
            return object.sample_name == vm.food.current_sample_name;
        }

        vm.food.uploadExcel = function() {
            $('#food_upload_excel').click();
        }

        vm.food.collapseAll = function() {
            vm.food.mainCollapse = !vm.food.mainCollapse;
            if (vm.food.samples.length != 0) {
                vm.food.samples.forEach( function(sample) {
                    sample.sample_expanded = vm.food.mainCollapse;
                });
            }
        }

        vm.food.downloadExcel = function() {
            food.generateBlob();
        }

        food.getWorkbook = function() {
            return new Promise(function (resolve, reject) {
                var req = new XMLHttpRequest();
                var url = isLocal ? 'content/files/excel/food_analysis.xlsx' : 'content/files/food_analysis.xlsx';

                req.open("GET", url, true);
                req.responseType = "arraybuffer";
                req.onreadystatechange = function () {
                    if (req.readyState === 4){
                        if (req.status === 200) {
                            resolve(XlsxPopulate.fromDataAsync(req.response));
                        } else {
                            reject("Received a " + req.status + " HTTP code.");
                        }
                    }
                };
                req.send();
            });
        }

        food.generate = function(type) {
            return food.getWorkbook()
                .then(function (workbook) {
                    var r = workbook.sheet(0).range("A1:AY1");
                    
                    var lot_field_name = $rootScope.customer.customer_id == 3868 ? 'Lot Number*' : 'Lot/Batch #';
                    var header = ['Sample Description*', 'Sample Condition', lot_field_name,  'Expiration Date (MM/DD/YYYY)', 'UPC Number', '# of Units Submitted', 'Sample Matrix', 'Product Specifications', 'Comments'];

                    var dropdownOptions = {}
                    var options = ['Yes', 'No']

                    vm.food.tests.forEach( function(test) {
                        header.push(test.display_name)
                        var headerName = test.display_name
                        dropdownOptions[headerName] = options;
                    });
                    
                    header.forEach(function (headerName, index) {
                        var colLetter = getColumnLetter(index);
                        if (dropdownOptions[headerName]) {
                            applyDropdown(workbook.sheet(0), colLetter, dropdownOptions[headerName]);
                        }
                    });

                    r.value([header]);
                    workbook.sheet(0).row(1).style("bold", true);

                    return workbook.outputAsync({ type: type });
                });
        }

        food.generateBlob = function() {
            return food.generate()
                .then(function (blob) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, "Analysis Sample Submission.xlsx");
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = "Analysis Sample Submission.xlsx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }
                })
                .catch(function (err) {
                    alert(err.message || err);
                    throw err;
                });
        }

        // Normalizes header text for consistent matching.
        // Converts to lowercase, trims whitespace, removes text in parentheses, and removes trailing asterisk.
        food.normalizeHeader = function(headerText) {
            var normalized;
            if (typeof headerText !== 'string') {
                return ''; // Return empty string if input is not a string
            }
            normalized = headerText.toLowerCase();
            normalized = normalized.trim();
            normalized = normalized.replace(/\(.*?\)/g, ''); // Remove text within parentheses
            normalized = normalized.replace(/\*$/, ''); // Remove trailing asterisk
            normalized = normalized.trim(); // Trim again after removals
            return normalized;
        };

        // Parses a date value from an Excel cell, handling various formats. (Corrected Version)
        food.parseDateFromCell = function(sheet, rowIndex, colIndex, rawValue) {
            var cell, date, cellFormat, isDateFormat, baseDate, trimmed, dateRegex1, dateRegex2, parsedDate;
            try {
                // Get the cell object using 1-based indices
                cell = sheet.cell(rowIndex, colIndex);

                // Check if the cell exists and has a value before accessing properties
                if (cell && cell.value !== null && cell.value !== undefined) {

                    // Check if it's explicitly a date type in Excel (using property access)
                    if (cell.type === 'd') {
                        // Create Date object from the cell's raw value (using property access)
                        date = new Date(cell.value);
                        // Check if the parsed date is valid
                        if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime())) {
                            return date;
                        }
                    }

                    // Check if it's a number that might be a date based on format
                    // Use rawValue here as cell.value might already be a Date object if type was 'd'
                    if (typeof rawValue === 'number') {
                        // Get cell format (style IS a function)
                        cellFormat = (cell.style && typeof cell.style === 'function' && cell.style("numberFormat")) || '';
                        isDateFormat = cellFormat.toLowerCase().indexOf('yy') !== -1 ||
                                    cellFormat.toLowerCase().indexOf('mm') !== -1 ||
                                    cellFormat.toLowerCase().indexOf('dd') !== -1;

                        if (isDateFormat) {
                            // Handle both 1900 and 1904 date systems
                            baseDate = (sheet.workbook &&
                                        sheet.workbook.Workbook &&
                                        sheet.workbook.Workbook.WBProps &&
                                        sheet.workbook.Workbook.WBProps.date1904)
                                        ? 24107 : 25567;
                            // Adjust calculation slightly for potential Excel base date differences
                            // Use Math.round to avoid floating point issues with date serial numbers
                            date = new Date(Math.round((rawValue - baseDate - 1) * 86400 * 1000));

                            // Check if the parsed date is valid
                            if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime())) {
                                // Basic sanity check for reasonable date range (e.g., year > 1900)
                                if (date.getFullYear() > 1900) {
                                    return date;
                                }
                            }
                        }
                    }
                } // End check for cell existence and value

                // Handle string dates with specific formats (MM/DD/YYYY, MM-DD-YYYY, YYYY/MM/DD, YYYY-MM-DD)
                // Use rawValue here as it might be a string even if the cell exists
                if (typeof rawValue === 'string') {
                    trimmed = rawValue.trim();
                    // Matches formats like MM/DD/YYYY, MM-DD-YYYY
                    dateRegex1 = /^\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}$/;
                    // Matches formats like YYYY/MM/DD, YYYY-MM-DD
                    dateRegex2 = /^\d{4}[\/\-]\d{1,2}[\/\-]\d{1,2}$/;

                    if (dateRegex1.test(trimmed) || dateRegex2.test(trimmed)) {
                        parsedDate = new Date(trimmed);
                        // Check if the parsed date is valid
                        if (Object.prototype.toString.call(parsedDate) === "[object Date]" && !isNaN(parsedDate.getTime())) {
                            return parsedDate;
                        }
                    }
                }

            } catch (e) {
                console.error("Error parsing date for cell (" + rowIndex + "," + colIndex + ") with value:", rawValue, e);
                // Fall through to return rawValue if any error occurs
            }

            // Return the original value if no valid date was parsed or if rawValue was null/undefined initially
            return rawValue;
        }; // End of food.parseDateFromCell

        vm.food.handleExcel = function() {
            var fileInput = $('#food_upload_excel')[0];
            var file = fileInput.files[0];
            if (!file) return Promise.reject("You must select a file.");
            
            vm.food.processingExcelUpload = true;
            
            XlsxPopulate.fromDataAsync(file)
            .then(function(workbook) {
                return new Promise(function(resolve) {
                    $scope.$apply(function() {
                        var sheet = workbook.sheet(0);
                        var values = sheet.usedRange().value();
                        food.extractExcelValues(values, sheet);
                        resolve();
                    });
                });
            })
            .then(function() {
                // Only clear after processing is complete
                fileInput.value = '';
                vm.food.processingExcelUpload = false;
                $scope.$apply();
            })
            .catch(function(error) {
                fileInput.value = '';
                vm.food.processingExcelUpload = false;
                $scope.$apply();
                return Promise.reject(error);
            });
        }
        
        food.createExcelAssayMap = function(file) {
            for( var i = vm.food.excel_header_offset; i < file[0].length; i++) {
                if (file[0][i]) {
                    vm.food.excel_assay_index_map[i] = vm.food.assay_map[file[0][i]];
                }
                else {
                    break;
                }
            }
        }

        food.createNewSample = function() {
            return ({
                sample_checked: null,
                sample_delete: false,
                sample_name: null,
                sample_lot_batch_number: null,
                sample_time_of_collection: null,
                sample_time_of_collection_frontend: null,
                sample_time_of_collection_backend: null,
                sample_expiration_date: null,
                sample_expiration_date_open: false,
                sample_tests: [],
                sample_other_analyses: {},
                sample_has_tests: null,
                sample_panel_by_id: null,
                sample_panel_type: null,
                sample_current_panel_title: null,
                focus: {
                    name: false,
                    lot_batch_number: false,
                    autofill: false
                },
                options: {
                    name: [],
                    lot_batch_number: [],
                    autofill: []
                }
            });
        }
        
// Extracts data from the Excel file based on headers.
food.extractExcelValues = function(file, sheet) {
    var headerRow, standardHeaderMapping, requiredSampleProperties,
        headerIndexMap, assayColumnMap, missingHeaders, i, dataRow,
        sampleNameIndex, sample, sampleProperty, columnIndex, rawValue,
        assayObject, cellValue, testToAdd, originalAssayIndex,
        normalizedHeader, prop, originalHeaderKey;

    // --- Configuration ---
    standardHeaderMapping = {
        'sample description': 'sample_name',
        'sample condition': 'sample_condition',
        'lot/batch #': 'sample_lot_batch_number',
        'expiration date': 'sample_expiration_date', // Requires special date parsing
        'upc number': 'sample_upc_number',
        '# of units submitted': 'sample_number_of_units_submitted',
        'sample matrix': 'food_sample_matrix',
        'product specifications': 'sample_expected_potency',
        'comments': 'sample_comments'
    };

    // Apply customer-specific override for Lot Number
    if ($rootScope.customer && $rootScope.customer.customer_id == 3868) {
        standardHeaderMapping['lot number'] = 'sample_lot_batch_number';
        // Remove the generic one if the specific one exists to avoid ambiguity if both headers are present
        if (standardHeaderMapping['lot/batch #'] && standardHeaderMapping['lot number']) {
            delete standardHeaderMapping['lot/batch #'];
        }
    }

    // Define which sample properties correspond to required headers
    requiredSampleProperties = ['sample_name']; // Add others if needed, e.g., 'sample_lot_batch_number'

    // --- Header Processing ---
    if (!file || file.length === 0) {
        AlertService.error("Upload failed. The Excel file is empty.");
        vm.food.processingExcelUpload = false;
        return;
    }
    headerRow = file[0];
    headerIndexMap = {}; // { sample_property: colIndex }
    assayColumnMap = {}; // { colIndex: assayObject }

    // Map headers to indices and assays
    angular.forEach(headerRow, function(header, colIndex) {
        var normalizedHeader = food.normalizeHeader(header);
        var sampleProperty = standardHeaderMapping[normalizedHeader];
        var matchedAssay = null;

        if (sampleProperty) {
            // Store mapping from internal property name to column index
            headerIndexMap[sampleProperty] = colIndex;
        } else if (normalizedHeader) { // Only check assays if header is not empty after normalization
            // Check assays - prioritize reporting_title, then display_name
            angular.forEach(vm.food.tests, function(test) {
                if (matchedAssay) return; // Already found a match
                if (test.reporting_title && food.normalizeHeader(test.reporting_title) === normalizedHeader) {
                    matchedAssay = test;
                }
            });
            if (!matchedAssay) {
                angular.forEach(vm.food.tests, function(test) {
                    if (matchedAssay) return; // Already found a match
                    if (food.normalizeHeader(test.display_name) === normalizedHeader) {
                        matchedAssay = test;
                    }
                });
            }

            if (matchedAssay) {
                assayColumnMap[colIndex] = matchedAssay;
            }
            // else: Header is unrecognized - ignore silently
        }
    });

    // --- Validate Required Headers ---
    missingHeaders = [];
    angular.forEach(requiredSampleProperties, function(prop) {
        if (!headerIndexMap.hasOwnProperty(prop)) {
            // Find the original-like header key for the error message
            originalHeaderKey = "Unknown Required Header"; // Default
            for (var key in standardHeaderMapping) {
                if (standardHeaderMapping[key] === prop) {
                    // Attempt to reconstruct a user-friendly header name (best effort)
                    originalHeaderKey = key.replace(/\b\w/g, function(l){ return l.toUpperCase() }); // Capitalize words
                    if (prop === 'sample_name') originalHeaderKey += '*'; // Add back asterisk if needed
                    // Add more specific reconstructions if necessary
                    break;
                }
            }
            missingHeaders.push(originalHeaderKey);
        }
    });

    if (missingHeaders.length > 0) {
        AlertService.error("Upload failed. Missing required column(s): " + missingHeaders.join(', '));
        vm.food.processingExcelUpload = false; // Reset flag
        return; // Stop processing
    }

    // --- Data Row Processing ---
    vm.food.samples = []; // Clear existing samples before adding new ones from Excel
    vm.food.sampleArray = []; // Clear existing sample array

    for (i = 1; i < file.length; i++) {
        dataRow = file[i];
        sampleNameIndex = headerIndexMap['sample_name']; // Get index for sample name

        // Stop processing if the required sample name cell is empty for this row
        if (!dataRow || dataRow.length === 0 || !dataRow[sampleNameIndex]) {
            break;
        }

        sample = food.createNewSample(); // Create a new sample structure
        vm.food.sampleArray[i - 1] = {}; // Initialize assay tracking for this sample

        // Populate standard fields using the headerIndexMap
        for (sampleProperty in headerIndexMap) {
            if (headerIndexMap.hasOwnProperty(sampleProperty)) {
                columnIndex = headerIndexMap[sampleProperty];
                rawValue = (dataRow.length > columnIndex) ? dataRow[columnIndex] : null; // Handle rows shorter than header

                // Handle empty cells - assign null
                if (rawValue === undefined || rawValue === '') {
                    rawValue = null;
                }

                // Special handling for dates
                if (sampleProperty === 'sample_expiration_date' && rawValue !== null) {
                    // Use 1-based indices for sheet interaction
                    sample.sample_expiration_date = food.parseDateFromCell(sheet, i + 1, columnIndex + 1, rawValue);
                } else {
                    sample[sampleProperty] = rawValue;
                }
            }
        }

        // Populate assays using the assayColumnMap
        for (columnIndex in assayColumnMap) {
            if (assayColumnMap.hasOwnProperty(columnIndex)) {
                assayObject = assayColumnMap[columnIndex];
                cellValue = (dataRow.length > columnIndex) ? dataRow[columnIndex] : null;

                // Check if the assay is marked as 'Yes' (case-insensitive)
                if (cellValue && typeof cellValue === 'string' && cellValue.trim().toLowerCase() === 'yes') {
                    testToAdd = angular.copy(assayObject); // Use angular.copy for safety
                    testToAdd.checked = true;
                    sample.sample_tests.push(testToAdd);
                    sample.sample_has_tests = true;

                    // Update the UI tracking array (vm.food.sampleArray) using the original assay index
                    // Find original index from vm.food.assay_map (created elsewhere)
                    if (vm.food.assay_map && vm.food.assay_map[assayObject.display_name]) {
                        originalAssayIndex = vm.food.assay_map[assayObject.display_name][0];
                        vm.food.sampleArray[i - 1][originalAssayIndex] = true;
                    } else {
                        console.warn("Could not find assay '" + assayObject.display_name + "' in vm.food.assay_map for UI update.");
                    }
                }
            }
        }

        vm.food.samples.push(sample); // Add the processed sample to the main list
    }

    // --- Finalization ---
    vm.food.checkPageOne(); // Validate page one based on newly added samples
    food.resetDeleteCheckboxes(); // Reset UI checkboxes
    // vm.food.processingExcelUpload = false; // Flag is reset in vm.food.handleExcel's .then/.catch
}; // End of food.extractExcelValues function


// **************************** END FOOD FUNCTIONS ****************************

        vm.comp.step = 1;
        vm.comp.pageOneValid = false;
        vm.comp.pageTwoValid = false;
        vm.comp.pageThreeValid = false;
        vm.comp.customer_type = $rootScope.customer ? ($rootScope.customer.customer_type ? $rootScope.customer.customer_type : 'Cannabis') : 'Cannabis';
        vm.comp.company_discount = $rootScope.customer ? ($rootScope.customer.company_discount ? $rootScope.customer.company_discount : null) :  null;
        vm.comp.file = null;
        vm.comp.specialInstructions = null;
        vm.comp.turnaroundTime = '3-4 day (standard)';
        vm.comp.estimated_report_date = null;
        vm.comp.categories = null;
        vm.comp.sampleArray = [];
        vm.comp.all_types = null;
        vm.comp.manufacturing_address = null;
        vm.comp.deliveryPreference = 'ANRESCO_PICK_UP';
        vm.comp.orderPO = null;
        vm.comp.pickup_arrival_date = null;
        vm.comp.emails = '';
        vm.comp.samples = [];
        vm.comp.tests = [];
        vm.comp.testsRequested = [];
        vm.comp.testsRequestedCount = [];
        vm.comp.mainCollapse = false;
        vm.comp.total = 0;
        vm.comp.subtotal = null;
        vm.comp.total_discount = 0;
        vm.comp.panels = {};
        vm.order_type = vm.comp.customer_type == 'Cannabis' ? 'Compliance' : 'RnD';
        vm.comp.existing_user_added_assays = [];
        vm.comp.phases = [];
        vm.comp.phase_map_by_name = {};
        vm.comp.submitting = false;


// **************************** START COMP FUNCTIONS ****************************

    vm.comp.cancelOrder = function() {
        location.reload(true);
    }

    vm.comp.uploadExcel = function() {
        $('#comp_upload_excel').click();
    }

    vm.comp.handleExcel = function() {
        var file = $('#comp_upload_excel')[0].files[0];
        if (!file) return Promise.reject("You must select a file.");
        XlsxPopulate.fromDataAsync(file)
        .then( function(workbook) {
            $scope.$apply(comp.extractExcelValues(workbook.sheet(0).usedRange().value()));
        });
    }

    comp.createPhaseMap = function() {
        vm.comp.phases.forEach( function(phase) {
            vm.comp.phase_map_by_name[phase.name] = phase;
        });
    }

    comp.createNewSample = function() {
        return ({
            sample_expanded: vm.comp.mainCollapse,
            sample_checked: null,
            sample_delete: false,
            sample_name: null,
            sample_lot_batch_number: null,
            sample_category: null,
            sample_type: null,
            sample_types: null,
            sample_inhalable: null,
            sample_metrc_id: null,
            sample_source_id: null,
            sample_strain_name: null,
            sample_strain_type: null,
            sample_label_claims: null,
            sample_expected_potency: null,
            sample_matrix: null,
            sample_product_harvest_batch: 'PRODUCT_BATCH',
            sample_product_batch_size: null,
            sample_manufacture_date_open: false,
            sample_manufacture_date: null,
            sample_harvest_date_open: false,
            sample_harvest_date: null,
            sample_unit_weight: null,
            sample_add_terpenoid_profile: false,
            sample_analyze_by_lcmsms: false,
            focus: {
                    name: false,
                    lot_batch_number: false,
                    autofill: false
                },
                options: {
                    name: [],
                    lot_batch_number: [],
                    autofill: []
                }
        });
    }

    comp.extractComplianceValues = function(row) {
        var sample = comp.createNewSample();

        if(!row.every(allEmpty)) {
            var sample_category = row[3] ? formatSampleCategory(row[3]) : null;
            sample.sample_matrix = row[0] ? vm.comp.phase_map_by_name[row[0]] : null;
            sample.sample_name = row[1] ? row[1] : null;
            sample.sample_lot_batch_number = row[2] ? row[2] : null;
            sample.sample_category = sample_category ? sample_category : null;
            sample.sample_types = sample_category ? sample_category.types : null;
            sample.sample_type = sample_category ? checkSampleType(sample_category.types, row[4]) : null;
            sample.sample_inhalable = row[5] ? formatInhalableFromImport(row[5]) : null;
            sample.sample_source_id = row[6] ? row[6] : null;
            sample.sample_strain_name = row[7] ? row[7] : null;
            sample.sample_strain_type = row[8] ? formatStrainType(row[8]) : null;
            sample.sample_label_claims = row[9] ? row[9] : null;
            sample.sample_expected_potency = row[10] ? row[10] : null;
            sample.sample_add_terpenoid_profile = row[11] ? (row[11] == 'Yes' ? true : false) : false;
            sample.sample_analyze_by_lcmsms = row[12] ? (row[12] == 'Yes' ? true : false) : false;
            sample.sample_product_harvest_batch = row[13] ? comp.formatBatchType(row[13]) : null;
            sample.sample_product_batch_size = row[14] ? row[14] : null;
            row[14] ? (sample.sample_product_harvest_batch == 'PRODUCT_BATCH' ? sample.sample_harvest_date = comp.formatExcelDate(row[15]) : sample.sample_manufacture_date = comp.formatExcelDate(row[15])) : angular.noop;
            sample.sample_product_harvest_batch ? angular.noop : sample.sample_product_harvest_batch = 'PRODUCT_BATCH';
            sample.sample_unit_weight = row[16] ? row[16] : null;
            sample.sample_expanded =  true;
            vm.comp.samples.push(sample);
        }
    }

   comp.extractExcelValues = function(file) {
        comp.createPhaseMap();

        for( var i = 1; i < file.length; i++) {
            comp.extractComplianceValues(file[i]);
        }

        vm.comp.checkPageOne();
        comp.resetDeleteCheckboxes();
    }

    comp.formatExcelDate = function(date) {
        var new_date = new Date(date);

        if (new_date != 'Invalid Date'){
            return new_date
        }
        else {
            return null;
        }
    }

    comp.formatBatchType = function(batchType) {
        if (batchType == 'Product Batch') {
            return 'PRODUCT_BATCH'
        }
        else {
            return 'HARVEST_BATCH'
        }
    }

    vm.comp.downloadExcel = function() {
        comp.generateBlob();
    }

    comp.generateBlob = function() {
        return comp.generate()
            .then(function (blob) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, "Compliance Sample Submission.xlsx");
                } else {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = "Compliance Sample Submission.xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }
            })
            .catch(function (err) {
                alert(err.message || err);
                throw err;
            });
    }

    comp.generate = function(type) {
        return comp.getWorkbook()
            .then(function (workbook) {
                var r = workbook.sheet(0).range("A1:P1");
                var header = ['Matrix*', 'Sample Name*', 'Lot/Batch #*', 'Product Category*', 'Product Type*', 'Inhalable/Non-Inhalable*', 'Source UID*', 'Strain Name', 'Strain Type', 'Label Claims', 'Product Specifications*', 'Add Terpene Profile', 'Analyze by LCMSMS', 'Batch Type*', 'Batch Size*', 'Batch Date', 'Unit Weight*'];

                r.value([header]);
                workbook.sheet(0).row(1).style("bold", true);
                workbook.sheet(1).hidden(true);

                return workbook.outputAsync({ type: type });
            });
    }

    comp.getWorkbook = function() {
        return new Promise(function (resolve, reject) {
            var req = new XMLHttpRequest();
            var url = isLocal ? 'content/files/excel/compliance.xlsx' : 'content/files/compliance.xlsx';

            req.open("GET", url, true);
            req.responseType = "arraybuffer";
            req.onreadystatechange = function () {
                if (req.readyState === 4){
                    if (req.status === 200) {
                        resolve(XlsxPopulate.fromDataAsync(req.response));
                    } else {
                        reject("Received a " + req.status + " HTTP code.");
                    }
                }
            };
            req.send();
        });
    }

    vm.comp.collapseAll = function() {
        vm.comp.mainCollapse = !vm.comp.mainCollapse;
        if (vm.comp.samples.length != 0) {
            vm.comp.samples.forEach( function(sample) {
                sample.sample_expanded = vm.comp.mainCollapse;
            });
        }
    }

    function findWithAttr(array, attr, value) {
        for(var i = 0; i < array.length; i += 1) {
            if(typeof(array[i][attr]) == 'string' && typeof(value) == 'string'){
                if(array[i][attr].toLowerCase() === value.toLowerCase()) {
                    return i;
                }
            }
            else {
                if(array[i][attr] === value) {
                    return i;
                }
            }
        }
        return -1;
    }

    vm.comp.changeMatrix = function(index) {
        var category_index = null;
        var type_index = null;
        var inhalable_index = null;

        if (vm.comp.samples.length < 1) {
            vm.comp.pageOneValid = false;
            return;
        }

        if(vm.comp.samples[index].sample_matrix) {
            if(vm.comp.samples[index].sample_matrix.value == 'PLANT_COMPLIANCE_PHASE_3') {
                category_index = findWithAttr(vm.comp.categories, 'name', 'Plant');
                type_index = findWithAttr(vm.comp.categories[category_index].types, 'name', 'Flower')
                inhalable_index = findWithAttr(vm.comp.inhalable_edible_array, 'value', 'INHALABLE');

                vm.comp.samples[index].sample_category = vm.comp.categories[category_index];
                vm.comp.populateTypes(vm.comp.samples[index]);
                vm.comp.samples[index].sample_inhalable = vm.comp.inhalable_edible_array[inhalable_index];
                vm.comp.samples[index].sample_type = vm.comp.categories[category_index].types[type_index];
            }
            else if(vm.comp.samples[index].sample_matrix.value == 'PRE_ROLL_COMPLIANCE_PHASE_3') {
                category_index = findWithAttr(vm.comp.categories, 'name', 'Plant');
                type_index = findWithAttr(vm.comp.categories[category_index].types, 'name', 'Flower')
                inhalable_index = findWithAttr(vm.comp.inhalable_edible_array, 'value', 'INHALABLE');

                vm.comp.samples[index].sample_category = vm.comp.categories[category_index];
                vm.comp.populateTypes(vm.comp.samples[index]);
                vm.comp.samples[index].sample_inhalable = vm.comp.inhalable_edible_array[inhalable_index];
                vm.comp.samples[index].sample_type = vm.comp.categories[category_index].types[type_index];
            }
            else if(vm.comp.samples[index].sample_matrix.value == 'INHALABLE_EXTRACT_COMPLIANCE_PHASE_3') {
                category_index = findWithAttr(vm.comp.categories, 'name', 'Concentrate');
                inhalable_index = findWithAttr(vm.comp.inhalable_edible_array, 'value', 'INHALABLE');

                vm.comp.samples[index].sample_category = vm.comp.categories[category_index];
                vm.comp.samples[index].sample_inhalable = vm.comp.inhalable_edible_array[inhalable_index];
                vm.comp.samples[index].sample_type = null;
            }
            else if(vm.comp.samples[index].sample_matrix.value == 'EDIBLE_COMPLIANCE_PHASE_3') {
                inhalable_index = findWithAttr(vm.comp.inhalable_edible_array, 'value', 'NONINHALABLE');

                vm.comp.samples[index].sample_category = null;
                vm.comp.samples[index].sample_inhalable = vm.comp.inhalable_edible_array[inhalable_index];
                vm.comp.samples[index].sample_type = null;
            }
            else if(vm.comp.samples[index].sample_matrix.value == 'SOLID_EDIBLE_COMPLIANCE_PHASE_3') {
                category_index = findWithAttr(vm.comp.categories, 'name', 'Edible');
                inhalable_index = findWithAttr(vm.comp.inhalable_edible_array, 'value', 'NONINHALABLE');

                vm.comp.samples[index].sample_category = vm.comp.categories[category_index];
                vm.comp.samples[index].sample_inhalable = vm.comp.inhalable_edible_array[inhalable_index];
                vm.comp.samples[index].sample_type = null;
            }

            if(category_index) {
                vm.comp.populateTypes(vm.comp.samples[index]);
            }
        }

        vm.comp.checkPageOne();
    }

    vm.comp.checkPageOne = function() {
        if (vm.comp.samples.length < 1) {
            vm.comp.pageOneValid = false;
            return;
        }

        for(var i = 0; i < vm.comp.samples.length; i++){
            if (!vm.comp.samples[i].sample_product_harvest_batch) {
                vm.comp.pageOneValid = false;
                return;
            }
            else {
                if (vm.comp.samples[i].sample_product_harvest_batch == 'PRODUCT_BATCH') {
                    if (!vm.comp.samples[i].sample_matrix ||
                        !vm.comp.samples[i].sample_name ||
                        !vm.comp.samples[i].sample_lot_batch_number ||
                        !vm.comp.samples[i].sample_category ||
                        !vm.comp.samples[i].sample_type ||
                        !vm.comp.samples[i].sample_inhalable ||
                        !vm.comp.samples[i].sample_source_id ||
                        !vm.comp.samples[i].sample_product_batch_size ||
                        !vm.comp.samples[i].sample_unit_weight || 
                        !vm.comp.samples[i].sample_expected_potency) {
                            vm.comp.pageOneValid = false;
                            return;
                    }
                }
                else {
                    if (!vm.comp.samples[i].sample_matrix ||
                        !vm.comp.samples[i].sample_name ||
                        !vm.comp.samples[i].sample_lot_batch_number ||
                        !vm.comp.samples[i].sample_category ||
                        !vm.comp.samples[i].sample_type ||
                        !vm.comp.samples[i].sample_inhalable ||
                        !vm.comp.samples[i].sample_source_id ||
                        !vm.comp.samples[i].sample_product_batch_size) {
                            vm.comp.pageOneValid = false;
                            return;
                    }
                }
            }
        }

        vm.comp.pageOneValid = true;
        return;
    }

    vm.comp.checkPageTwo = function() {
        if (!vm.fullLocationManufactur ||
            !vm.fullLocationComp ||
            !vm.comp.pickup_arrival_date ||
            !vm.fullLocationPickup ||
            !($('#emailToCompliance').val().length > 0)
        ) {
            vm.comp.pageTwoValid = false;
            return;
        }
        vm.comp.pageTwoValid = true;
        return;
    }

    vm.comp.submitOrder = function() {
        $rootScope.type = 'COMPLIANCE';
        CustomerOrder.save(comp.serializePayload(), onSaveSuccess, onSaveError);
    }

    comp.serializeSamples = function(samples) {
        var serializedSamples = [];
        if (samples) {
            samples.forEach( function(sample){
                serializedSamples.push({
                    id: null,
                    name: sample.sample_name,
                    lotBatchNumber: sample.sample_lot_batch_number,
                    productCategory: typeof(sample.sample_category) == 'string' ? formatNameDict(sample.sample_category) : sample.sample_category,
                    productType: typeof(sample.sample_type) == 'string' ? formatNameDict(sample.sample_type) : sample.sample_type,
                    strainName: typeof(sample.sample_strain_name) != 'object' ? formatNameDict(sample.sample_strain_name) : sample.sample_strain_name,
                    strainType: typeof(sample.sample_strain_type) == 'string' ? formatNameDict(sample.sample_strain_type) : sample.sample_strain_type,
                    otherAnalysis: null,
                    reportedUnits: null,
                    productBatchSize: sample.sample_product_harvest_batch == 'PRODUCT_BATCH' ? sample.sample_product_batch_size : null,
                    harvestBatchSize: sample.sample_product_harvest_batch == 'HARVEST_BATCH' ? sample.sample_product_batch_size : null,
                    batchType: sample.sample_product_harvest_batch,
                    inhalableEdible: typeof(sample.sample_inhalable) == 'object' ? sample.sample_inhalable.value : sample.sample_inhalable,
                    sourceId: sample.sample_source_id ? sample.sample_source_id : null,
                    sCode: sample.sample_metrc_id ? sample.sample_metrc_id : null,
                    labelClaims: sample.sample_label_claims,
                    expectedPotency: sample.sample_expected_potency,
                    addTerpenes: sample.sample_add_terpenoid_profile ? sample.sample_add_terpenoid_profile : null,
                    analyzeByLCMSMS: sample.sample_analyze_by_lcmsms ? sample.sample_analyze_by_lcmsms : null,
                    manufactureDate: formatDate(sample.sample_manufacture_date),
                    harvestDate: formatDate(sample.sample_harvest_date),
                    sampleWeightPerIncrement: sample.sample_unit_weight ? sample.sample_unit_weight : null,
                    sampleTests: null,
                    phase: sample.sample_matrix.value ? sample.sample_matrix.value : null,
                    medicinal_batch: sample.medicinal_batch
                });
            });
        }
        return serializedSamples;
    }

    comp.serializePayload = function() {
        vm.comp.submitting = true;
        $rootScope.comp_submitting = vm.comp.submitting
        var payload = {
            orderType: "COMPLIANCE",
            collection_type: null,
            customer_account_id: $localStorage.customerId,
            dateCreated: null,
            dateReceived: null,
            date_submitted: null,
            delivery_preference: vm.comp.deliveryPreference,
            estimated_arrival_date: formatDate(vm.comp.pickup_arrival_date),
            partially_complete: null,
            poNumber: vm.comp.orderPO,
            reporting_preference: null,
            sample_condition: null,
            sampledDate: formatDate(vm.comp.pickup_arrival_date),
            samplingAddress: vm.samplingAddress,
            manufacturingAddress: vm.manufacturingAddress ? vm.manufacturingAddress : null,
            invoicing_address: vm.invoicing_address ? vm.invoicing_address : vm.customerData.qbAddress,
            shipping_carrier: null,
            specialInstructions: vm.comp.specialInstructions,
            state: null,
            submitted_by: vm.comp.emailsArray.join(),
            dynamic_turnaround_time: vm.comp.turnaroundTime,
            id: null,
            samples: comp.serializeSamples(vm.comp.samples),
            tracking_number: vm.comp.tracking_number
        }

        return payload;
    }

        vm.comp.openCalendar = function(sample, key) {
            sample[key] = true;
        }

        vm.comp.estimateReportDate = function(turnaround_time){
            var days = null;

            if (turnaround_time.includes('3')) {
                days = 3;
            }
            else if (turnaround_time.includes('2')) {
                days = 2;
            }
            else if (turnaround_time.includes('1')) {
                days = 1;
            }

            if (days && turnaround_time && vm.comp.pickup_arrival_date) {
                var tomorrow = new Date(vm.comp.pickup_arrival_date.getTime());
                for (var i = 0; i < days; i++) {
                    tomorrow.setDate(tomorrow.getDate() + 1)
                    if (tomorrow.getDay() == 0) {
                        tomorrow.setDate(tomorrow.getDate() + 1)
                    }
                    else if (tomorrow.getDay() == 6) {
                        tomorrow.setDate(tomorrow.getDate() + 2)
                    }
                }

                vm.comp.estimated_report_date = tomorrow;
            }

            if (tomorrow) {
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                vm.comp.estimated_report_date.day = days[tomorrow.getDay()]
                vm.comp.estimated_report_date.month_day_year = tomorrow.toDateString().substring(4);
            }
            vm.comp.checkPageTwo();
        }

        vm.comp.deleteChecked = function() {
            for(var i = vm.comp.samples.length-1; i >= 0; i--) {
                if (vm.comp.samples[i].sample_delete) {
                    vm.comp.samples.splice(i, 1);
                }
            }
            comp.resetDeleteCheckboxes();
            vm.comp.checkPageOne();
        }

        vm.comp.clickCheckAllDelete = function() {
            vm.comp.samples.forEach( function(sample) {
                sample.sample_delete = $(':checkbox.comp-delete-checkbox-header')[0].checked;
            });
        }

        vm.comp.clickDelete = function(index) {
            vm.comp.samples[index].sample_delete = !vm.comp.samples[index].sample_delete;
            $(':checkbox.comp-delete-checkbox-header')[0].checked = false;
        }

        vm.comp.incrementPage = function() {
            vm.comp.step = vm.comp.step + 1;
            if(vm.comp.step == 3) {
                vm.comp.emailsArray = vm.comp.emails.split(',')
            }
        }

        vm.comp.decrementPage = function() {
            vm.comp.step = vm.comp.step - 1;
        }

        vm.comp.removeSample = function(index) {
            vm.comp.samples.splice(index, 1);
            vm.comp.sampleArray.splice(index, 1);
            comp.resetDeleteCheckboxes();
            vm.comp.checkPageOne();
        }

        comp.onPageLoad = function() {
            comp.getPanels();
            comp.getCategories();
        }

        comp.getPanels = function() {
            Panel.query({}, function(data){
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].portalIdentifier) {
                        vm.comp.panels[data[i].portalIdentifier] = data[i];
                    }
                }
                vm.comp.phases = [
                        /* Plant */
                        {
                            'id': vm.comp.panels['PLANT_COMPLIANCE_PHASE_3'].id,
                            'name': 'Plant',
                            'value':'PLANT_COMPLIANCE_PHASE_3'
                        },

                        /* Pre-Roll Compliance */
                        {
                            'id': vm.comp.panels['PRE_ROLL_COMPLIANCE_PHASE_3'].id,
                            'name': 'Pre-Roll',
                            'value': 'PRE_ROLL_COMPLIANCE_PHASE_3'
                        },

                        /* Inhalable Extract */
                        {
                            'id': vm.comp.panels['INHALABLE_EXTRACT_COMPLIANCE_PHASE_3'].id,
                            'name': 'Inhalable Extract',
                            'value':'INHALABLE_EXTRACT_COMPLIANCE_PHASE_3'
                        },

                        /* Non-Inhalable */
                        {
                            'id': vm.comp.panels['EDIBLE_COMPLIANCE_PHASE_3'].id,
                            'name': 'Liquid Edible/Topical',
                            'value':'EDIBLE_COMPLIANCE_PHASE_3'
                        },

                        /* Solid Edible */
                        {
                            'id': vm.comp.panels['SOLID_EDIBLE_COMPLIANCE_PHASE_3'].id,
                            'name': 'Solid/Semi-Solid Edible',
                            'value':'SOLID_EDIBLE_COMPLIANCE_PHASE_3'
                        },
                    ];
                }
            });
        }

        vm.comp.addSample = function() {
            vm.comp.samples.push({
                sample_order_type: 'comp',
                sample_expanded: true,
                sample_checked: null,
                sample_delete: false,
                sample_name: null,
                sample_lot_batch_number: null,
                sample_category: null,
                sample_type: null,
                sample_types: null,
                sample_inhalable: null,
                sample_source_id: null,
                sample_metrc_id: null,
                sample_strain_name: null,
                sample_strain_type: null,
                sample_label_claims: null,
                sample_expected_potency: null,
                sample_matrix: null,
                sample_product_harvest_batch: 'PRODUCT_BATCH',
                sample_product_batch_size: null,
                sample_manufacture_date_open: false,
                sample_manufacture_date: null,
                sample_harvest_date_open: false,
                sample_harvest_date: null,
                sample_unit_weight: null,
                sample_add_terpenoid_profile: false,
                sample_analyze_by_lcmsms: false,
                focus: {
                    name: false,
                    lot_batch_number: false,
                    autofill: false
                },
                options: {
                    name: [],
                    lot_batch_number: [],
                    autofill: []
                },
                medicinal_batch: false
            });
            vm.comp.checkPageOne();
        }

        vm.comp.strainTypes  = [{
            'id': 1,
            'name': 'Sativa'
          }, {
             'id': 2,
             'name': 'Indica'
          }, {
            'id': 3,
            'name': 'Hybrid'
          },
          {
            'id': 4,
            'name': 'CBD'
          }];

        vm.comp.inhalable_edible_array  = [
            {
                'id': 1,
                'name': 'Inhalable',
                'value':'INHALABLE'
            },
            {
                'id': 2,
                'name': 'Non-Inhalable',
                'value':'NONINHALABLE'
            }
        ];

        comp.getCategories = function() {
            ProductCategory.query({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.comp.categories = data;
                comp.getTypes();
                return;
            }

            function onError(e) {
                console.log(e);
                return;
            }
        }

        comp.getTypes = function() {
            ProductType.query({}, onSuccess, onError);

            function onSuccess(data, headers){
                vm.comp.all_types = data;
                comp.processCatAndTypes();
                return;
            }

            function onError(e) {
                console.log(e);
                return;
            }
        }

        comp.processCatAndTypes = function() {
            for(var i = 0; i < vm.comp.all_types.length; i++){
                for(var j = 0; j < vm.comp.categories.length; j++){
                    if (!vm.comp.categories[j.toString()].types){
                        vm.comp.categories[j.toString()].types = [];
                    }
                    if (vm.comp.all_types[i.toString()].productCategory.id == vm.comp.categories[j.toString()].id){
                        vm.comp.categories[j.toString()].types.push(vm.comp.all_types[i.toString()]);
                    }
                }
            }
        }

        comp.resetDeleteCheckboxes = function() {
            $(':checkbox.comp-delete-checkbox-header')[0].checked = false;
            vm.comp.samples.forEach( function(sample) {
                sample.sample_delete = false;
            });
        }

        vm.comp.populateTypes = function(sample) {
            for(var i = 0; i < vm.comp.categories.length; i++) {
                if (sample.sample_category.name == vm.comp.categories[i.toString()].name) {
                    sample.sample_types = vm.comp.categories[i.toString()].types;
                    return;
                }
            }
        }

        comp.onPageLoad();

// ***************************** END COMP FUNCTIONS *****************************
// **************************** START FDA ****************************
        vm.fda = {payload:{}}
        vm.fda.isFdaDisabled = true
        vm.fda.location = function(address, city, zipCode, name, medicalLicence, fullLocation) {
            vm.fda.payload.warehouse_address = createAddress(address, city, zipCode, name, medicalLicence);
            vm.fda.payload.warehouse_address = fullLocation;
            distribution_list_toggle();
        }
        function checkLineArray(element) {
            return element.productdesc !== '' && element.lineItem !== ''
        }
        vm.fda.checkfdaform = function(){
            var validLineArray
            if(vm.fda.line.length > 0){
                validLineArray = vm.fda.line.every(checkLineArray)
            }
            if(!vm.fda.payload.entry || !vm.fda.payload.warehouse_address || validLineArray === false){
                vm.fda.isFdaDisabled = true
                return false
            }
            else{
                if(!vm.fda.payload.detention_notice || !vm.fda.payload.list_invoice_pack){
                    vm.fda.isFdaDisabled = true
                    return false
                }
                else{
                    vm.fda.isFdaDisabled = false
                }
            }
        }
        vm.fda.submitOrder = function() {
            $rootScope.type = 'FDA'
            vm.fda.checkfdaform()
            vm.fda.payload.order_type = 'FDA';
            vm.fda.payload.customer_account_id = $localStorage.customerId
            vm.fda.payload.samples = vm.fda.line
            CustomerOrder.save(vm.fda.payload, onSaveSuccess, onSaveError);
            if(vm.fda.submitting){
                ProgressInterval = setInterval(() => {
                    if (vm.progressCount < 31) {
                        vm.progressCount = vm.progressCount - 1;
                        var percentage = (vm.progressCount * 100)/30
                        progressCircle.style.backgroundImage = 'conic-gradient('+ 'rgb(50, 168, 82) ' +  percentage + '%, rgb(183, 237, 197) 0 )';
                        if(vm.progressCount <= 0 ){
                            $('#submitProgressModal').modal('hide');
                        }
                    } else {
                        vm.progressCount = 30;
                    }
                }, 1000);
                $(document).on('click', '.progress-button', function() {
                    $('#submitProgressModal').modal('show');
                });
            }
        }
        vm.fda.line = [{
            productdesc : '',
            lineItem : '',
            batchNo : ''
        }]
        vm.fda.addLine = function (){
            vm.fda.line.push({
                productdesc : '',
                lineItem : '',
                batchNo : ''
            })
            vm.fda.checkfdaform()
        }
        vm.fda.removeLine = function(index){
            vm.fda.line.splice(index,1)
        }

        vm.onfileUpload = function(id) {
            var file = $(`#${id}`)[0].files[0];
            var base64 = DataUtils.toBase64(file, function(data){
                if (!vm.fda.payload[id]) {
                    vm.fda.payload[id] = {}; // Initialize if undefined
                }
                vm.fda.payload[id].name = file.name; 
                vm.fda.payload[id].base64 = data;
                if(id == 'detention_notice' || id == 'list_invoice_pack'){
                    vm.fda.isFdaDisabled = false
                }
            });
            vm.fda.checkfdaform()
        };
// ***************************** END FDA FUNCTIONS *****************************


// **************************** START RND ****************************

        vm.rd.step = 1;
        vm.rd.pageOneValid = false;
        vm.rd.pageTwoValid = false;
        vm.rd.pageThreeValid = false;
        vm.rd.customer_type = $rootScope.customer ? ($rootScope.customer.customer_type ? $rootScope.customer.customer_type : 'Cannabis') : 'Cannabis';
        vm.rd.company_discount = $rootScope.customer ? ($rootScope.customer.company_discount ? $rootScope.customer.company_discount : null) :  null;
        vm.rd.excel_header_offset =  vm.rd.customer_type.includes('Cannabis') || vm.rd.customer_type.includes('Hemp') ? 10 : 5;
        vm.rd.file = null;
        vm.rd.specialInstructions = null;
        vm.rd.turnaroundTime = vm.rd.turnaroundTimeOptions ? '3-4 day (standard)' : '10 Days (standard)';
        vm.rd.estimated_report_date = null;
        vm.rd.categories = null;
        vm.rd.sampleArray = [];
        vm.rd.all_types = null;
        vm.rd.manufacturing_address = null;
        vm.rd.deliveryPreference = null;
        vm.rd.orderPO = null;
        vm.rd.pickup_arrival_date = null;
        vm.rd.emails = '';
        vm.rd.samples = [];
        vm.rd.tests = [];
        vm.rd.testsRequested = [];
        vm.rd.testsRequestedCount = [];
        vm.rd.mainCollapse = false;
        vm.rd.total = 0;
        vm.rd.subtotal = null;
        vm.rd.total_discount = 0;
        vm.rd.excel_assay_header = null;
        vm.rd.excel_assay_index_map = {};
        vm.rd.assay_map = {};
        vm.rd.assay_map_by_index = {};
        vm.rd.cannabinoid_assays = {}
        vm.rd.cannabinoid_assay_added = false;
        vm.rd.all_assay_map_by_id = {};
        vm.rd.all_tests = [];
        vm.rd.showTests = {};
        vm.rd.showTestsArray = [];
        vm.rd.showTestResults = null;
        vm.rd.panels = {};
        vm.rd.panels.current_panel_index = null;
        vm.rd.panels.new_panel_name = null;
        vm.rd.panel_by_id_map = {}
        vm.rd.current_sample_name = null;
        vm.rd.customPanels = [];
        vm.rd.qbenchPanels = [];
        vm.order_type = vm.rd.customer_type == 'Cannabis' ? 'Compliance' : 'RnD';
        vm.rd.test_to_search = '';
        vm.rd.other_analyses = [];
        vm.rd.user_added_assays = [];
        vm.rd.existing_user_added_assays = [];
        vm.rd.number_of_freq_assays = 0;
        vm.rd.submitting = false;

        // **************************** START RND FUNCTIONS ****************************

        rd.showAllTests = function() {
            angular.forEach(vm.rd.showTests, function(value, key) {
                vm.rd.showTests[key] = true;
            });
            return;
        }

        rd.getTypes = function() {
            ProductType.query({}, onSuccess, onError);

            function onSuccess(data, headers){
                vm.rd.all_types = data;
                rd.processCatAndTypes();
                return;
            }

            function onError(e) {
                console.log(e);
                return;
            }
        }

        rd.processCatAndTypes = function() {
            for(var i = 0; i < vm.rd.all_types.length; i++){
                for(var j = 0; j < vm.rd.categories.length; j++){
                    if (!vm.rd.categories[j.toString()].types){
                        vm.rd.categories[j.toString()].types = [];
                    }
                    if (vm.rd.all_types[i.toString()].productCategory.id == vm.rd.categories[j.toString()].id){
                        vm.rd.categories[j.toString()].types.push(vm.rd.all_types[i.toString()]);
                    }
                }
            }
        }

        rd.resetDeleteCheckboxes = function() {
            $(':checkbox.delete-checkbox-header')[0].checked = false;
            vm.rd.samples.forEach( function(sample) {
                sample.sample_delete = false;
            });
        }

        vm.rd.filterAssays = function() {
            vm.rd.showTestsArray = Object.keys(vm.rd.showTests);
            vm.rd.showTestResults = $filter('filter')(vm.rd.showTestsArray, vm.rd.test_to_search);
            angular.forEach(vm.rd.showTests, function(value, key) {
                vm.rd.showTests[key] = false;
            });
            vm.rd.showTestResults.forEach( function(test) {
                vm.rd.showTests[test] = true;
            });
            // rd.setInfoBoxCoordinates();
        }
        //filtertering with the ID
        vm.rd.customFilter = function(item) {
            var searchText = vm.rd.test_to_search;
            if (/^\d+$/.test(searchText)) {
                var idMatches = (item.id.toString().indexOf(searchText) !== -1);
                var assayIdMatches = (item.assay_id && item.assay_id.toString().indexOf(searchText) !== -1);
                return idMatches || assayIdMatches;
            } else {
                return item.display_name.toLowerCase().includes(searchText.toLowerCase());
            }
        };
             
        // not currently being used
        function getExistingAssays() {
            if (vm.customerData.added_assays) {
                vm.customerData.added_assays.forEach( function(id) {
                    vm.rd.addExistingAssay(id);
                });
            }
        }

        // not currently being used
        vm.rd.addExistingAssay = function(id) {
            var test = vm.rd.all_assay_map_by_id[id];

            test.index = vm.rd.tests.length;
            vm.rd.assay_map[test.display_name] = [test.index, test];
            vm.rd.assay_map_by_index[test.index] = test;
            vm.rd.tests.push(test)

            vm.rd.existing_user_added_assays.push(id);

            vm.rd.test_to_search = '';
        }

        vm.rd.clear_test_to_search = function() {
            vm.rd.test_to_search = '';
            vm.rd.filterAssays();
            // rd.setInfoBoxCoordinates();
        }

        vm.rd.select_test_to_search = function(name) {
            vm.rd.test_to_search = name;
            vm.rd.filterAssays();
            // rd.setInfoBoxCoordinates();
        }

        vm.rd.clickCheckAllAddedTest = function(test, index) {
            var selector = ':checkbox.added-assay-col-header-' + index;
            vm.rd.samples.forEach( function(sample) {
                sample.sample_other_analyses[test] = $(selector)[0].checked
            });

            vm.rd.checkPageTwo();
        }

        vm.rd.addOtherAnalysis = function() {
            vm.rd.other_analyses.push(vm.rd.test_to_search);
            vm.rd.test_to_search = '';
            rd.showAllTests()

            return;
        }

        vm.rd.clearOtherAnalysis = function() {
            rd.showAllTests();
            vm.rd.other_analyses = [];
            vm.rd.test_to_search = '';

            return;
        }

        vm.rd.savePanel = function(sampleIndex, step) {
            if (step == 1) {
                vm.rd.panels.current_panel_index = sampleIndex;
                return;
            }

            else if (step == 2) {
                var assays = [];
                Object.keys(vm.rd.sampleArray[vm.rd.panels.current_panel_index]).forEach( function(key) {
                    if (vm.rd.sampleArray[vm.rd.panels.current_panel_index][key]) {
                        assays.push({id: vm.rd.assay_map_by_index[key].id})
                    }
                });

                if (vm.rd.panels.new_panel_name && assays) {
                    CustomPanel.create({
                            title: vm.rd.panels.new_panel_name,
                            assays: assays
                        },
                        function() {
                            vm.rd.samples[vm.rd.panels.current_panel_index].sample_has_tests = true;

                            rd.getPanelsByCustomerAndSetPanel(vm.rd.panels.current_panel_index);
                        },
                        function(e) {
                            console.log(e);
                        }
                    );
                }
                return;
            }
        }

        rd.getPanelsByCustomerAndSetPanel = function(sampleIndex) {
            CustomPanel.query({},
                function(data) {
                    vm.rd.customPanels = [];
                    var panel_id = null
                    data.forEach( function(panel) {
                        vm.rd.customPanels.push({
                            title: panel.title,
                            assays:rd.getPanels(panel),
                            id: panel.id,
                            type: 'custom_panel'
                        });
                    });
                    rd.setPanelsByIdMap();
                    panel_id = vm.rd.customPanels[vm.rd.customPanels.length-1].id;
                    vm.rd.setPanel(vm.rd.samples[sampleIndex], panel_id, 'custom_panel');
                    vm.rd.samples[sampleIndex].sample_panel_by_id = panel_id;
                    vm.rd.samples[sampleIndex].sample_has_tests = false;
                    vm.rd.panels = {};

                },
                function(e){
                    console.log(e);
                }
            );
        }


        vm.rd.updatePanel = function(sampleIndex, panelId) {
            var assays = [];
            Object.keys(vm.rd.sampleArray[sampleIndex]).forEach( function(key) {
                if(vm.rd.sampleArray[sampleIndex][key]) {
                    assays.push({id: vm.rd.assay_map_by_index[key].id})
                }
            });
            CustomPanel.update({
                id: panelId,
                assays: assays
            },
                function() {
                    vm.rd.samples[sampleIndex].sample_has_tests = false;
                    rd.getPanelsByCustomer();
                },
                function(e) {
                    console.log(e);
                }
            );
            return;
        }

        vm.rd.deletePanel = function(panelId) {
            vm.rd.customPanels = [];

            CustomPanel.delete({id: panelId},
                function() {
                    rd.getPanelsByCustomer();
                },
                function(e) {
                    console.log(e);
                }
            );

            return;
        }

        rd.isSample = function(object) {
            return object.sample_name == vm.rd.current_sample_name;
        }

        rd.isBasicPotencyAssay = function(test) {
            if(test.portal_identifier && [vm.const.PLANT, vm.const.NON_PLANT].includes(test.portal_identifier)) {
                return true;
            }
            else {
                return false
            }
        }

        rd.potencyAssayByCategory = function(category) {
            if(['Plant'].includes(category.name)) {
                return vm.rd.cannabinoid_assays[vm.const.PLANT]
            }
            else if(['Concentrate', 'Edible', 'Topical', 'Environmental', 'Other'].includes(category.name)) {
                return vm.rd.cannabinoid_assays[vm.const.NON_PLANT]
            }
        }

        vm.rd.removePanelFromSample = function(sampleIndex) {
            Object.keys(vm.rd.sampleArray[sampleIndex]).forEach( function(key) {
                vm.rd.sampleArray[sampleIndex][key] = false;
            });

            vm.rd.tests.forEach( function(test, index) {
                $(':checkbox.assay-col-header-' + index)[0].checked = false;
            })

            vm.rd.current_sample_name = false;
            vm.rd.samples[sampleIndex].sample_current_panel_title = null;
            vm.rd.samples[sampleIndex].sample_panel_selected = null;
            vm.rd.samples[sampleIndex].sample_panel_by_id = null;
            vm.rd.samples[sampleIndex].sample_panel_type = null;
            vm.rd.samples[sampleIndex].sample_has_tests = null;
            vm.rd.samples[sampleIndex].sample_tests = [];

            vm.rd.checkPageTwo();
        }

        vm.rd.setPanel = function(sample, panelId, panel_type) {
            var sampleIndex = null;
            vm.rd.current_sample_name = sample.sample_name;

            if (vm.rd.samples.findIndex(rd.isSample) > -1) {
                sampleIndex = vm.rd.samples.findIndex(rd.isSample);
            }

            var temp = null;
            if (vm.rd.sampleArray[sampleIndex]) {
                delete vm.rd.sampleArray[sampleIndex];
            }
            vm.rd.sampleArray[sampleIndex] = {};

            if (vm.rd.samples[sampleIndex].sample_tests) {
                vm.rd.samples[sampleIndex].sample_tests = [];
            }
            vm.rd.panel_by_id_map[panelId][1].forEach(function (assay) {
                if (vm.rd.assay_map[assay.display_name]) {
                temp = Object.assign({}, vm.rd.assay_map[assay.display_name][1]);
                temp.checked = true;
                vm.rd.samples[sampleIndex].sample_tests.push(temp);
                vm.rd.sampleArray[sampleIndex][vm.rd.assay_map[assay.display_name][1].index] = true;
                }
            });

            vm.rd.samples[sampleIndex].sample_panel_selected = panelId;
            vm.rd.samples[sampleIndex].sample_panel_type = panel_type;
            vm.rd.samples[sampleIndex].sample_current_panel_title = vm.rd.panel_by_id_map[panelId][0];
            vm.rd.current_sample_name = null;
            vm.rd.checkPageTwo();

            return;
        }

        vm.rd.strainTypes = [{
            'id': 1,
            'name': 'Sativa'
          }, {
             'id': 2,
             'name': 'Indica'
          }, {
            'id': 3,
            'name': 'Hybrid'
          },
           {
            'id': 4,
            'name': 'CBD'
           }];

        vm.rd.inhalable = [
            {
                'id': 1,
                'name': 'Inhalable',
                'value': 'INHALABLE'
            },
            {
                'id': 2,
                'name': 'Non-Inhalable',
                'value': 'NONINHALABLE'
            }
        ]

        vm.rd.cancelOrder = function() {
            vm.rd.step = 1;
            vm.rd.pageOneValid = false;
            vm.rd.pageTwoValid = false;
            vm.rd.pageThreeValid = false;
            vm.rd.specialInstructions = null;
            vm.rd.estimated_report_date = null;
            vm.rd.manufacturing_address = null;
            vm.rd.deliveryPreference = null;
            vm.rd.orderPO = null;
            vm.rd.pickup_arrival_date = null;
            vm.rd.emails = '';
            vm.rd.samples = [];
            vm.fullLocationManufactur = null;
            vm.rd.sampleArray = [];
            vm.rd.mainCollapse = false;
            vm.rd.other_analyses = [];
            vm.rd.user_added_assays = [];
            vm.rd.panels = {};
            vm.rd.panels.current_panel_index = null;
            vm.rd.panels.new_panel_name = null;
            vm.rd.turnaroundTime = '3-4 day (standard)';
            CustomerOrder.getCustomerEmails({}, onCustomerEmailSuccess, onCustomerEmailError);
        }

         rd.getOrderTotal = function() {
            vm.rd.total = 0;
            vm.rd.subtotal = null;
            vm.rd.total_discount = 0;
            vm.rd.testsRequestedCount.forEach( function(test) {
                // set list price of test
                if (test.assay_customer) {
                    if (test.assay_customer.basePrice) {
                        test.list_price = test.assay_customer.basePrice;
                    }
                    else {
                        if (test.base_price) {
                           test.list_price = test.base_price;
                        }
                        else {
                            test.list_price = null;
                        }
                    }
                }
                else if (test.base_price){
                    test.list_price = test.base_price;
                }
                else {
                    test.list_price = null;
                }
                // set discount of test
                if (test.assay_customer) {
                    if (test.assay_customer.discount) {
                        test.discount = test.assay_customer.discount;
                    }
                    else {
                        if (vm.rd.company_discount) {
                            test.discount = vm.rd.company_discount;
                        }
                        else {
                            test.discount = null;
                        }
                    }
                }
                else if (vm.rd.company_discount){
                    test.discount = vm.rd.company_discount;
                }
                else {
                    test.discount = null;
                }
                // set amount for test and get order subtotal/discount/total
                if (test.list_price && test.count) {
                    if (test.discount) {
                        vm.rd.total_discount = vm.rd.total_discount + (test.list_price * (test.discount / 100));
                        test.amount = (test.list_price * test.count) * (1 - (test.discount / 100));
                        vm.rd.total = vm.rd.total + test.amount
                    }
                    else {
                        test.amount = test.list_price * test.count;
                        vm.rd.total = vm.rd.total + test.amount
                    }
                }
                else {
                    test.amount = null;
                }
            });

            if (vm.rd.total && vm.rd.total_discount) {
                vm.rd.subtotal = vm.rd.total + vm.rd.total_discount;
            }
            else {
                vm.rd.subtotal = vm.rd.total;
            }
        }

        rd.processAllSampleTests = function() {
            vm.rd.testsRequested.length = 0;
            vm.rd.testsRequestedCount.length = 0;
            vm.rd.testsRequested = [];
            vm.rd.testsRequestedCount = [];
            var added = [];
            var to_add = null;

            for(var i = 0; i < vm.rd.samples.length; i++) {
                for(var j = vm.rd.samples[i].sample_tests.length-1; j >= 0; j--) {
                    if(rd.isBasicPotencyAssay(vm.rd.samples[i].sample_tests[j])) {
                        to_add = rd.potencyAssayByCategory(vm.rd.samples[i].sample_category);
                        to_add.checked = true;
                        vm.rd.samples[i].sample_tests[j] = jQuery.extend({}, to_add);
                        vm.rd.testsRequested.push(to_add);
                    }
                    else {
                        vm.rd.testsRequested.push(vm.rd.samples[i].sample_tests[j]);
                    }
                }
            }
            // rd.getOrderTotal();
        }

        vm.rd.estimateReportDate = function(turnaround_time) {
            var days_length = turnaround_time.split(' days')[0];
            var days = null;

            if(days_length.includes('-')) {
                days = days_length.split('-')[1];
            }
            else {
                days = days_length;
            }

            days = parseInt(days);

            if (days && turnaround_time && vm.rd.pickup_arrival_date) {
                var tomorrow = new Date(vm.rd.pickup_arrival_date.getTime());
                for (var i = 0; i < days; i++) {
                    tomorrow.setDate(tomorrow.getDate() + 1)
                    if (tomorrow.getDay() == 0) {
                        tomorrow.setDate(tomorrow.getDate() + 1)
                    }
                    else if (tomorrow.getDay() == 6) {
                        tomorrow.setDate(tomorrow.getDate() + 2)
                    }
                }

                vm.rd.estimated_report_date = tomorrow;
            }
            if (tomorrow) {
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                vm.rd.estimated_report_date.day = days[tomorrow.getDay()]
                vm.rd.estimated_report_date.month_day_year = tomorrow.toDateString().substring(4);
            }
            vm.rd.checkPageThree();
        }

        vm.rd.openCalendar = function(sample, key) {
            sample[key] = true;
        }

        vm.rd.populateTypes = function(sample) {
            for(var i = 0; i < vm.rd.categories.length; i++) {
                if (sample.sample_category.name == vm.rd.categories[i.toString()].name) {
                    sample.sample_types = vm.rd.categories[i.toString()].types;
                    return;
                }
            }
        }

        vm.rd.clearPanel = function() {
            vm.rd.panels.current_panel_index = null;
            vm.rd.current_sample_name = null;
            vm.rd.panels = {};
        }

        vm.rd.uploadExcel = function() {
            $('#rd_upload_excel').click();
        }

        vm.rd.downloadExcel = function() {
            rd.generateBlob();
        }

        rd.getWorkbook = function() {
            return new Promise(function (resolve, reject) {
                var req = new XMLHttpRequest();
                var url = isLocal ? 'content/files/excel/cannabis.xlsx' : 'content/files/cannabis.xlsx';

                req.open("GET", url, true);
                req.responseType = "arraybuffer";
                req.onreadystatechange = function () {
                    if (req.readyState === 4){
                        if (req.status === 200) {
                            resolve(XlsxPopulate.fromDataAsync(req.response));
                        } else {
                            reject("Received a " + req.status + " HTTP code.");
                        }
                    }
                };
                req.send();
            });
        }

        // Function to convert column index to Excel column letter
        function getColumnLetter(index) {
            var letter = '';
            while (index >= 0) {
                letter = String.fromCharCode(index % 26 + 65) + letter;
                index = Math.floor(index / 26) - 1;
            }
            return letter;
        }
        // Function to apply dropdown to a column
        function applyDropdown(sheet, col, options) {
            var range = sheet.range(col + "2:" + col + "1048576"); // Apply dropdown to entire column starting from row 2
            range.dataValidation({
                type: 'list',
                showErrorMessage: true,
                formula1: '"' + options.join(',') + '"'
            });
        }

        rd.generate = function(type) {
            return rd.getWorkbook()
                .then(function (workbook) {
                    var r = workbook.sheet(0).range("A1:AY1");
                    var header = null;

                    if (vm.rd.customer_type.includes('Cannabis') || vm.rd.customer_type.includes('Hemp')) {
                        header = ['Sample Name*', 'Lot/Batch #*', 'Product Category*', 'Product Type*', 'Inhalable/Non-Inhalable*', 'Source UID', 'Strain Name', 'Strain Type', 'Label Claims', 'Product Specifications*'];
                    }
                    else {
                       header = ['Sample Name*', 'Lot/Batch #*', 'Product Category', 'Product Type', 'Inhalable/Non-Inhalable'];
                    }
                    var dropdownOptions = {}
                    var options = ['Yes', 'No']

                    vm.rd.tests.forEach( function(test) {
                        test.reporting_title ? header.push(test.reporting_title) : header.push(test.display_name);
                        var headerName = test.reporting_title ? test.reporting_title : test.display_name
                        dropdownOptions[headerName] = options;
                    });

                    header.forEach(function (headerName, index) {
                        var colLetter = getColumnLetter(index);
                        if (dropdownOptions[headerName]) {
                            applyDropdown(workbook.sheet(0), colLetter, dropdownOptions[headerName]);
                        }
                    });
                    r.value([header]);
                    workbook.sheet(0).row(1).style("bold", true);
                    workbook.sheet(1).hidden(true);
                    return workbook.outputAsync({ type: type });
                });
        }

        rd.generateBlob = function() {
            return rd.generate()
                .then(function (blob) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, "QA_RND Sample Submission.xlsx");
                    } else {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = "QA_RND Sample Submission.xlsx";
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }
                })
                .catch(function (err) {
                    alert(err.message || err);
                    throw err;
                });
        }

        vm.rd.handleExcel = function() {
            var file = $('#rd_upload_excel')[0].files[0];
            if (!file) return Promise.reject("You must select a file.");
            XlsxPopulate.fromDataAsync(file)
            .then( function(workbook) {
                $scope.$apply(rd.extractExcelValues(workbook.sheet(0).usedRange().value()));
            });
        }

        rd.createExcelAssayMap = function(file) {
            for( var i = vm.rd.excel_header_offset; i < file[0].length; i++) {
                if(file[0][i]) {
                    vm.rd.excel_assay_index_map[i] = vm.rd.assay_map[file[0][i]];
                }
                else {
                    break;
                }
            }
        }

        rd.createNewSample = function() {
            return ({
                sample_expanded: vm.rd.mainCollapse,
                sample_checked: null,
                sample_delete: false,
                sample_name: null,
                sample_lot_batch_number: null,
                sample_category: null,
                sample_type: null,
                sample_types: null,
                sample_inhalable: null,
                sample_source_id: null,
                sample_tests: [],
                sample_other_analyses: {},
                sample_strain_name: null,
                sample_strain_type: null,
                sample_label_claims: null,
                sample_expected_potency: null,
                sample_has_tests: null,
                sample_panel_by_id: null,
                sample_panel_type: null,
                sample_current_panel_title: null,
                focus: {
                    name: false,
                    lot_batch_number: false,
                    autofill: false
                },
                options: {
                    name: [],
                    lot_batch_number: [],
                    autofill: []
                }
            });
        }

        rd.extractCannabisValues = function(row, index) {
            vm.rd.sampleArray[index] = {}
            var test = null;
            var sample = rd.createNewSample();

            if(!row.every(allEmpty)) {
                var sample_category = row[2] ? formatSampleCategory(row[2]) : null;
                sample.sample_name = row[0] ? row[0] : null;
                sample.sample_lot_batch_number = row[1] ? row[1] : null;
                sample.sample_category = sample_category ? sample_category : null;
                sample.sample_types = sample_category ? sample_category.types : null;
                sample.sample_type = sample_category ? checkSampleType(sample_category.types, row[3]) : null;
                sample.sample_inhalable = row[4] ? formatInhalableFromImport(row[4]) : null;
                sample.sample_source_id = row[5] ? row[5] : null;
                sample.sample_strain_name = row[6] ? row[6] : null;
                sample.sample_strain_type = row[7] ? formatStrainType(row[7]) : null;
                sample.sample_label_claims = row[8] ? row[8] : null;
                sample.sample_expected_potency = row[9] ? row[9] : null;
                for( var j = vm.rd.excel_header_offset; j < row.length; j++) {
                    if (row[j] == 'Yes') {
                        test = Object.assign({}, vm.rd.excel_assay_index_map[j]);

                        if (test[1] && vm.rd.assay_map[test[1].display_name]) {
                            test[1].checked = true;
                            sample.sample_tests.push(test[1])
                            sample.sample_has_tests = true;
                            vm.rd.sampleArray[index][vm.rd.assay_map[test[1].display_name][0]] = true;
                        }
                        test = null;
                    }
                }
                vm.rd.samples.push(sample)
            }
        }

        rd.extractFoodValues = function(row, index) {
            vm.rd.sampleArray[index] = {}
            var test = null;
            var sample = rd.createNewSample();

            if(!row.every(allEmpty)) {
                var sample_category = row[2] ? formatSampleCategory(row[2]) : null;
                sample.sample_name = row[0] ? row[0] : null;
                sample.sample_lot_batch_number = row[1] ? row[1] : null;
                sample.sample_category = sample_category ? sample_category : null;
                sample.sample_types = sample_category ? sample_category.types : null;
                sample.sample_type = sample_category ? checkSampleType(sample_category.types, row[3]) : null;
                sample.sample_inhalable = row[4] ? formatInhalableFromImport(row[4]) : null;
                for( var j = vm.rd.excel_header_offset; j < row.length; j++) {
                    if (row[j] == 'Yes') {
                        test = Object.assign({}, vm.rd.excel_assay_index_map[j]);
                        // check if that assay exists for the customer
                        if (test[1] && vm.rd.assay_map[test[1].display_name]) {
                            test[1].checked = true;
                            sample.sample_tests.push(test[1])
                            sample.sample_has_tests = true;
                            vm.rd.sampleArray[index][vm.rd.assay_map[test[1].display_name][0]] = true;
                        }
                        test = null;
                    }
                }
                vm.rd.samples.push(sample)
            }
        }

       rd.extractExcelValues = function(file) {
            rd.createExcelAssayMap(file)

            if (vm.rd.customer_type.includes('Cannabis') || vm.rd.customer_type.includes('Hemp')) {
                for( var i = 1; i < file.length; i++) {
                    rd.extractCannabisValues(file[i], i-1);
                }
            }
            else {
                for( var i = 1; i < file.length; i++) {
                    rd.extractFoodValues(file[i], i-1);
                }
            }

            vm.rd.checkPageOne();
            rd.resetDeleteCheckboxes();
        }


        function allEmpty(value) {
            return value == '' || value == null || value == undefined;
        }

        vm.rd.deleteChecked = function() {
            for(var i = vm.rd.samples.length-1; i >= 0; i--) {
                if (vm.rd.samples[i].sample_delete) {
                    vm.rd.samples.splice(i, 1);
                }
            }
            rd.resetDeleteCheckboxes();
            vm.rd.checkPageOne();
        }

        vm.rd.clickCheckAllDelete = function() {
            vm.rd.samples.forEach( function(sample) {
                sample.sample_delete = $(':checkbox.delete-checkbox-header')[0].checked;
            });
        }

        vm.rd.clickDelete = function(index) {
            vm.rd.samples[index].sample_delete = !vm.rd.samples[index].sample_delete;
            $(':checkbox.delete-checkbox-header')[0].checked = false;
        }

        vm.rd.clickCheckAllTest = function(col) {
            var testToAdd = vm.rd.assay_map_by_index[col];
            var found = false;
            var selector = ':checkbox.assay-col-header-' + col;
            var test = {};

            vm.rd.samples.forEach( function(sample, sample_index) {
                sample.sample_tests.forEach( function(test, test_index) {
                    if (test.id == testToAdd.id) {
                        found = true;
                        sample.sample_tests[test_index].checked = $(selector)[0].checked;
                        vm.rd.sampleArray[sample_index][col] = $(selector)[0].checked;
                    }
                });
                if (!found) {
                    if (!vm.rd.sampleArray[sample_index]) {
                        vm.rd.sampleArray[sample_index] = {};
                        vm.rd.sampleArray[sample_index][col] = $(selector)[0].checked;
                    }
                    test = Object.assign({}, testToAdd)
                    vm.rd.sampleArray[sample_index][col] = $(selector)[0].checked;
                    test.checked = $(selector)[0].checked;
                    sample.sample_tests.push(test);
                }
                found = false;
               rd.checkSampleContainsTrueTests(sample_index);
            });
            vm.rd.checkPageTwo();
        }

        // add/remove test from sample when checkbox clicked
        vm.rd.checkClick = function (sample, testToAdd, row, col) {
            var found = false;
            var test = null;
            $(':checkbox.assay-col-header-' + col)[0].checked = false;
            // check if test exists, if yes then checked = !checked
            for(var i = 0; i < sample.sample_tests.length; i++) {
                if (sample.sample_tests[i].id == testToAdd.id) {
                    found = true;
                    sample.sample_tests[i].checked = vm.rd.sampleArray[row][col];
                    break;
                }
            }
            // if no, add that test
            if (!found) {
                test = Object.assign({}, testToAdd)
                test.checked = true;
                sample.sample_tests.push(test);
            }

            rd.checkSampleContainsTrueTests(row);
            vm.rd.checkPageTwo();
        }

        vm.rd.collapseAll = function() {
            vm.rd.mainCollapse = !vm.rd.mainCollapse;
            if (vm.rd.samples.length != 0) {
                vm.rd.samples.forEach( function(sample) {
                    sample.sample_expanded = vm.rd.mainCollapse;
                });
            }
        }

        rd.addAssay = function(test) {
            if(test.id != 13) {
                vm.rd.showTests[test.display_name] = true;
                vm.rd.showTestsArray.push(test.display_name);
                vm.rd.tests.push(test);
            }

        }

        rd.createAssayMaps = function() {
            vm.rd.tests.forEach( function(test, index) {
                test.index = index;
                vm.rd.assay_map[test.display_name] = [index, test]
                if(test.reporting_title) {
                    vm.rd.assay_map[test.reporting_title] = [index, test];
                }

                vm.rd.assay_map_by_index[index] = test;
            });
        }

        rd.getAssaysAddedByAnrescoBillingTab = function() {
            vm.customerData.assay_customer.forEach( function(assay_customer) {

                vm.rd.all_tests.forEach( function(assay) {
                    if ((assay.id == assay_customer.assay_id) && assay_customer.active) {
                    // if (assay.id == assay_customer.assay_id) {
                        if(findWithAttr(vm.rd.tests, 'id', assay.id) == -1) {
                            rd.addAssay(assay);
                        }
                    }
                });
            });
        }

         rd.getAssayData = function() {
            Assay.query({}, function(data) {
                if (data) {
                    var index = 0;
                    var test = null;
                    var add_assay = true;

                    data.forEach( function(assay) {
                        if (add_assay) {
                            for(var i = 0; i < vm.customerData.assay_customer.length; i++) {
                                if (assay.id == vm.customerData.assay_customer[i].assay_id) {
                                    assay.assay_customer = vm.customerData.assay_customer[i];
                                }
                            }

                            test = {
                                info_modal: parseAssayInfoModalData(assay),
                                portal_identifier: assay.portalIdentifier ? assay.portalIdentifier : null,
                                portal_client_type: assay.portal_client_type ? assay.portal_client_type : null,
                                display_name: assay.title,
                                reporting_title: assay.title ? assay.title : null,
                                base_price: assay.basePrice,
                                assay_customer: assay.assay_customer ? assay.assay_customer : null,
                                analysis_description: assay.analysis_description ? assay.analysis_description : null,
                                id: assay.id,
                                index: index
                            }

                            if(assay.portal_client_type) {
                                if(assay.portal_client_type == 'General') {
                                    rd.addAssay(test);
                                    index++
                                }
                                else if ((vm.rd.customer_type.toLowerCase().includes('cannabis') && assay.portal_client_type.toLowerCase().includes('cannabis'))) {
                                    if(rd.isBasicPotencyAssay(test)) {
                                        vm.rd.cannabinoid_assays[test.portal_identifier] = jQuery.extend({}, test);
                                        if(!vm.rd.cannabinoid_assay_added) {
                                            rd.addAssay(test);
                                            index++
                                            vm.rd.cannabinoid_assay_added = true;
                                        }
                                    }
                                    else {
                                        rd.addAssay(test);
                                        index++;
                                    }
                                }
                                else if ((vm.rd.customer_type.toLowerCase().includes('hemp') && assay.portal_client_type.toLowerCase().includes('hemp'))) {
                                    rd.addAssay(test);
                                    index++;
                                }
                                else if ((vm.rd.customer_type.toLowerCase().includes('food') && assay.portal_client_type.toLowerCase().includes('food'))) {
                                    rd.addAssay(test);
                                    index++;
                                }
                                else if ((vm.rd.customer_type.toLowerCase().includes('kratom') && assay.portal_client_type.toLowerCase().includes('kratom'))) {
                                    rd.addAssay(test);
                                    index++;
                                }
                            }
                            vm.rd.all_assay_map_by_id[assay.id] = test;
                            vm.rd.all_tests.push(test)
                        }
                        add_assay = true;
                    });
                }
                // getExistingAssays()
                rd.getAssaysAddedByAnrescoBillingTab();

                // sorts tests arrray by how many times each test has been ordered
                vm.rd.tests.sort( function(test_a ,test_b) {
                    if (!test_a.assay_customer) {
                        return 1;
                    }
                    else if (!test_b.assay_customer){
                        return -1;
                    }
                    else {
                        return test_b.assay_customer.count - test_a.assay_customer.count;
                    }
                });

                for (var i = 0; i < 5; i++) {
                    if (vm.rd.tests[i].assay_customer) {
                        if (vm.rd.tests[i].assay_customer.count) {
                            vm.rd.number_of_freq_assays++;
                        }
                        else {
                            break;
                        }
                    }
                    else {
                        break;
                    }
                }

                if (vm.rd.number_of_freq_assays != vm.rd.tests.length) {
                    var tests_sorted_by_name = vm.rd.tests.splice(vm.rd.number_of_freq_assays, vm.rd.tests.length).sort( function(name_a, name_b) {
                        var comparison = 0;
                        name_a = name_a.reporting_title ? name_a.reporting_title : name_a.display_name;
                        name_b = name_b.reporting_title ? name_b.reporting_title : name_b.display_name;

                        if(name_a > name_b) {
                            comparison = 1
                        }
                        else if(name_b > name_a) {
                            comparison = -1
                        }

                        return comparison
                    });
                    vm.rd.tests = vm.rd.tests.concat(tests_sorted_by_name);
                }

                rd.createAssayMaps();
            });

            // rd.setInfoBoxCoordinates();
        }

         rd.checkSampleContainsTrueTests = function(index) {
            if (Object.values(vm.rd.sampleArray[index]).indexOf(true) == -1) {
                vm.rd.samples[index].sample_has_tests = false;
                vm.rd.samples[index].sample_panel_selected = false;
                vm.rd.samples[index].sample_panel_type = false;
                vm.rd.samples[index].sample_panel_by_id = false;
                vm.rd.samples[index].sample_current_panel_title = false;

                return;
            }
            vm.rd.samples[index].sample_has_tests = true;
            return;
        }

        // removes tests with checked: false
        // necessary for table on page 4
        rd.cleanUncheckedTests = function() {
            if (vm.rd.samples.length > 0) {
                vm.rd.samples.forEach (function(sample) {
                    if (sample.sample_tests.length > 0) {
                        for(var i = sample.sample_tests.length - 1; i >= 0; i--){
                            if (!sample.sample_tests[i].checked){
                                sample.sample_tests.splice(i, 1);
                            }
                        }
                    }
                    angular.forEach(sample.sample_other_analyses, function(value, key) {
                        if (!value) {
                            delete sample.sample_other_analyses[key];
                        }
                    });
                });
            }
        }

        vm.rd.checkPageOne = function() {
            if (vm.rd.samples.length < 1) {
                vm.rd.pageOneValid = false;
                return;
            }

            for(var i = 0; i < vm.rd.samples.length; i++) {
                if (!vm.rd.samples[i].sample_name ||
                    !vm.rd.samples[i].sample_category ||
                    !vm.rd.samples[i].sample_type ||
                    !vm.rd.samples[i].sample_inhalable ||
                    !vm.rd.samples[i].sample_expected_potency ) {
                        vm.rd.pageOneValid = false;
                        return;
                }
            }

            vm.rd.pageOneValid = true;
            return;
        }

        vm.rd.checkPageTwo = function() {
            var other_analysis_is_empty = null;
            var sample_array_is_empty = null;

            for(var i = 0; i < vm.rd.samples.length; i++) {
                other_analysis_is_empty = Object.keys(vm.rd.samples[i].sample_other_analyses).length == 0;
                sample_array_is_empty = vm.rd.sampleArray[i] ? Object.keys(vm.rd.sampleArray[i]).length == 0 : true;

                if (sample_array_is_empty && other_analysis_is_empty){
                    vm.rd.pageTwoValid = false;
                    return;
                }
                else {
                    if (sample_array_is_empty) {
                        if (Object.values(vm.rd.samples[i].sample_other_analyses).indexOf(true) == -1) {
                            vm.rd.pageTwoValid = false;
                            return
                        }
                    }
                    else if (other_analysis_is_empty) {
                        if (Object.values(vm.rd.sampleArray[i]).indexOf(true) == -1) {
                            vm.rd.pageTwoValid = false;
                            return;
                        }
                    }
                    else {
                        if ((Object.values(vm.rd.samples[i].sample_other_analyses).indexOf(true) == -1) && (Object.values(vm.rd.sampleArray[i]).indexOf(true) == -1)) {
                            vm.rd.pageTwoValid = false;
                            return;
                        }
                    }
                }
            }
            vm.rd.pageTwoValid = true;
            return;
        }

        vm.rd.checkPageThree = function() {
            if (
                !vm.rd.pickup_arrival_date ||
                !vm.rd.deliveryPreference ||
                !($('#rdEmailRecepients').val().length > 0) ||
                (vm.rd.deliveryPreference === 'Anresco Pick-Up' && !vm.fullLocationPickup) ||
                (vm.fullLocationPickup && !vm.contact.phone)
            ) {
                vm.rd.pageThreeValid = false;
                return;
            }
            vm.rd.pageThreeValid = true;
            return;
        }

        function setHeightOfFirstTable() {
                var rightTableTrHeight = $('.rdTestsTableRight.varHeight thead').height();
                $('.rdTestsTableLeft thead').css('height', rightTableTrHeight);
        }

        vm.generateTurnaroundTimes = function(orderVersion) {
            var durations = [];

            orderVersion.samples.forEach( function(sample) {
                sample.sample_tests.forEach( function(test) {
                    if(test.info_modal && test.info_modal.duration) durations.push(test.info_modal.duration);
                });
            });

            if(!durations.length) return;

            var longest_duration = Math.max(...durations);
            var shortest_duration = Math.min(...durations);
            var options = [];


            if(longest_duration == shortest_duration) {
                options.push(`${longest_duration} days (standard)`);
            }
            else {
                options.push(`${shortest_duration}-${longest_duration} days (standard)`);
            }

            for(var duration = shortest_duration-1; duration > 0; duration--) {
                options.push(`${duration} days (rush)`);
            }

            orderVersion.turnaroundTimeOptions = options;
            orderVersion.turnaroundTime = options[0];

        }

        vm.rd.incrementPage = function() {
            vm.rd.step = vm.rd.step + 1;
            rd.cleanUncheckedTests()
            if (vm.rd.step == 2) {
                vm.rd.checkPageTwo();
                setTimeout( function() {
                    // rd.setInfoBoxCoordinates();
                    setHeightOfFirstTable();
                }, 100);
            }
            else if (vm.rd.step == 3) {
                vm.generateTurnaroundTimes(vm.rd);
                vm.rd.checkPageThree();
                rd.processAllSampleTests();
            }
            else if (vm.rd.step == 4) {
                vm.rd.emailsArray = vm.rd.emails.split(',');
            }
        }

        vm.rd.decrementPage = function() {
            vm.rd.step = vm.rd.step - 1;
            rd.cleanUncheckedTests()
            if (vm.rd.step == 2) {
                vm.rd.checkPageTwo();
                setTimeout( function() {
                    // rd.setInfoBoxCoordinates();
                }, 100);
            }
            else if (vm.rd.step == 3) {
                vm.rd.checkPageThree();
                rd.processAllSampleTests();
            }
        }

        vm.rd.removeSample = function(index) {
            vm.rd.samples.splice(index, 1);
            vm.rd.sampleArray.splice(index, 1);
            rd.resetDeleteCheckboxes();
            vm.rd.checkPageOne();
        }

        function formatDeliveryPreference(delivery_preference) {
            $rootScope.deliveryPreference = delivery_preference;

            if (delivery_preference == 'Anresco Pick-Up') {
                return 'ANRESCO_PICK_UP';
            }
            else if (delivery_preference == 'Client Drop-Off') {
                return 'CLIENT_DROP_OFF';
            }
            else if (delivery_preference == 'Mail') {
                return 'MAIL';
            }
            else {
                return null;
            }
        }

        function formatNameDict(name) {
            return name ? {name: name} : null;
        }

        function formatDate(date) {
            if (!date) return null;
            
            // If it's already a string in MM/DD/YYYY format, return as is
            if (typeof date === 'string' && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
                return date;
            }
            
            // Convert to Date object if it isn't already
            var dateObj = date instanceof Date ? date : new Date(date);
            
            // Check for invalid date
            if (isNaN(dateObj.getTime())) {
                return null;
            }
            
            // Format as MM/DD/YYYY with manual padding
            var month = (dateObj.getMonth() + 1).toString();
            month = month.length === 1 ? '0' + month : month;
            
            var day = dateObj.getDate().toString();
            day = day.length === 1 ? '0' + day : day;
            
            var year = dateObj.getFullYear();
            
            return month + '/' + day + '/' + year;
        }

        function formatSampleTests(sample) {
            var sampleTests = [];
            if (sample) {
                sample.sample_tests.forEach( function(test) {
                    if(test.checked){
                        sampleTests.push({
                            assay: {id: test.id},
                        });
                    }
                });
            }
            if (sample.sample_other_analyses) {
                if (Object.values(sample.sample_other_analyses).indexOf(true) != -1) {
                    sampleTests.push({
                        // consulting assay
                        assay: {id: 13}
                    });
                }
            }

            return sampleTests;
        }

        function formatTempSampleTests(sample, requestedtests) {
            var sampleTests = [];
            if (sample) {
                sample.sample_tests.forEach( function(test) {
                    sampleTests.push(test);
                });
            }
            if (sample.sample_other_analyses) {
                if (Object.values(sample.sample_other_analyses).indexOf(true) != -1) {
                    vm.otherData.display_name = requestedtests
                    sampleTests.push(vm.otherData)
                }
            }
            //  if (sample.sample_other_analyses) {
            //     if (Object.values(sample.sample_other_analyses).indexOf(true) != -1) {        
            //         var display_name = []
            //         Object.keys(sample.sample_other_analyses).forEach(function (e){
            //             display_name.push(e)
            //         })
            //         vm.otherData.display_name = display_name
            //         sampleTests.push(vm.otherData)

            //     }
            // }
            return sampleTests;
        }
        
        function formatInhalableFromImport(value) {
            return (value.toUpperCase().includes('NON') || value.toUpperCase().includes('-')) ? vm.rd.inhalable[1] : vm.rd.inhalable[0];
        }

        function formatInhalable(value) {
            return (value.toUpperCase().includes('NON') || value.toUpperCase().includes('-')) ? vm.rd.inhalable[1].value : vm.rd.inhalable[0].value;
        }

        function formatSampleCategory(cat) {
            if (cat.length > 1){
                for(var i = 0; i < vm.rd.categories.length; i++) {
                    if ((cat.charAt(0).toUpperCase() + cat.substring(1)) == vm.rd.categories[i.toString()].name) {
                        return vm.rd.categories[i.toString()]
                    }
                }
            }
            return  null;
        }

        function checkSampleType(types, type) {
            if (type) {
                type = type.charAt(0).toUpperCase() + type.toLowerCase().substring(1);
                for(var i = 0; i < types.length; i++) {
                    if (type.toLowerCase() == types[i.toString()].name.toLowerCase()) {
                        return types[i.toString()];
                    }
                }
            }
            return null;
        }

        function formatStrainType(name) {
            if (name) {
                name.toUpperCase() == "CBD" ? name = "CBD" : name = name.charAt(0).toUpperCase() + name.toLowerCase().substring(1);
                for(var i = 0; i < vm.rd.strainTypes.length; i++) {
                    if (name == vm.rd.strainTypes[i].name){
                        return vm.rd.strainTypes[i];
                    }
                }
            }
            return null;
        }

        rd.serializeSamples = function(samples) {
            var serializedSamples = [];
            if (samples) {
                samples.forEach( function(sample){
                    serializedSamples.push({
                        id: null,
                        name: sample.sample_name,
                        lotBatchNumber: sample.sample_lot_batch_number,
                        productCategory: typeof(sample.sample_category) == 'string' ? formatNameDict(sample.sample_category) : sample.sample_category,
                        productType: typeof(sample.sample_type) == 'string' ? formatNameDict(sample.sample_type) : sample.sample_type,
                        strainName: typeof(sample.sample_strain_name) != 'object' ? formatNameDict(sample.sample_strain_name) : sample.sample_strain_name,
                        strainType: typeof(sample.sample_strain_type) == 'string' ? formatNameDict(sample.sample_strain_type) : sample.sample_strain_type,
                        otherAnalysis: sample.sample_other_analyses ? formatOtherAnalyses(sample.sample_other_analyses) : null,
                        reportedUnits: null,
                        productBatchSize: null,
                        harvestBatchSize: null,
                        batchType: null,
                        inhalableEdible: typeof(sample.sample_inhalable) == 'object' ? sample.sample_inhalable.value : sample.sample_inhalable,
                        sCode: sample.sample_metrc_id ? sample.sample_metrc_id : null,
                        sourceId: sample.sample_source_id ? sample.sample_source_id : null,
                        labelClaims: sample.sample_label_claims,
                        expectedPotency: sample.sample_expected_potency,
                        manufactureDate: formatDate(sample.sample_manufacture_date),
                        harvestDate: formatDate(sample.sample_harvest_date),
                        sampleWeightPerIncrement: null,
                        sampleTests: formatSampleTests(sample),
                        sampleTests_temp: formatTempSampleTests(sample,vm.rd.other_analyses),
                        medicinal_batch: sample.medicinal_batch
                    });
                });
            }
            return serializedSamples;
        }

        rd.serializePayload = function() {
            vm.rd.submitting = true;
            var payload = {
                orderType: "RD",
                collection_type: null,
                customer_account_id: $localStorage.customerId,
                dateCreated: null,
                dateReceived: null,
                date_submitted: null,
                delivery_preference: formatDeliveryPreference(vm.rd.deliveryPreference),
                estimated_arrival_date: formatDate(vm.rd.pickup_arrival_date),
                partially_complete: null,
                poNumber: vm.rd.orderPO,
                reporting_preference: null,
                sample_condition: null,
                sampledDate: formatDate(vm.rd.pickup_arrival_date),
                manufacturingAddress: vm.samplingAddress ? vm.samplingAddress : null,
                samplingAddress: vm.manufacturingAddress ? vm.manufacturingAddress : null,
                invoicing_address: vm.invoicing_address ? vm.invoicing_address : vm.customerData.qbAddress,
                pickup_address: vm.pickup_address ? vm.pickup_address : null,
                phone_number: vm.contact.phone ? vm.contact.phone : null,
                shipping_carrier: null,
                specialInstructions: vm.rd.specialInstructions,
                state: null,
                submitted_by: vm.rd.emailsArray.join(),
                dynamic_turnaround_time: vm.rd.turnaroundTime.includes('10') ? '10 day (standard)' : vm.rd.turnaroundTime,
                id: null,
                samples: rd.serializeSamples(vm.rd.samples),
                tracking_number: vm.rd.tracking_number
            }

            return payload;
        }
        

        vm.rd.submitOrder = function() {
            $rootScope.type = 'R&D'
            CustomerOrder.save(rd.serializePayload(), onSaveSuccess, onSaveError);
        };
        // Function to process a single test asynchronously
        function processTest(sample) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(sample);
                }, 1000); 
            });
        }
        
            // },10000)
            // if(vm.rd.submitting){
            //     setInterval(() => {
            //         SweetAlert.swal({
            //             title: "Submitting your order",
            //             text: `Please remain on this page. The order is processing in the background and should submit in a few moments.`,
            //             type: "info"
            //         });
            //     },5000)
            // }
        

        vm.rd.addSample = function() {
            vm.rd.samples.push({
                sample_order_type: 'rd',
                sample_expanded: vm.rd.mainCollapse,
                sample_checked: null,
                sample_delete: false,
                sample_name: null,
                sample_lot_batch_number: null,
                sample_category: null,
                sample_type: null,
                sample_types: null,
                sample_inhalable: null,
                sample_source_id: null,
                sample_tests: [],
                sample_other_analyses: {},
                sample_strain_name: null,
                sample_strain_type: null,
                sample_label_claims: null,
                sample_expected_potency: null,
                sample_has_tests: null,
                sample_panel_by_id: null,
                sample_panel_type: null,
                sample_current_panel_title: null,
                focus: {
                    name: false,
                    lot_batch_number: false,
                    autofill: false
                },
                options: {
                    name: [],
                    lot_batch_number: [],
                    autofill: []
                },
                medicinal_batch: false
            });
            vm.rd.checkPageOne();
        }

        rd.getPanels = function(panel) {
            var assays_dict = [];

            if (panel) {
                panel.assays.forEach( function(assay) {
                    assays_dict.push({display_name: assay.title});
                });
            }

            return assays_dict;
        }

        rd.setPanelsByIdMap = function() {
            vm.rd.qbenchPanels.forEach( function(panel) {
                vm.rd.panel_by_id_map[panel.id] = [panel.title, panel.assays];
            });
            vm.rd.customPanels.forEach( function(panel) {
                vm.rd.panel_by_id_map[panel.id] = [panel.title, panel.assays];
            });
        }

        rd.pushPanel = function(panel) {
            var assays_dict =rd.getPanels(panel);

            // if (rd.allPanelAssaysPresent(assays_dict)) {
                vm.rd.qbenchPanels.push({
                    title: panel.title,
                    assays: assays_dict,
                    id: panel.id,
                    type: 'qbench_panel',
                });
            // }
        }

        rd.allPanelAssaysPresent = function(assays_dict) {
            var all_assays_present = true;
            assays_dict.forEach( function(assay) {
                if (vm.rd.showTestsArray.indexOf(assay.display_name) == -1) {
                    all_assays_present = false;
                }
           });
           return all_assays_present;
        }

        rd.getQbenchPanels = function() {
            Panel.query({},
                function(data) {
                    data.forEach( function(panel) {
                        if (panel.portalIdentifier && panel.panel_type) {
                            if (panel.panel_type == 'General') {
                               rd.pushPanel(panel);
                            }
                            else if ((vm.rd.customer_type.toLowerCase().includes('cannabis') || vm.rd.customer_type .toLowerCase().includes('hemp')) && (panel.panel_type.toLowerCase().includes('cannabis'))) {
                                rd.pushPanel(panel);
                            }

                            else if ((vm.rd.customer_type.toLowerCase().includes('food') || vm.rd.customer_type.toLowerCase().includes('kratom') || vm.rd.customer_type.toLowerCase().includes('other')) && (panel.panel_type.toLowerCase().includes('food'))) {
                               rd.pushPanel(panel);
                            }
                        }
                    });
                    rd.getPanelsByCustomer();

                    return;
                },
                function(e) {
                    console.log(e);
                    return;
                }
            );
        }

        rd.getPanelsByCustomer = function() {
            CustomPanel.query({},
                function(data) {
                    vm.rd.customPanels = [];
                    data.forEach( function(panel) {
                        vm.rd.customPanels.push({
                            title: panel.title,
                            assays: rd.getPanels(panel),
                            id: panel.id,
                            type: 'custom_panel'
                        });
                    });
                    rd.setPanelsByIdMap();
                },
                function(e){
                    console.log(e);
                }
            );
        }

        // ***************************** END RND FUNCTIONS *****************************

        $scope.$watch('vm.fullLocationManufactur', function() {
            vm.comp.checkPageTwo();
            vm.rd.checkPageThree();
        })

        $scope.$watch('vm.fullLocationComp', function() {
            vm.comp.checkPageTwo();
        });

        $scope.$watch('vm.fullLocationPickup', function() {
            vm.comp.checkPageTwo();
        });



        setPageTitle();

        function setPageTitle() {
            $rootScope.pageTitle = "Submit";
        }

        vm.error = null;

        vm.orderId = $state.params.id;

        //fix sidebar menu state
        vm.showsidebar = true;

        // changing the sequence of calling of apis
        // getCurrentContact();

        getDataList();

        vm.customers = [];
        $scope.$root.samplesComp = [];
        vm.samplesComp = $scope.$root.samplesComp;

        $scope.$root.samplesRnD = [];
        vm.samplesRnD = $scope.$root.samplesRnD;// samplovi su iz root koji su postavljeni u tom kontroleru iz dijaloga


        vm.orderComp = {
            orderType: "COMPLIANCE",
            collection_type: null,
            customer_account_id: $localStorage.customerId,
            dateCreated: null,
            dateReceived: null,
            date_submitted: null,
            delivery_preference: null,
            estimated_arrival_date: null,
            partially_complete: null,
            poNumber: null,
            reporting_preference: null,
            sample_condition: null,
            sampledDate: null,
            samplingAddress: null,
            invoicing_address: null,
            shipping_carrier: null,
            state: null,
            submitted_by: null,
            turnaround_time: '4-5 day (standard)',

            id: null,
            samples: []

        };

        vm.orderRnD = {
            orderType: "RD",
            collection_type: null,
            customer_account_id: $localStorage.customerId,
            dateCreated: null,
            dateReceived: null,
            date_submitted: null,
            delivery_preference: null,
            estimated_arrival_date: null,
            partially_complete: null,
            poNumber: null,
            reporting_preference: null,
            sample_condition: null,
            sampledDate: null,
            samplingAddress: null,
            invoicing_address: null,
            shipping_carrier: null,
            state: null,
            submitted_by: null,
            turnaround_time: '4-5 day (standard)',

            id: null,
            samples: []

        };

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;

        if (!vm.isAuthenticated()) {
            $state.go('login');
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.removeAddressId;
        vm.removeAddressElement;
        vm.newAddressList;

        distribution_list_toggle();
        manufacturing_list_toggle();
        invoicing_list_toggle();
        pickup_list_toggle();

        function createAddress(address, city, zipCode, name, medicalLicence) {
            var full_address = name + '\n' + address + '\n' + city + ' ' + zipCode;

            if(medicalLicence) {
                full_address += '\n' + medicalLicence
            }

            return full_address;
        }

        function createInvoicingAddress(address, city, zipCode, name, state) {
            return name + '\n' + address + '\n' + city + ' ' + zipCode + ' ' + state;
        }

        $scope.copyDistributionData = function(address, city, zipCode, name, medicalLicence, fullLocation) {
            vm.samplingAddress = createAddress(address, city, zipCode, name, medicalLicence);
            vm.fullLocationComp = fullLocation;
            distribution_list_toggle();
            if(vm.active_tab.comp) {
                vm.distributionAddressDict  = {
                    name: name,
                    address: address,
                    city: city,
                    zipCode: zipCode,
                    medicalLicence: medicalLicence
                }
            }

        }

        $scope.copyManufacturingData = function(address, city,  zipCode, name, medicalLicence, fullLocation) {
            vm.manufacturingAddress =  createAddress(address, city, zipCode, name, medicalLicence);
            vm.fullLocationManufactur = fullLocation;
            if(vm.active_tab.comp) {
                vm.comp.cultivationLocation  = {
                    name: name,
                    address: address,
                    city: city,
                    zipCode: zipCode,
                    medicalLicence: medicalLicence
                }
            }
            else {
                vm.reportingAddressDict = {
                    name: name,
                    address: address,
                    city: city,
                    zipCode: zipCode,
                    medicalLicence: medicalLicence
                }
            }

            manufacturing_list_toggle();
        }

        $scope.copyInvoicingData = function(address, city, zipCode, name, state) {
            var invoicing_address = createInvoicingAddress(address, city, zipCode, name, state);
            vm.invoicing_address = invoicing_address;
            vm.fullLocationInvoicing = invoicing_address;
            invoicing_list_toggle();
            vm.invoicingAddressDict  = {
                name: name,
                address: address,
                city: city,
                zipCode: zipCode,
                state: state
            }
        }

        $scope.copyPickupData = function(address, city, zipCode, name, state) {
            var pickup_address = createInvoicingAddress(address, city, zipCode, name, state);
            vm.pickup_address = pickup_address;
            vm.fullLocationPickup = pickup_address;
            pickup_list_toggle();
            vm.pickupAddressDict  = {
                name: name,
                address: address,
                city: city,
                zipCode: zipCode,
                state: state
            }
        }

        $scope.removeAddressData = function(address, addressId, element, input) {
            vm.removeAddressData = address;
            vm.removeAddressId = addressId;
            vm.removeAddressElement = element;

            setTimeout(function() {
                if ('DISTRIBUTION' === input)
                    vm.samplingAddress = "";
                else if ('MANUFACTURING' === input) {
                    vm.manufacturingAddress = "";
                }
                else if ('INVOICING' === input) {
                    vm.invoicingAddress = "";
                }
                else if ('PICKUP' === input) {
                    vm.pickupAddress = "";
                }
            }, 200);
        }

        function distribution_list_toggle() {
            $scope.DistributionList = "Distribution_close";
            $scope.openDistributionList = function() {
                if ($scope.DistributionList === "Distribution_close")
                    $scope.DistributionList = "Distribution_open";
                else
                    $scope.DistributionList = "Distribution_close";
            };
        }

        function manufacturing_list_toggle() {
            $scope.ManufacturingList = "Manufacturing_close";
            $scope.openManufacturingList = function() {
                if ($scope.ManufacturingList === "Manufacturing_close")
                    $scope.ManufacturingList = "Manufacturing_open";
                else
                    $scope.ManufacturingList = "Manufacturing_close";
            };
        }

        function invoicing_list_toggle() {
            $scope.InvoicingList = "Invoicing_close";
            $scope.openInvoicingList = function() {
                if ($scope.InvoicingList === "Invoicing_close")
                    $scope.InvoicingList = "Invoicing_open";
                else
                    $scope.InvoicingList = "Invoicing_close";
            };
        }

        function pickup_list_toggle() {
            $scope.PickupList = "Pickup_close";
            $scope.openpickupList = function() {
                if ($scope.PickupList === "Pickup_close")
                    $scope.PickupList = "Pickup_open";
                else
                    $scope.PickupList = "Pickup_close";
            };
        }

        $scope.newInvoicingAddress = function() {
            vm.InvoicingAddress.locationType = "INVOICING_LOCATION";
            AddressList.save(vm.InvoicingAddress, onSuccessCustomer, function() {

            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.newPickupAddress = function() {
            vm.PickupAddress.locationType = "PICKUP_LOCATION";
            AddressList.save(vm.PickupAddress, onSuccessCustomer, function() {

            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.newManufacturingAddress = function() {
            vm.ManufacturingAddress.locationType = "CULTIVATION_LOCATION";
            AddressList.save(vm.ManufacturingAddress, onSuccessCustomer, function() {

            });

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.newDistributionAddress = function() {
            vm.DistributionAddress.locationType = "DISTRIBUTION_LOCATION";
            AddressList.save(vm.DistributionAddress, onSuccessCustomer, null);

            function onSuccessCustomer(data, headers) {
                getDataList();
            }
        }

        $scope.removeAddressQuery = function() {
            AddressList.remove({
                id: vm.removeAddressId
            }, onSuccessCustomer, function() {
            });

            function onSuccessCustomer(data, headers) {
                vm.removeAddressElement.currentTarget.parentElement.style.display = "none";
            }
        }


        /* Autocomplate cities */

        var showCityListAllowed = false;
        var showZipListAllowed = false;

        $scope.toggleCity = function () {
          if (showCityListAllowed)
             $scope.showCityList = !$scope.showCityList;
        };

        $scope.toggleZip = function () {
          if (showZipListAllowed)
             $scope.showZipList = !$scope.showZipList;
        };

        $scope.loadCity = function(value) {
          if(typeof value == 'undefined')
            return;

          CityList.get({searchString: value}, function(data) {
            if (data.length == 0) {
              $scope.showCityList = false;
              showCityListAllowed = false;
              return;
            } else {
              $scope.showCityList = true;
              showCityListAllowed = true;
            }

            $scope.cityRecords = data;
          }, null);
        }

        $scope.loadZip = function(value) {
          if(typeof value == 'undefined')
            return;

          ZipList.get({searchString: value}, function(data) {
            if (data.length == 0) {
              $scope.showZipList = false;
              showZipListAllowed = false;
              return;
            } else {
              $scope.showZipList = true;
              showZipListAllowed = true;
            }
            $scope.cityRecordsZips = data;
          }, null);
        }

        $scope.clearCityZipListDataOnClose = function() {
          $scope.cityRecordsZips = [];
          $scope.cityRecords = [];
        }


        $scope.cityClick = function(city, zip, whereTo) {
          if (whereTo === 'MANUFACTURING') {
            vm.ManufacturingAddress.city = city;
            vm.ManufacturingAddress.zipCode = zip;
          } else {
            vm.DistributionAddress.city = city;
            vm.DistributionAddress.zipCode = zip;
          }
          $scope.toggleCity();
        }


        $scope.zipClick = function(value, whereTo) {
          if (whereTo === 'MANUFACTURING') {
            vm.ManufacturingAddress.zipCode = value;
          } else {
            vm.DistributionAddress.zipCode = value;
          }
          $scope.toggleZip();
        }


        /********************************************************************/

        function login() {
            collapseNavbar();
            //LoginService.open();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function getDataList() {
            AddressList.get({}, onSuccessCustomer, onErrorCustomer);

            function onSuccessCustomer(data, headers) {
                $scope.records = data;
            }

            function onErrorCustomer(e) {
                console.log(e);
            }
        }

        function getCurrentContact() {
            Contact2ForUser.get({}, onSuccessCustomer, onErrorCustomer);

            function onSuccessCustomer(data, headers) {
                vm.contact = data;
                if (vm.contact.phone === 'null') vm.contact.phone = null;
                rd.getQbenchPanels();
                food.getQbenchPanels();
            }

            function onErrorCustomer(e) {
                console.log(e);
            }
        }

        $scope.submitOrder = function() {
        	vm.isSaving = true;

            vm.orderComp.samples = vm.samplesComp;
            vm.error = null;

            $rootScope.type = vm.orderComp.orderType;

            var date = vm.orderComp.sampledDate;
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            vm.orderComp.sampledDate = date;

            // vm.orderComp.submitted_by = $('#emailToCompliance').val();
            CustomerOrder.save(vm.orderComp, onSaveSuccess, onSaveError);
        }

        $scope.submitorderRnD = function() {
        	vm.isSaving = true;


            var date = vm.orderRnD.estimated_arrival_date;
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            vm.orderComp.estimated_arrival_date = date;

            vm.orderRnD.samples = vm.samplesRnD;

            vm.orderRnD.submitted_by = $('#emailToRD').val();

            $rootScope.type = vm.orderRnD.orderType;
            vm.error = null;

            CustomerOrder.save(vm.orderRnD, onSaveSuccess, onSaveError);
        }


        vm.datePickerOpenStatus.dateReceived = false;
        vm.datePickerOpenStatus.estimated_arrival_date = false;
        vm.datePickerOpenStatus.pickup_arrival_date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onSaveSuccess(result) {
            vm.submitData == true;
            if(vm.rd.submitting) vm.rd.submitting = false;
            if(vm.food.submitting) vm.food.submitting = false;
            if(vm.comp.submitting) vm.comp.submitting = false;
            
            vm.isSaving = false;
            vm.orderId = result.id;
            $state.params.id = result.id;
            $state.go('samplesubmit.notify', {
                id: result.id
            });
        }

        function onSaveError(e) {
            console.log(e);
            var error_string = JSON.stringify(e);
            vm.error_string = error_string;

            var request_id = e && e.data && e.data.error ? e.data.error.request_id : undefined;

            
        	vm.isSaving = false;
            vm.error = "ERROR";
            if(vm.food.submitting) vm.food.submitting = false;
            if(vm.comp.submitting) vm.comp.submitting = false;
            if(vm.rd.submitting) vm.rd.submitting = false;
    
            $scope.errorText = '';
            $scope.errorFormPath = '';
            if(e.status == 500 || e.status == 504 || e.status == 400){
                if(vm.customerData.businessType.id == 2){
                    $scope.errorText = `Unfortunately there was an error with your portal submission and your order could not be processed by our LIMS. Our developers have been notified of the error and will look into patching it ASAP.<br /><br />   

                    For this order, please complete a PDF R&D Submission Form instead and include it with your samples. We sincerely apologize for the inconvenience.`;
                    $scope.errorFormPath = 'content/images/Cannabis_Submission_Form.pdf';
                }else{
                    $scope.errorText = `Unfortunately there was an error with your portal submission and your order could not be processed by our LIMS. Our developers have been notified of the error and will look into patching it ASAP.<br /><br />

                    For this order, please complete a PDF Submission Form instead and include it with your samples. We sincerely apologize for the inconvenience.`;
                    $scope.errorFormPath = 'content/images/Anresco-Submission-Form.pdf';
                }
                
            }else{
                $scope.errorText = `Unfortunately there was an error with your portal submission and your order could not be processed by our LIMS. Our developers have been notified of the error and will look into patching it ASAP.<br /><br />

                For this order, please complete a PDF <a href="content/files/pdf/sample-submit/Anresco-Submission-Form.pdf" download>Submission Form</a> instead and include it with your samples. We sincerely apologize for the inconvenience.`;
            }

            if (request_id)
                $scope.errorText = $scope.errorText + "<br /><br />" + "Request ID: " + request_id;

            SweetAlert.swal({
                title: "Order Submission Error",
                html: true,
                text: $scope.errorText,
                type: "error",
                showCancelButton: true,
                confirmButtonColor: "#4f8fcc",
                confirmButtonText: 'Submission Form',
                closeOnConfirm: true
            }, function(confirm) {
                if(confirm) {
                    if(vm.food.submitting) vm.food.submitting = false;
                    // var a = document.createElement('a');
                    // a.download = `Anresco Error Submit Error.log - ${new Date().toLocaleDateString()}`;
                    // var blob = new Blob([vm.error_string], {type: 'text/plain'});
                    // a.href = window.URL.createObjectURL(blob);
                    // a.click();
                    // a.remove();                   
                    var aAdditionalPDF = document.createElement('a');
                    aAdditionalPDF.download = "SubmissionForm.pdf"; 
                    fetch($scope.errorFormPath) 
                        .then(response => response.arrayBuffer())
                        .then(pdfBuffer => {
                            var pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
                            aAdditionalPDF.href = window.URL.createObjectURL(pdfBlob);
                            aAdditionalPDF.click();
                            aAdditionalPDF.remove();
                        })
                    .catch(error => {
                        console.error("Error fetching additional PDF file:", error);
            });
                }
                else{
                    if(vm.food.submitting) vm.food.submitting = false;
                }
            });
        }

        vm.openShippingGuide = function() {
            var a = document.createElement("a");

            document.body.appendChild(a);
            a.style = "display: none";
            a.href = "https://hubs.ly/H0MtR-n0";
            a.target = "_blank"
            a.click();
            document.body.removeChild(a);
        }

        vm.downloadOrderPdf = function() {
            var orderId = $state.params.id;
                        if (orderId) {
                if ($rootScope.type === 'COMPLIANCE') {
                    var fileName = "Compliance Order " + orderId + ".pdf";
                }
                else if ($rootScope.type === 'R&D' || $rootScope.type === 'RD') {
                    var fileName = "R&D Order " + orderId + ".pdf";
                }
                else if ($rootScope.type === 'FOOD') {
                    var fileName = "Analysis Order " + orderId + ".pdf";
                }
                else if ($rootScope.type === 'FDA'){
                    var fileName = "FDA Order " + orderId + ".pdf";
                }

                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                CustomerOrderPdfService.downloadPdf(orderId).then(function(result) {
                    var file = new Blob([result.data], {
                        type: 'application/pdf'
                    });
                    a.href = window.URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();
                });
            }
        }

        rd.setInfoBoxCoordinates = function() {
            var viewBox = $('#wrap');
            var relativeTo = null;
            var target = null;
            var left = null;
            var bottom = null;

            for(var i = 0; i < vm.rd.tests.length; i++) {
                if(vm.rd.tests[i].info_modal) {
                    relativeTo = '#info-modal-icon-' + i.toString();
                    target = '.info-modal-' + i.toString();

                    left = $(relativeTo).offset().left - 218;
                    bottom = viewBox.outerHeight() - $(relativeTo).offset().top - 25;

                    if(viewBox.outerWidth() - left < 350) {
                        left = left - 302;
                        vm.rd.tests[i].left = true;
                    }
                    else {
                        vm.rd.tests[i].left = false;
                    }

                    left = left.toString() + 'px';
                    bottom = bottom.toString() + 'px';

                    $(target).css({ left: left, bottom: bottom })
                }
            }
        }

        food.setInfoBoxCoordinates = function() {
            var viewBox = $('#wrap');
            var relativeTo = null;
            var target = null;
            var left = null;
            var bottom = null;

            for(var i = 0; i < vm.food.tests.length; i++) {
                if(vm.food.tests[i].info_modal) {
                    relativeTo = '#info-modal-icon-' + i.toString();
                    target = '.info-modal-' + i.toString();

                    left = $(relativeTo).offset().left - 218;
                    bottom = viewBox.outerHeight() - $(relativeTo).offset().top - 25;

                    if(viewBox.outerWidth() - left < 350) {
                        left = left - 302;
                        vm.food.tests[i].left = true;
                    }
                    else {
                        vm.food.tests[i].left = false;
                    }

                    left = left.toString() + 'px';
                    bottom = bottom.toString() + 'px';

                    $(target).css({ left: left, bottom: bottom })
                }
            }
        }

        getCustomerData();
        angular.element(document).ready(function() {

            $('#rd-tests-scrollbar').scroll(function(){
                // rd.setInfoBoxCoordinates();
            });

            $('#food-tests-scrollbar').scroll(function(){
                // food.setInfoBoxCoordinates();
            });

            // getCustomerData();

            setTimeout(function() {

                $("#table tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li class='tbl-dot-" + index + "'><span class='tbl-dot'></span></li>";
                    $("ul.tbl-dots").append(addLi);
                });

                var num_col = $("#table tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;
                $("#table tr:first > th").css("width", col_width + "%");

                $("#col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#col-prev").attr('disabled', 'disabled');
                        $("#col-next").prop("disabled", false);
                    }
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant == 2 || constant2 >= 2) {
                        $("#col-next").prop("disabled", false);
                    }
                });

                $("#col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    $('.tbl-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#col-next").attr('disabled', 'disabled');
                        $("#col-prev").prop("disabled", false);
                    }
                    $("#col-prev").prop("disabled", false);
                });

            }, 1000);
        });


        $('#emailToCompliance').tagsinput({
            tagClass: function(item) {
                return 'custom-label label-email'
    		}
    	});

        $('#rdEmailRecepients').tagsinput({
            tagClass: function(item) {
                return 'custom-label label-email rdColumnRightLabel'
            },
        });

        $('#foodEmailRecepients').tagsinput({
            tagClass: function(item) {
                return 'custom-label label-email rdColumnRightLabel'
            },
        });

        function getUserEmails() {
            CustomerOrder.getCustomerEmails({},
                function(data) {
                    $('#emailToCompliance').tagsinput({
                        tagClass: function(item) {
                            return 'custom-label label-email'
                        }
                    });

                    $('#rdEmailRecepients').tagsinput({
                        tagClass: function(item) {
                            return 'custom-label label-email rdColumnRightLabel'
                        },
                    });

                    $('#foodEmailRecepients').tagsinput({
                        tagClass: function(item) {
                            return 'custom-label label-email rdColumnRightLabel'
                        },
                    });

                    $('#emailToCompliance').tagsinput('add', data.emails);
                    $('#rdEmailRecepients').tagsinput('add', data.emails);
                    $('#foodEmailRecepients').tagsinput('add', data.emails);

                    $('#emailToCompliance').tagsinput('refresh');
                    $('#rdEmailRecepients').tagsinput('refresh');
                    $('#foodEmailRecepients').tagsinput('refresh');

                    $('input').on('itemAdded', function(event) {
                        !vm.customer_type.includes('food') ? vm.rd.checkPageThree() :  vm.food.checkPageThree();
                    });

                    $('input').on('itemRemoved', function(event) {
                        !vm.customer_type.includes('food') ? vm.rd.checkPageThree() :  vm.food.checkPageThree();
                    });
                    },
                function(e) {
                    console.log(e);
                }
            );
        }

        // leave for now, anresco may want this filter instead of the default angularjs filter
        // $scope.startWith = function(actual, expected) {
        //     var lowerStr = (actual + "").toLowerCase();
        //     return lowerStr.indexOf(expected.toLowerCase()) === 0;
        //   }

    }

})();
