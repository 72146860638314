(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('OrdersController', OrdersController);

OrdersController.$inject = ['$state', '$stateParams','$scope','$rootScope', '$filter', 'CustomerOrder','CustomerOrderSearch',
    'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','Auth', 'Principal', 'ProfileService',
    'LoginService','CustomerByUser', 'OrderAttach', 'OrderAttachDownload', '$timeout'];

    function OrdersController($state, $stateParams, $scope,$rootScope, $filter, CustomerOrder,CustomerOrderSearch, ParseLinks,
                              AlertService, paginationConstants, pagingParams, Auth, Principal, ProfileService,
                              LoginService,CustomerByUser, OrderAttach, OrderAttachDownload, $timeout) { /*,Auth, Principal, ProfileService, LoginService*/

        var vm = this;
        vm.state = {};
        $rootScope.showSearch = pagingParams.search;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
    	vm.queryParams = $stateParams.queryParams;

        $scope.starred = pagingParams.starred === true;
        $scope.isInProgress = pagingParams.isInProgress === true;
        $scope.isCompleted = pagingParams.isCompleted === true;
        $scope.total = !$scope.starred && !$scope.isInProgress && !$scope.isCompleted;

        function createAttributesCountDict() {
            var dict = {
                starred: [1], 
                state: ['SAMPLES_RECEIVED', 'PRELIMINARY_RESULTS'], 
                state_copy: ['COMPLETED'],
                total: [true]
            };
        
            var filters = Object.assign({}, vm.sampleFilters);  
            filters.isInProgress = null;
            filters.starred = null;
            filters.isCompleted = null;
        
            var payload = {
                headers: dict,
                filters: filters
            }
        
            vm.filterCounts = {};
            return payload;
        }
        


    CustomerOrder.attributeCounts(createAttributesCountDict(),
    function(data){

        vm.filterCounts.total = data.total;
        vm.filterCounts.starred = data.starred;
        vm.filterCounts.isInProgress = data.state;
        vm.filterCounts.isCompleted = data.state_copy;
    },

    function(e) {
        console.error("❌ API Error:", e);
        console.log("📤 Sending payload to API:", JSON.stringify(createAttributesCountDict()));

    }
);
        
        

        $scope.getAttachments = function (order,id) {
	        vm.getAttachmentsLoader = true;
	        vm.idOfSelectedOrder = id;

	        if(order.attachments != null){
		        vm.getAttachmentsLoader = false;
		        return;
	        }

	        OrderAttach.query({id: id}, onSuccess, onError);
	        function onSuccess(data, headers) {
		        vm.getAttachmentsLoader = false;
		        order.attachments = data;
	        }
	        function onError(error) {
	        	AlertService.error(error.data.message);
	        }
        }

		$scope.downloadAttachment = function (id_hash,order) {
		    OrderAttachDownload.get({id: id_hash}, onSuccess, onError);

		    function onSuccess(data, headers) {
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.style = "display: none";

	            var fileURL = window.URL.createObjectURL(data.data);
	            a.href = fileURL;
	            a.download = headers('content-disposition').substring(21);
	            a.click();
		        order.select = "";
		    }

		    function onError(error) {

		    }
		}

        $scope.itemArray = [
            {id: 0, name: 'All', value: 'ALL'},
            {id: 1, name: 'Created', value: 'CREATED'},
            {id: 2, name: 'Samples Received', value: 'SAMPLES_RECEIVED'},
            {id: 3, name: 'Preliminary Results', value: 'PRELIMINARY_RESULTS'},
            {id: 4, name: 'Completed', value: 'COMPLETED'},
            {id: 5, name: 'Retesting', value: 'RETESTING'}
            // {id: 5, name: 'Invoiced', value: 'INVOICED'}
        ];

        $scope.selected = { value: $scope.itemArray[0] };

        setPageTitle();

        function setPageTitle(){
            $rootScope.pageTitle = "Orders";
            $rootScope.searchVm = vm;
        }

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.itemsPerPage = "20";
        vm.state = "ALL";
        if(pagingParams.items != null){
            vm.itemsPerPage = pagingParams.items;
        }

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.setpagesize = setpagesize;
        vm.setFilter = setFilter;

        if(!vm.isAuthenticated()){
             $state.go('login');
        }

        if ($rootScope.sampleOrderFilter) {
            vm.state = $rootScope.sampleOrderFilter.value;
            $scope.selected = { value: $rootScope.sampleOrderFilter };
        }

        vm.datePickerOpenStatus = {};

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.toggleWidget = function(name) {
        	vm.activeWidget = name === vm.activeWidget ? null : name;
        }

        vm.listFilters = {
        		"1 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"2 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"3 day (rush)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"4-5 day (standard)" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},
        		"TATOther" : {
        			selected: false,
        			field: "turnaroundTimes"
        		},

        		"CREATED": {
        			selected: false,
        			field: "states"
        		},
        		"SAMPLES_RECEIVED": {
        			selected: false,
        			field: "states"
        		},
        		"PRELIMINARY_RESULTS": {
        			selected: false,
        			field: "states"
        		},
        		"COMPLETED": {
        			selected: false,
        			field: "states"
        		}
        }
        vm.toggleListFilter = function(field) {
        	var values = [];

        	for (var key in vm.listFilters) {
        		if (vm.listFilters[key].field === field && vm.listFilters[key].selected) {
        			values.push(key);
        		}
        	}
        	vm.orderFilters[field] = values;
        }

        vm.orderFilters = {
        		id: pagingParams.id,
        		sampleCount: pagingParams.sampleCount,
        		turnaroundTimes: pagingParams.turnaroundTimes,
        		dateReceivedLower: pagingParams.dateReceivedLower ? convertLocalDateFromServer(pagingParams.dateReceivedLower) : null,
        		dateReceivedUpper: pagingParams.dateReceivedUpper ? convertLocalDateFromServer(pagingParams.dateReceivedUpper) : null,
        		states: pagingParams.states,
                starred: pagingParams.starred ? true : null,
                isInProgress: pagingParams.isInProgress ? true : null,
                isCompleted: pagingParams.isCompleted ? true : null
        }

            if (vm.orderFilters.isInProgress) {
                vm.orderFilters.states = ["SAMPLES_RECEIVED", "PRELIMINARY_RESULTS"];
            }
            else if (vm.orderFilters.isCompleted) {
                vm.orderFilters.states = ["COMPLETED"];
            } else if (!pagingParams.states) {
                vm.orderFilters.states = null; 
            }
        if (pagingParams.turnaroundTimes) {
        	if (Array.isArray(pagingParams.turnaroundTimes)) {
            	for (var i = 0; i < pagingParams.turnaroundTimes.length; i++) {
            		if (vm.listFilters[pagingParams.turnaroundTimes[i]]) {
            			vm.listFilters[pagingParams.turnaroundTimes[i]].selected = true;
            		}
            	}
        	} else {
        		vm.listFilters[pagingParams.turnaroundTimes].selected = true;
        	}
        }
        if (pagingParams.states) {
        	if (Array.isArray(pagingParams.states)) {
            	for (var i = 0; i < pagingParams.states.length; i++) {
            		if (vm.listFilters[pagingParams.states[i]]) {
            			vm.listFilters[pagingParams.states[i]].selected = true;
            		}
            	}
        	} else {
        		vm.listFilters[pagingParams.states].selected = true;
        	}

        }



        vm.applyFilters = applySampleFilters;
        function applySampleFilters() {
            var params = createFilterQueryParams();

            CustomerOrder.filter(params, onSuccess, onError);
            function onSuccess(result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerOrders = result;
                vm.page = 0;
                vm.pagingParams = pagingParams;
            }
            function onError(error) {
            	console.log("Error applying filter");
            	console.log(error);
            }
        }
        

        function getFilterCount() {
            var totalFilters = 0;
            for (var key in vm.orderFilters) {
                if (vm.orderFilters[key] !== null && vm.orderFilters[key] !== "" && vm.orderFilters[key] !== undefined && vm.orderFilters[key] !== false) {
                    if (Array.isArray(vm.orderFilters[key])) {
                        if (vm.orderFilters[key].length > 0) totalFilters++;
                    } else {
                        totalFilters++;
                    }
                }
            }
            vm.totalFilters = totalFilters;
            return totalFilters;
        }
        
        getFilterCount();
        vm.getFilterCount = getFilterCount;
        vm.filterSubmit = function() {
            vm.page = 1;
            
            var activeTabName = "total";
            if ($scope.starred) activeTabName = "starred";
            else if ($scope.isInProgress) activeTabName = "isInProgress";
            else if ($scope.isCompleted) activeTabName = "isCompleted";
                        
            if (activeTabName === "starred") {
                vm.orderFilters.starred = true;
                pagingParams.starred = true;
            } else if (activeTabName === "isInProgress") {
                vm.orderFilters.states = ["SAMPLES_RECEIVED", "PRELIMINARY_RESULTS"];
                pagingParams.isInProgress = true;
            } else if (activeTabName === "isCompleted") {
                vm.orderFilters.states = ["COMPLETED"];
                pagingParams.isCompleted = true;
            }
            
            vm.transition();
            applySampleFilters();
            
        }

        $scope.all = true;
        vm.switchTab = function(tab) {
            vm.orderFilters = {
                id: null,
                sampleCount: null,
                turnaroundTimes: null,
                dateReceivedLower: null,
                dateReceivedUpper: null,
                states: null,
                starred: null
            };
            
            $scope.starred = false;
            $scope.isInProgress = false;
            $scope.isCompleted = false;
            $scope.total = false;
            
            $('#starred, #starred_controls, #isInProgress, #isInProgress_controls, #isCompleted, #isCompleted_controls, #total, #total_controls')
                .removeClass('active');
            
            angular.forEach(vm.tabs, function(value, key) {
                vm.tabs[key] = false;
            });
            
            if (tab === 'starred') {
                $scope.starred = true;
                vm.tabs.starred = true;
                pagingParams.starred = true;
                vm.orderFilters.starred = true;
                $('#starred, #starred_controls').addClass('active');
            }
            else if (tab === 'isInProgress') {
                $scope.isInProgress = true;
                vm.tabs.isInProgress = true;
                pagingParams.isInProgress = true;
                vm.orderFilters.states = ["SAMPLES_RECEIVED", "PRELIMINARY_RESULTS"];
                $('#isInProgress, #isInProgress_controls').addClass('active');
            }
            else if (tab === 'isCompleted') {
                $scope.isCompleted = true;
                vm.tabs.isCompleted = true;
                pagingParams.isCompleted = true;
                vm.orderFilters.states = ["COMPLETED"];
                $('#isCompleted, #isCompleted_controls').addClass('active');
            }
            else if (tab === 'total') {
                $scope.total = true;
                vm.tabs.total = true;
                pagingParams.total = true;
                $('#total, #total_controls').addClass('active');
            }
            
            getFilterCount();
            vm.filterSubmit();

        }

        vm.setTabViews = function() {
            setTimeout(function() {
                if (pagingParams.starred) {
                    $('#starred').addClass('active');
                    $('#starred_controls').addClass('active');
                    $('#isInProgress').removeClass('active');
                    $('#total').removeClass('active');
                    $('#isInProgress_controls').removeClass('active');
                    $('#total_controls').removeClass('active');
                    $('#isCompleted').removeClass('active');
                    $('#isCompleted_controls').removeClass('active');
                }
                else if (pagingParams.isInProgress) {
                    $('#isInProgress').addClass('active');
                    $('#isInProgress_controls').addClass('active');
                    $('#starred').removeClass('active');
                    $('#total').removeClass('active');
                    $('#starred_controls').removeClass('active');
                    $('#total_controls').removeClass('active');
                    $('#isCompleted').removeClass('active');
                    $('#isCompleted_controls').removeClass('active');
                }
                else if (pagingParams.isCompleted) {
                    $('#isCompleted').addClass('active');
                    $('#isCompleted_controls').addClass('active');
                    $('#starred').removeClass('active');
                    $('#total').removeClass('active');
                    $('#starred_controls').removeClass('active');
                    $('#total_controls').removeClass('active');
                    $('#isInProgress').removeClass('active');
                    $('#isInProgress_controls').removeClass('active');
                }
                else {
                    $('#total').addClass('active');
                    $('#total_controls').addClass('active');
                    $('#starred').removeClass('active');
                    $('#isInProgress').removeClass('active');
                    $('#starred_controls').removeClass('active');
                    $('#isInProgress_controls').removeClass('active');
                    $('#isCompleted').removeClass('active');
                    $('#isCompleted_controls').removeClass('active');
                }
            }, 10);
        }

		vm.setTabViews();

		vm.tabs = {
			total: totalOnly(),
			isInProgress: pagingParams.isInProgress ? pagingParams.isInProgress : false,
			starred: pagingParams.starred ? pagingParams.starred : false,
			isCompleted: pagingParams.isCompleted ?pagingParams.isCompleted : false,
			external: false
		}

		function totalOnly() {
			if (!pagingParams.isInProgress && !pagingParams.starred && !pagingParams.isCompleted) {
				return true;
			}
			else {
				return false;
			}
		}	
        vm.toggleStarredView = function() {
			$scope.starred = !$scope.starred;

			vm.filterSubmit();
		}

        	function createStarredPayload() {
			var dict = {
				starred: 1,
			};

			var filters = Object.assign({}, vm.CustomerOrder);
			filters.isInProgress = null;
			filters.starred = null;
			filters.isCompleted = null;

			var payload = {
				headers : dict,
				filters: filters
			}

			return payload
		}


        function getStarredOrdersCount() {
			CustomerOrder.attributeCounts(createStarredPayload(),
			function(data){
				vm.filterCounts.starred = data.starred;
			},

			function(e) {
				console.log(e)
			}
		);
		}



        $scope.toggleStarred = function(id, order) {
          
            CustomerOrder.toggleStarred({ id: id }, {}, onSuccess, onError);
         	function onSuccess (data, headers) {
                order.starred = data.starred
        	}

        	function onError(error) {
                console.log("error ", error);
		    }
        };



        function createFilterQueryParams() {
        	var params = {};
            var dateFields = ["dateReceivedLower", "dateReceivedUpper"];
        	for (var key in vm.orderFilters) {
        		if (dateFields.indexOf(key) !== -1) {
         			params[key] = convertLocalDateToServer(vm.orderFilters[key]);

         		} else if (vm.orderFilters[key] !== "" && vm.orderFilters[key] !== null && vm.orderFilters[key] !== undefined) {
        			params[key] = vm.orderFilters[key];
        		}
        	}

        	params.page = vm.page;
        	params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            params.size = vm.itemsPerPage;
            params.items = params.size;
            params.search = vm.currentSearch;
            
            if ($scope.starred) {
                params.starred = true;
            }
            if ($scope.isInProgress) {
                params.isInProgress = true;
            }
            if ($scope.isCompleted) {
                params.isCompleted = true;
            }

        	return params;
        }


     

        function loadAll () { 

        	if (vm.totalFilters > 0) {
				 var params = createFilterQueryParams();
              
                 params.page = pagingParams.page - 1;
				 CustomerOrder.filter(params, onSuccess, onError);

        	} else if (pagingParams.search) {
                CustomerOrderSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            else {
                CustomerOrder.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    state: vm.state
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'dateReceived') {
                    result.push('dateReceived');
                }

                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerOrders = data;
                vm.page = pagingParams.page;

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAll();

        function getCustomerData () {
            CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
                 vm.customerData = data;
            }
            function onError(error) {

            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var params = createFilterQueryParams();
            
            if (params.starred === false) {
                delete params.starred;
            }
            if (params.isInProgress === false) {
                delete params.isInProgress;
            }
            if (params.isCompleted === false) {
                delete params.isCompleted;
            }
            
            var currentTabState = {
                starred: $scope.starred,
                isInProgress: $scope.isInProgress,
                isCompleted: $scope.isCompleted,
                total: $scope.total
            };
            
            $state.transitionTo($state.$current, params);
            
            $timeout(function() {
                $scope.starred = currentTabState.starred;
                $scope.isInProgress = currentTabState.isInProgress; 
                $scope.isCompleted = currentTabState.isCompleted;
                $scope.total = currentTabState.total;
                
                vm.forceSyncTabUI();
                            }, 50);
        }
        
        vm.forceSyncTabUI = function() {
            if ($scope.starred) {
                $('#starred, #starred_controls').addClass('active');
                $('#isInProgress, #isInProgress_controls, #isCompleted, #isCompleted_controls, #total, #total_controls').removeClass('active');
            }
            else if ($scope.isInProgress) {
                $('#isInProgress, #isInProgress_controls').addClass('active');
                $('#starred, #starred_controls, #isCompleted, #isCompleted_controls, #total, #total_controls').removeClass('active');
            }
            else if ($scope.isCompleted) {
                $('#isCompleted, #isCompleted_controls').addClass('active');
                $('#starred, #starred_controls, #isInProgress, #isInProgress_controls, #total, #total_controls').removeClass('active');
            }
            else {
                $('#total, #total_controls').addClass('active');
                $('#starred, #starred_controls, #isInProgress, #isInProgress_controls, #isCompleted, #isCompleted_controls').removeClass('active');
            }
        };

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

        function setFilter(filter) {
            vm.page = 0;
            pagingParams.page = 0;
            $stateParams['page']= 0;
            $rootScope.sampleOrderFilter =  filter;
            vm.state = filter.value;
            loadAll ();
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'dateReceived';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.orderFilters = {};
            
            if (!$scope.starred && !$scope.isInProgress && !$scope.isCompleted) {
                $scope.total = true;
            }
            
            vm.transition();
        }

        /* Date Utils (import DateUtils service doesn't work) */
        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function padToTwo(number) {
            return `0${number}`.slice(-2);
          }

        function formatDate(date) {
            var month = date.getUTCMonth() + 1;
            var day = date.getUTCDate();
            var year = date.getUTCFullYear();

            return `${padToTwo(month)}/${padToTwo(day)}/${year}`;
        }

        vm.parseDueDate = function (order) {
            var current_due_date = null;
            var sample = null;
            var tests = null;
            var test = null;

            for(var sample_index = 0; sample_index < order.samples.length; sample_index++) {
                sample = order.samples[sample_index];
                tests = order.samples[sample_index].sampleTests;

                for(var test_index = 0; test_index < tests.length; test_index++) {
                    test = tests[test_index];
                    if(current_due_date == null) current_due_date = test.estimatedCompleteDate;
                    if(test.estimatedCompleteDate != current_due_date) return "Various";
                }
            }

            if(!current_due_date) return "Not Set";

			try {
                current_due_date = new Date(current_due_date);
                if(current_due_date == 'Invalid Date') throw 'Invalid Date'

				return formatDate(current_due_date);
			}
			catch(e) {
				console.log(e);
                return current_due_date;
			}
        }

        angular.element(document).ready(function() {
            setTimeout(function () {

                $("#table tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li class='tbl-dot-" + index + "'><span class='tbl-dot'></span></li>";
                    $("ul.tbl-dots").append(addLi);
                });

                var num_col = $("#table tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;
                $("#table tr:first > th").css("width", col_width+"%");

                $("#col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#col-prev").attr('disabled', 'disabled');
                        $("#col-next").prop("disabled", false);
                    }
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if(constant == 2 || constant2 >= 2){
                        $("#col-next").prop("disabled", false);
                    }
                });

                $("#col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    $('.tbl-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#col-next").attr('disabled', 'disabled');
                        $("#col-prev").prop("disabled", false);
                    }
                    $("#col-prev").prop("disabled", false);
                });

            }, 1000);
        });
        
        vm.SampleDetail = function(samples){
            $('#rdAddRequestedTest').modal('show');
            vm.modalsamples = samples
        }
        vm.redirectSamples = function(orderId){
            $('#rdAddRequestedTest').modal('hide');
        	$state.go('samplelog', {orderId});
        }
        vm.openPublicSample = function(event, jsEvent, view) {
        	$state.go('public', {hash: event.hash});
        }

        $timeout(function() {
            vm.forceSyncTabUI();
        }, 100);
    }
})();
