(function () {
    'use strict';

    angular.module('anrescowebApp').controller('PublicPageController', PublicPageController)
    .directive('testStatus', function(){
    	return {
    		scope: {test:'='},
    		link: function (scope, element, attrs){

                var html = '';
                var test = scope.test;

                if (test) {
                	if (test.state === 'RETESTING') {
                		html += '<p class="test-retesting"><span class="fa fa-repeat"></span>&nbsp;<span>Retesting</span></p>';
                	}
                	else if (test.caution) {
                		html += '<p class="test-caution"><span class="fa fa-warning"></span>&nbsp;<span>Caution</span></p>';
                	}
					else if (test.allComplete === false) { // Other Analyses
                		html += '<p class="in-progress"><span class="fa fa-clock-o"></span>&nbsp;<span>In Progress</span></p>';
                	}
					else if (test.canSeeResults) {
                        if (test.statusPf === 'FAIL') {
                            html += '<p class="test-fail"><span class="fa fa-times-circle"></span>&nbsp; Fail</p>';
                        }
                        else if (test.statusPf === 'PASS') {
                            html += '<p class="test-pass"><span class="fa fa-check-circle"></span>&nbsp; Pass</p>';
                        }
                        else {
                            html += '<p class="test-completed"><span class="fa fa-stop-circle"></span>&nbsp;<span>Tested</span></p>';
                        }
                	}
					else {
                		html += '<p class="in-progress"><span class="fa fa-clock-o"></span>&nbsp;<span>In Progress</span></p>';
                	}
				}
				else {
                	html += '<p class="not-tested"><span class="fa fa-stop-circle"></span>&nbsp;<span>Not Tested</span></p>';
                }

                element.html(html);

    		}
    	}
    })
    .directive('testSatusIcon', function() {
        return {
    		scope: {test:'='},
    		link: function (scope, element, attrs){

                var html = '';
                var test = scope.test;

                if (test) {
                	if (test.state === 'RETESTING') {
                		html += '<p class="test-retesting"><span class="fa fa-repeat"></span></p>';
                	}
                	else if (test.caution) {
                		html += '<p class="test-caution"><span class="fa fa-warning"></span></p>';
                	}
					else if (test.allComplete === false) { // Other Analyses
                		html += '<p class="in-progress"><span class="fa fa-clock-o"></span></p>';
                	}
					else if (test.canSeeResults) {
                        if (test.statusPf === 'FAIL') {
                            html += '<p class="test-fail"><span class="fa fa-times-circle"></p>';
                        }
                        else if (test.statusPf === 'PASS') {
                            html += '<p class="test-pass"><span class="fa fa-check-circle"></p>';
                        }
                        else {
                            html += '<p class="test-completed"><span class="fa fa-stop-circle"></span></p>';
                        }
                	}
					else {
                		html += '<p class="in-progress"><span class="fa fa-clock-o"></span></p>';
                	}
				}
				else {
                	html += '<p class="not-tested"><span class="fa fa-stop-circle"></span></p>';
                }

                element.html(html);

    		}
    	}

    })
    .directive('psIcon', function(){
    	return {
    		scope: {title:'='},
    		link: function (scope, element, attrs){
                var title = scope.title ? scope.title.toLowerCase() : '';
                var icon_class = '';

                if(title.includes('cannabis')) {
                    icon_class = 'cannabinoid-icon';
                }
                else if(title.includes('terpenoids')) {
                    icon_class = 'terpenes-icon';
                }
                else if(title.includes('microbio')) {
                    icon_class = 'microbiology-icon';
                }
                else if(title.includes('solvent')) {
                    icon_class = 'solvents-icon';
                }
                else if(title.includes('heavy metals')) {
                    icon_class = 'heavy-metals-icon';
                }
                else if(title.includes('mycotoxins')) {
                    icon_class = 'mycotoxins-icon';
                }
                else if(title.includes('pesticides')) {
                    icon_class = 'pesticides-icon';
                }
                else {
                    icon_class = 'flask-icon';
                }

                element.html('<span class="icon-bar ' + icon_class +'\"></span>');
    		}
    	}
    });

    PublicPageController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$resource', '$localStorage',
                                    '$sessionStorage', 'SampleService', 'Sample', 'SampleSearch', 'Principal', 'CustomerByOrder',
                                     'CustomerMap', 'data', 'SampleCoaPdfService', '$window','SampleAttach','SampleAttachDownload','AlertService'];

    function PublicPageController($rootScope, $scope,  $state, $stateParams, $resource, $localStorage,
                                 $sessionStorage, SampleService, Sample, SampleSearch, Principal, CustomerByOrder,
                                  CustomerMap, data, SampleCoaPdfService, $window, SampleAttach, SampleAttachDownload, AlertService) {

        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;

        var parsers = {}
        vm.dataExists = data ? true : false;
        vm.viewMoreLength = 20;
        vm.viewMore = false;
        vm.view_more_sample_info = false;

        vm.showWideView = false;
        vm.showTallView = false;
        vm.googleChartsLoaded = false;
        vm.cannabinoidsTested = false;
        vm.terpenesTested = false;
        vm.moistureTested = false;
        vm.pesticidesMycotoxins = {
            tested: false,
            mycotoxins_units: 'ppb',
            loq_mycotoxins: '',
            pesticides_units: 'ppm',
            loq_pesticides: '',
            ochratoxin_a_limit: 20,
            total_aflatoxins_limit: 20,
        }

        vm.sample_can_see_report = false;
        vm.isComplianceOrder = false;
        vm.cannabinoidChartColorArray = []

		onResize(function(){
            showCorrectView();
            $scope.$apply();
        });

        showCorrectView();
        generateParsers();
        createSectionDecriptionMap();
        createTitleToSectionMap();
        SampleService.getWorksheetParserMap({},
            function(data) {
                delete data['$promise'];
                delete data['$resolved'];
                vm.parserMap = data;
                vm.sections = [];
                if(vm.dataExists) {
                    processSample();
                }
            },
            function(e) {
                console.log(e);
            }
        );

        vm.titleToSection = function(title) {
            if(vm.titleToSectionTitleMap[title]) {
                return vm.titleToSectionTitleMap[title];
            }
            else {
                return title;
            }
        }

        vm.titleToSectionHeader = function(title) {
            if(vm.titleToSetionHeader[title]) {
                return vm.titleToSetionHeader[title];
            }
            else {
                return title;
            }
        }

        function getAttachments(){
            SampleAttach.query({id: vm.sample.id}, onSuccess, onError);
            function onSuccess(data, headers) {
                var processed_attachments = [];
                vm.sample.attachments = data;
                vm.sample.attachments.forEach( function(attachment) {
                    if(!attachment.file_name.toLowerCase().includes('label')) {
                        processed_attachments.push(attachment)
                    }
                })
                vm.sample.attachments = processed_attachments;
                // sample.attachments = $scope.reorderAttachments(processed_attachments, true);
                angular.forEach(vm.sample.attachments,function(data,index){
                    if(vm.customer.canSeeAll){
                        data.canSee = true
                        vm.customer.canSeeCOASummary = true
                    }
                    else{
                        if(data.type == "SAMPLE_QR_CODE"){
                            vm.sample.attachments[index].canSee = vm.customer.canSeeQRcode ? true : false
                        }
                        if(data.type == "SAMPLE_QBENCH_SAMPLE_TEST_PDF"){
                            vm.sample.attachments[index].canSee = vm.customer.canSeeTestCOA ? true : false
                        }
                        if(data.type == "SAMPLE_QBENCH_SAMPLE_ATTACHMENT"){
                            vm.sample.attachments[index].canSee = vm.customer.canSeePictures ? true : false
                        }
                        if(data.file_name.toLowerCase().includes('_consolidated_coa')){
                            vm.sample.attachments[index].canSee = vm.customer.canSeeConsolidatedCOA ? true : false
                        }
                    }
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function createTitleToSectionMap() {
            vm.titleToSectionTitleMap = {};
            vm.titleToSectionTitleMap["Cannabis"] = 'Cannabinoids';
            vm.titleToSectionTitleMap["Terpenoids"] = 'Terpenes';
            vm.titleToSectionTitleMap["Alkaloids"] = 'Alkaloids';
            vm.titleToSectionTitleMap["Microbiology"] = 'Microbiology';
            vm.titleToSectionTitleMap["Pesticides"] = 'Pesticides';
            vm.titleToSectionTitleMap["Mycotoxins"] = 'Mycotoxins';
            vm.titleToSectionTitleMap["Residual Solvent Screen"] = 'Solvents';
            vm.titleToSectionTitleMap["Heavy Metals"] = 'Heavy Metals';
            vm.titleToSectionTitleMap["Allergens"] = 'Allergens';
            vm.titleToSectionTitleMap["Nutrients"] = 'Nutritionals';
            vm.titleToSectionTitleMap["Antibiotics"] = 'Antibiotics';
            vm.titleToSectionTitleMap["Chemistry"] = 'Chemistry';
            vm.titleToSectionTitleMap["Microscopy"] = 'Microscopy';
            vm.titleToSectionTitleMap["Other Analyses"] = 'Other Analyses';

            vm.titleToSetionHeader = {};
            vm.titleToSetionHeader["Cannabis"] = 'Cannabinoid Profile';
            vm.titleToSetionHeader["Terpenoids"] = 'Terpene Profile';
            vm.titleToSetionHeader["Alkaloids"] = 'Alkaloids';
            vm.titleToSetionHeader["Microbiology"] = 'Microbiology';
            vm.titleToSetionHeader["Pesticides"] = 'Pesticide Residue';
            vm.titleToSetionHeader["Mycotoxins"] = 'Mycotoxins';
            vm.titleToSetionHeader["Residual Solvent Screen"] = 'Residual Solvents';
            vm.titleToSetionHeader["Heavy Metals"] = 'Heavy Metals';
            vm.titleToSetionHeader["Allergens"] = 'Allergens';
            vm.titleToSetionHeader["Nutrients"] = 'Nutritionals';
            vm.titleToSetionHeader["Antibiotics"] = 'Antibiotics';
            vm.titleToSetionHeader["Chemistry"] = 'Chemistry';
            vm.titleToSetionHeader["Microscopy"] = 'Microscopy';
            vm.titleToSetionHeader["Other Analyses"] = 'Other Analyses';
        }

        vm.sectionTested = function(pf) {
            if(pf) {
                var pf_lowercase = pf.toLowerCase();
                if(pf_lowercase.toLowerCase() == 'not_tested' || pf_lowercase.toLowerCase() == 'in_progress' || pf_lowercase.toLowerCase() == 'retesting') {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return false;
            }
        }

        vm.overallPf = function(pf, show_results) {
            if(!show_results && ['tested', 'pass', 'fail'].includes(pf)) {
                return '<span class="test-completed"><span class="fa fa-stop-circle"></span>&nbsp;<span>Final Review</span></span>';
            }

            switch(pf) {
                case "not_tested":
                    return '<span class="not-tested"><span class="fa fa-stop-circle"></span>&nbsp;<span>Not Tested</span></span>';
                case "caution":
                    return '<span class="test-caution"><span class="fa fa-warning"></span>&nbsp;<span>Caution</span></span>';
                case "in_progress":
                    return '<span class="in-progress"><span class="fa fa-clock-o"></span>&nbsp;<span>In Progress</span></span>';
                case "retesting":
                    return '<span class="test-completed"><span class="fa fa-repeat"></span>&nbsp;<span>Retesting</span></span>';
                case "fail":
                    return '<span class="test-fail"><span class="fa fa-times-circle"></span>&nbsp; Fail</span>';
                case "pass":
                    return '<span class="test-pass"><span class="fa fa-check-circle"></span>&nbsp; Pass</span>';
                case "tested":
                    return '<span class="test-completed"><span class="fa fa-stop-circle"></span>&nbsp;<span>Tested</span></span>';
                default:
                    return '<span class="not-tested"><span class="fa fa-stop-circle"></span>&nbsp;<span>Not Tested</span></span>';
            }
        }

        function showCorrectView() {
            if($window.innerWidth < 1366) {
                vm.showWideView = false;
                vm.showTallView = true;

                if(vm.googleChartsLoaded && vm.cannabinoidsTested) {
                    drawCannabinoidChart('resize');
                }
                if(vm.googleChartsLoaded && vm.terpenesTested) {
                    try {
                        drawTerpenesChart('resize');
                    }
                    catch(e) {
                        console.log('Could not render terpenes graph');
                        console.log(e)
                    }
                }
            }
            else {
                setResultsWidth();

                if(vm.googleChartsLoaded && vm.cannabinoidsTested) {
                    drawCannabinoidChart('resize');
                }
                if(vm.googleChartsLoaded && vm.terpenesTested) {
                    try {
                        drawTerpenesChart('resize');
                    }
                    catch(e) {
                        console.log('Could not render terpenes graph');
                    }
                }
                vm.showWideView = true;
                vm.showTallView = false;

                resetPaginationArrows();
            }
        }

        function onResize(c,t) {
            onresize = function() { // refers to window.onresize callback
                clearTimeout(t);
                t = setTimeout(c,250);
                };
            return c;
        }

        $(document).click( function() {
            if(vm.showWideView) {
                resetPaginationArrows();
            }
        })

        function resetPaginationArrows() {
            $('.paginate_button.previous').text('');
            $('.paginate_button.previous').append("<i class=\"fa fa-chevron-left\"><i>");
            $('.paginate_button.next').text('');
            $('.paginate_button.next').append("<i class=\"fa fa-chevron-right\"><i>");
        }

        function determineCategoryShowResults(show_result_array) {
            if(!show_result_array) {
                return false
            }
            else if(show_result_array.includes(false)) {
                return false;
            }
            else {
                return true;
            }
        }

        function determineCategoryPf(pf_array) {
            var category_status = null;

            pf_array = pf_array.join('|').toLowerCase().split('|');

            if(pf_array.includes('not_tested')) {
                category_status = 'not_tested';
            }
            else if (pf_array.includes('in_progress')) {
                category_status = 'in_progress';
            }
            else if (pf_array.includes('retesting')) {
                category_status = 'retesting';
            }
            else if (pf_array.includes('caution')) {
                category_status = 'caution';
            }
            else if (pf_array.includes('fail')) {
                category_status = 'fail';
            }
            else if (pf_array.includes('pass')) {
                category_status = 'pass';
            }
            else {
                category_status = 'tested';
            }

            return category_status;
        }

        function determineTestPf(test) {
            var pf_value = null;

            if (test) {
                if (test.state === 'RETESTING') {
                    pf_value = 'retesting';
                }
                else if (test.caution) {
                    pf_value = 'caution';
                }
                else if (test.allComplete === false) {
                    pf_value = 'in_progress';
                }
                else if(test.orderState === 'CREATED' && !test.statusPf) {
                    pf_value = 'not_tested'
                }
                else if (test.statusPf === 'FAIL') {
                    pf_value = 'fail';
                }
                else if (test.statusPf === 'PASS') {
                    pf_value = 'pass';
                }
                else if (test.canSeeResults || test.state =='COMPLETED') {
                    pf_value = 'tested';
                }
                else {
                    pf_value = 'in_progress';
                }
            }
            else {
                pf_value = 'not_tested';
            }

            return pf_value
        }

        function parseEmptyWorksheet(test) {
            var category = test.assay.category ? test.assay.category : test.assay.title;
            var section_index = findWithAttr(vm.sections, 'title', category);

            var pf_dict = {
                canSeeResults: test.canSeeResults ?  test.canSeeResults : null,
                caution: test.caution ? test.caution : null,
                orderState: test.orderState ? test.orderState : null,
                statusPf: test.statusPf ? test.statusPf : null
            }

            var test_pf = determineTestPf(pf_dict);

            if(section_index == -1) {
                vm.sections.push({
                    title : category,
                    description : null,
                    overall_pf : null,
                    section_data : [],
                    expanded : false,
                    active: false,
                    info_box_open: false,
                });
                section_index = vm.sections.length - 1;
            }
            else {
                vm.sections[section_index].pf_values.push(test_pf);
            }
        }

        function determineUnits(analyte_units, assay_units) {
            if(analyte_units) {
                return analyte_units;
            }
            else if(assay_units) {
                return assay_units;
            }
            else {
                return null;
            }
        }

        function unitsRequired(value) {
            if(!value) {
                return false;
            }

            else if(typeof(value) == 'string') {
                var compare = value.toLowerCase();

                if(compare == 'nt' || compare == 'nd' || compare == 'bloq' || compare == 'blod' || compare == 'blq' || compare == '0' || compare == '0.00') {
                    return true;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        }

        function findWithAttr(array, attr, value) {
            for(var i = 0; i < array.length; i += 1) {
                if(array[i][attr].toLowerCase() === value.toLowerCase()) {
                    return i;
                }
            }
            return -1;
        }

        vm.sort_section = function(section) {
            if(!section.current_sort_by) {
                section.sort_by = 'status';
                section.current_sort_by = 'status';
            }
            else if(section.current_sort_by == 'status') {
                section.sort_by = 'name';
                section.current_sort_by = 'name';
            }
            else if(section.current_sort_by == 'name'){
                section.sort_by = 'status';
                section.current_sort_by = 'status';
            }

            section.section_data.sort( function(item_1, item_2) {
                if(!item_1[section.sort_by]) {
                    return 1;
                }
                else if(!item_2[section.sort_by]) {
                    return -1;
                }
                else if(item_1[section.sort_by] > item_2[section.sort_by]) {
                    return 1;
                  }
                else if(item_1[section.sort_by] < item_2[section.sort_by]) {
                    return -1;
                }
                else {
                    return 0
                }
            });
        }

        function findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date) {
            var section_index = findWithAttr(vm.sections, 'title', category);

            if(section_index == -1) {
                vm.sections.push({
                    title : category,
                    description : null,
                    overall_pf : null,
                    section_data : [],
                    pf_values: [test_pf],
                    show_results: [show_result],
                    expanded : false,
                    active: false,
                    info_box_open: false,
                    instrument: [instrument],
                    complete_date: [complete_date],
                    estimated_complete_date: [estimated_complete_date],
                    sort_by: '',
                    current_sort_by: '',
                });
                section_index = vm.sections.length - 1;
            }
            else {
                vm.sections[section_index].pf_values.push(test_pf);
                vm.sections[section_index].instrument.push(instrument);
            }

            return section_index;
        }

        var pass_fail_map = {
            false: 'Pass',
            true: 'Fail'
        }

        function parseValueObject(value_object) {
            if(value_object && value_object.twoDecimalFormattedValue) {
                return parseValue(value_object.twoDecimalFormattedValue)
            }
            else if(value_object && value_object.value) {
                return parseValue(value_object.value);
            }
            else {
                return null;
            }
        }

        function parseValue(value) {
            var parsed_value = parseFloat(value);
            const regex =  /[A-Za-z]/
            if(value) {
                if(value == 'NT' || value == 'ND' || value == 'BLOQ' || value == 'BLOD' || value == 'BLQ' || value.includes('<') || value.includes('>') || regex.test(value)) {
                    return value;
                }
                else if(value == '0' || value == '0.0' || value == '0.00') {
                    return 'ND';
                }
            }
            else {
                return value;
            }

            if(isNaN(parsed_value)) {
                return value;
            }
            else {
                return parsed_value
            }
        }

        function determineGeneralParserAnalyteStatus(analyte, ws) {
            var status = '';
            if(ws[analyte + '_pf']) {
                status = ws[analyte + '_pf'].value;
            }
            else if (ws[analyte + '_fail']) {
                status = pass_fail_map[ws[analyte + '_fail'].value]
            }

            return status;
        }

        function generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
            var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);
            angular.forEach(ws, function(value, key) {
                if(value.display_name && value.value) {
                    if(value.display_name.includes("&amp;delta;")){
                        var new_display_name = value.display_name.replace(/&amp;delta/g, '&Delta');
                        value.display_name = new_display_name
                    }
                    vm.sections[section_index].section_data.push({
                        name: value.display_name ? value.display_name : '',
                        value: value.value ? parseValue(value.value) : '',
                        units: determineUnits(value.units, units),
                        value_exists: unitsRequired(value.value),
                        status: determineGeneralParserAnalyteStatus(key, ws),
                        limit: ws[key + '_limit'] ? ws[key + '_limit'].value : '',
                        lod: ws[key + '_lod'] ? ws[key + '_lod'].value : '',
                        loq: ws[key + '_loq'] ? ws[key + '_loq'].value : '',
                        method:  ws[key + '_method'] ? ws[key + '_method'].value : ''
                    });
                }
            });
        }

        function microParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
            var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

            angular.forEach(ws, function(value, key) {
                var data_value = null;
                if(!key.includes('_method') && key != 'comments' && value) {
                    if(value.twoDecimalFormattedValue) {
                        data_value = value.twoDecimalFormattedValue
                    }
                    else if(value.value) {
                        data_value = value.value
                    }
                    vm.sections[section_index].section_data.push({
                        name: value.display_name ? value.display_name : '',
                        value: data_value ? parseValue(data_value) : '',
                        units: determineUnits(value.units, units),
                        value_exists: unitsRequired(data_value),
                        limit: ws[key + '_limit'] ? ws[key + '_limit'].value : '',
                        lod: ws[key + '_lod'] ? ws[key + '_lod'].value : '',
                        loq: ws[key + '_loq'] ? ws[key + '_loq'].value : '',
                        method:  ws[key + '_method'] ? ws[key + '_method'].value : ''
                    });
                }
            });
        }

        function createSectionDecriptionMap() {
            vm.sectionDescriptionMap = {}

            vm.sectionDescriptionMap['Microbiology'] = 'Screening for fungal and bacteria contamination.';
            vm.sectionDescriptionMap['Residual Solvent Screen'] = 'Screening for residual solvents utilizing gas chromatography mass spectrometry (GC/MS).';
            vm.sectionDescriptionMap['Heavy Metals'] = 'Screening for heavy metals utilizing inductively coupled mass spectrometry (ICP/MS).';
            vm.sectionDescriptionMap['Pesticides'] = 'Screening for pesticide and fungicide residues utilizing liquid chromatography tandem mass spectrometry (LC-MS/MS) & gas chromatography tandem mass spectrometry (GC-MS/MS).';
            vm.sectionDescriptionMap['Mycotoxins'] = 'Screening for mycotoxins utilizing liquid chromatography tandem mass spectrometry (LC-MS/MS).';
            vm.sectionDescriptionMap['Terpenoids'] = 'Identification and quantification of terpenoids utilizing gas chromatography mass spectrometry (GC/MS).';
            vm.sectionDescriptionMap['Cannabis'] = 'Full spectrum cannabinoid profiling and analysis utilizing High Performance Liquid Chromatography (HPLC/UV).';
        }

        function setSectionsInfoData() {
            var lod = '';
            var lod_same_for_all = false;
            var loq = '';
            var loq_same_for_all = false;
            var method = '';
            var method_same_for_all = false;
            var units = '';
            var units_same_for_all = false;
            var limit = '';
            var limit_same_for_all = false;
            var show_status = false;

            for(var i = 0; i < vm.sections.length; i++) {
                lod = '';
                lod_same_for_all = false;
                loq = '';
                loq_same_for_all = false;
                method = '';
                method_same_for_all = false;
                units = '';
                units_same_for_all = false;
                limit = '';
                limit_same_for_all = false;
                show_status = false;

                if(vm.sections[i].section_data) {
                    if(vm.sections[i].section_data.length > 2) {
                        vm.sections[i].section_data.sort( function(a, b) {
                            if(a.value && b.value) {
                                if(typeof(a.value) == 'number' && typeof(b.value) == 'string') {
                                    return -1
                                  }
                                else {
                                    return 0
                                }
                            }
                            else {
                                return 0
                            }
                        });
                    }
                }

                for(var j = 0; j < vm.sections[i].section_data.length; j++) {
                    if(!show_status) {
                        if(vm.sections[i].section_data[j].status) {
                            show_status = true;
                        }
                    }
                    if(!lod) {
                        if(vm.sections[i].section_data[j].lod) {
                            lod = vm.sections[i].section_data[j].lod;
                            lod_same_for_all = true;
                        }
                    }
                    else {
                        if(vm.sections[i].section_data[j].lod) {
                            if(lod != vm.sections[i].section_data[j].lod) {
                                lod_same_for_all = false;
                            }
                        }
                        else {
                            lod_same_for_all = false;
                        }
                    }
                    if(!loq) {
                        if(vm.sections[i].section_data[j].loq) {
                            loq = vm.sections[i].section_data[j].loq;
                            loq_same_for_all = true;
                        }
                    }
                    else {
                        if(vm.sections[i].section_data[j].loq) {
                            if(loq != vm.sections[i].section_data[j].loq) {
                                loq_same_for_all = false;
                            }
                        }
                        else {
                            loq_same_for_all = false;
                        }
                    }
                    if(!method) {
                        if(vm.sections[i].section_data[j].method) {
                            method = vm.sections[i].section_data[j].method;
                            method_same_for_all = true;
                        }
                    }
                    else {
                        if(vm.sections[i].section_data[j].method) {
                            if(method != vm.sections[i].section_data[j].method) {
                                method_same_for_all = false;
                            }
                        }
                        else {
                            method_same_for_all = false;
                        }
                    }
                    if(!units) {
                        if(vm.sections[i].section_data[j].units) {
                            units = vm.sections[i].section_data[j].units;
                            units_same_for_all = true;
                        }
                    }
                    else {
                        if(vm.sections[i].section_data[j].units) {
                            if(units != vm.sections[i].section_data[j].units) {
                                units_same_for_all = false;
                            }
                        }
                        else {
                            units_same_for_all = false;
                        }
                    }
                    if(!limit) {
                        if(vm.sections[i].section_data[j].limit) {
                            limit = vm.sections[i].section_data[j].limit;
                            limit_same_for_all = true;
                        }
                    }
                    else {
                        if(vm.sections[i].section_data[j].limit) {
                            if(limit != vm.sections[i].section_data[j].limit) {
                                limit_same_for_all = false;
                            }
                        }
                        else {
                            limit_same_for_all = false;
                        }
                    }
                }

                vm.sections[i].lod = lod;
                vm.sections[i].lod_same_for_all = lod_same_for_all;
                vm.sections[i].loq = loq;
                vm.sections[i].loq_same_for_all = loq_same_for_all;
                vm.sections[i].method_same_for_all = method_same_for_all;
                vm.sections[i].units = units;
                vm.sections[i].units_same_for_all = units_same_for_all;
                vm.sections[i].limit = limit;
                vm.sections[i].limit_same_for_all = limit_same_for_all;
                vm.sections[i].show_status = show_status;

                if(method.includes(',')) {
                    vm.sections[i].method = method.split(',')
                }
                else {
                    vm.sections[i].method = [method];
                }
            }
        }

        function parseInstrument(instrument) {
            var matching_string = '';
            if(instrument) {
                if(instrument.length > 10) {
                    matching_string = instrument.match(/\(([^)]+)\)/);
                    if(matching_string) {
                        return matching_string[1];
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return instrument;
                }
            }
            else {
                return '';
            }
        }

        function isNdValue(value) {
            let nd_values = ['nd', 'ND', 'nt', 'nt', '0', '0.0', 'null', null, 0, 0.0];
            if(nd_values.includes(value)) return true;

            return false;
        }

        function processSample() {
            var pf_dict = {}
            var units = null;
            var category = null;
            var instrument = null;
            var complete_date = null;
            var estimated_complete_date = null;
            var table_name = null;
            var table_id = null;
            var li = null;
            var done = "<li id=\"done\"></li>";
            var section_index = null;

            vm.sample_can_see_report = data.canSeeReport;
            try {
                if(data.order.order_type.toLowerCase().includes('compliance')) {
                    vm.isComplianceOrder = true;
                }
                else {
                    vm.isComplianceOrder = false;
                }
            }
            catch(e) {
                console.log('No order type for this sample.');
                vm.isComplianceOrder = false;
            }

            data.sampleTests.forEach( function(test) {
                var show_result = false

                if (vm.isComplianceOrder) {
                    var isOrderComplete = data.order && data.order.state === 'COMPLETED';
                    if (isOrderComplete && data.canSeeReport) {
                        vm.sample_can_see_report = true;
                    }

                    show_result = test.canSeeResults && vm.sample_can_see_report
                }
                
                else if(test.canSeeResults || vm.sample_can_see_report) {
                    show_result = true;
                }

                if(test.assay.worksheet_id && test.worksheetData) {
                    pf_dict = {
                        canSeeResults: test.canSeeResults ?  test.canSeeResults : null,
                        caution: test.caution ? test.caution : null,
                        orderState: test.orderState ? test.orderState : null,
                        statusPf: test.statusPf ? test.statusPf : null,
                        state: test.state ? test.state : null,
                        show_result: show_result
                    }

                    category = test.assay.category ? test.assay.category : test.assay.title
                    units = test.assay.units ? test.assay.units : null;
                    instrument = test.assay.instrument ? parseInstrument(test.assay.instrument) : null;
                    complete_date = test.completeDate ? new Date(test.completeDate) : null;
                    estimated_complete_date = test.estimatedCompleteDate ? new Date(test.estimatedCompleteDate) : null;
                    parseWorksheet(test.assay.worksheet_id, test.worksheetData, category, determineTestPf(pf_dict), units, show_result, instrument, complete_date, estimated_complete_date, test.assay);
                }
                else {
                    if(!test.assay.worksheet_id) {
                        console.log('No worksheet id: ', test.assay.title);
                    }
                    else if(!test.worksheetData) {
                        parseEmptyWorksheet(test);
                    }
                }
            });

            moveOtherAnalyses();
            moveTerpenesAndCannabinoids();

            vm.sections.forEach( function(section, index) {
                section.overall_pf = determineCategoryPf(section.pf_values);
                section.overall_show_results = determineCategoryShowResults(section.show_results);

                if(section.title == 'Pesticides') {
                    var failed = 0;
                    if(section.section_data) {
                        section.section_data.forEach( function(analyte) {
                            if(!isNdValue(analyte.value)) {
                                failed++;
                            }
                        });
                    }
                    section.pesticides_detected = failed;
                }

                if(vm.sectionDescriptionMap[section.title]) {
                    section.description = vm.sectionDescriptionMap[section.title];
                }
                else {
                    section.description = 'Analysis for ' + section.title.toLowerCase() + '.';
                }

                if(section.section_data.length > vm.viewMoreLength) {
                    section.view_more = false;
                }

                if(section.instrument) {
                    for(var i = section.instrument.length; i > 0; i--) {
                        if(!section.instrument[i]) {
                           section.instrument.splice(i, 1);
                        }
                    }
                }

                if(section.complete_date) {
                    var latest_date = null;

                    section.complete_date.forEach( function(date) {
                        if(date > latest_date) {
                            latest_date = date;
                        }
                    });
                    section.complete_date = latest_date;
                }

                if(section.estimated_complete_date) {
                    var latest_date = null;

                    section.estimated_complete_date.forEach( function(date) {
                        if(date > latest_date) {
                            latest_date = date;
                        }
                    });
                    section.estimated_complete_date = latest_date;
                }

                section.table_name = section.title.replace(/\s+\(|\)\s|\s|\(|\)|&/g, "-") + '-table';
                // table_name = '#' + section.title.replace(/\s+\(|\)\s|\s|\(|\)/g, "-") + '-table';
                table_name = '#' + section.title.replace(/\s+\(|\)\s|\s|\(|\)|&/g, "-") + '-table';
                table_id = '1' + section.title.replace(/\s+\(|\)\s|\s|\(|\)|&/g, "-") + '-table';
                li = "<li id=\"" + table_id +  "\">" + table_name + "</li>";

                $('#list-of-sections').append(li);
            });

            if(vm.sections[0]) vm.sections[0].active = true;

            setSectionsInfoData();

            if(vm.pesticidesMycotoxins.tested) {
                section_index = findWithAttr(vm.sections, 'title', 'Pesticides');
                if(section_index > -1) {
                    vm.sections[section_index].units = vm.pesticidesMycotoxins.pesticides_units;
                    vm.sections[section_index].units_same_for_all = true;

                    vm.sections[section_index].loq_same_for_all = true;
                    vm.sections[section_index].loq = vm.pesticidesMycotoxins.loq_pesticides;

                    vm.sections[section_index].limit_same_for_all = false;
                }

                section_index = findWithAttr(vm.sections, 'title', 'Mycotoxins');
                if(section_index > -1) {
                    vm.sections[section_index].units = vm.pesticidesMycotoxins.mycotoxins_units;
                    vm.sections[section_index].units_same_for_all = true;

                    vm.sections[section_index].loq_same_for_all = true;
                    vm.sections[section_index].loq = vm.pesticidesMycotoxins.loq_mycotoxins;

                    vm.sections[section_index].limit_same_for_all = false;
                }
            }

            $('#list-of-sections').append(done);
            google.charts.setOnLoadCallback(function () {
                vm.googleChartsLoaded = true;

                if(vm.cannabinoidsTested) {
                    drawCannabinoidChart('first_load');
                }
                if(vm.terpenesTested) {
                    drawTerpenesChart('first_load');
                }
            });

            setResultsWidth();
        }

        function setResultsWidth() {
            var main_container_width = $('.ps-container').width()
            var ps_sample_width = $('.ps-sample').width()
            var ps_results_width = main_container_width - ps_sample_width - 16;
            $('.ps-results').width(ps_results_width);

            if(vm.cannabinoidsTested) {
                drawCannabinoidChart('resize');
            }
            if(vm.terpenesTested) {
                try {
                    drawTerpenesChart('resize');
                }
                catch(e) {
                    console.log('Could not render terpenes graph');
                }
            }
        }

        vm.makeActive = function(title) {
            var section_index = findWithAttr(vm.sections, 'title', title);

            vm.sections.forEach( function(section, index) {
                section.active = false;
            });

            vm.sections[section_index].active = true;
        }

        function moveOtherAnalyses() {
            for(var i = 0; i < vm.sections.length; i++) {
                if(vm.sections[i].title == 'Other Analyses') {
                    vm.sections.push(vm.sections.splice(i, 1)[0]);
                    break;
                }
            }
        }

        function moveTerpenesAndCannabinoids() {
            var front = [];
            var back = [];
            var cannabis_section_index = findWithAttr(vm.sections, 'title', 'Cannabis');
            var terpenes_section_index = findWithAttr(vm.sections, 'title', 'Terpenoids');

            if(cannabis_section_index != -1) {
                front.push(vm.sections[cannabis_section_index]);
            }
            if(terpenes_section_index != -1) {
                front.push(vm.sections[terpenes_section_index]);
            }

            if(front) {
                for(var i = 0; i < vm.sections.length; i++) {
                    if(vm.sections[i].title != 'Terpenoids' &&  vm.sections[i].title != 'Cannabis') {
                        back.push(vm.sections[i]);
                    }
                }

            }

            if(front && back) {
                vm.sections = front.concat(back);
            }
            else if(front) {
                vm.sections = front;
            }
        }

        function isDynamicAnalyte(analyte) {
            var is_dynamic = true;
            var suffixes = ['_comments', '_finding', '_method', '_method_override', '_units', '_instrument', '_limit', '_lod', '_loq', '_status', '_pf'];

            suffixes.forEach( function(suffix) {
                if(analyte.includes(suffix)) {
                    is_dynamic = false;
                }
            });

            return is_dynamic;
        }

        vm.changeCannabinoidTotals = function() {
            var active_unit = vm.sections[0].active_unit;

            if(active_unit == 'dry_mgpg') {
                vm.sections[0].total_thc = vm.sections[0].dry_mgpg_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].dry_mgpg_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].dry_mgpg_total;
                vm.sections[0].active_unit_frontend = ' mg/g (dry)';
            }
            else if(active_unit == 'mgpg') {
                vm.sections[0].total_thc = vm.sections[0].mgpg_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].mgpg_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].mgpg_total;
                vm.sections[0].active_unit_frontend = ' mg/g';
            }
            else if (active_unit == 'dry_percent') {
                vm.sections[0].total_thc = vm.sections[0].dry_percent_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].dry_percent_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].dry_percent_total;
                vm.sections[0].active_unit_frontend = '% (dry)';
            }
            else if (active_unit == 'mgpml') {
                vm.sections[0].total_thc = vm.sections[0].mgpml_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].mgpml_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].mgpml_total;
                vm.sections[0].active_unit_frontend = ' mg/ml';
            }
            else if (active_unit == 'mgpp') {
                vm.sections[0].total_thc = vm.sections[0].mgpp_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].mgpp_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].mgpp_total;
                vm.sections[0].active_unit_frontend = ' mg/pkg';
            }
            else if (active_unit == 'mgpu') {
                vm.sections[0].total_thc = vm.sections[0].mgpu_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].mgpu_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].mgpu_total;
                vm.sections[0].active_unit_frontend = ' mg/serving';
            }
            else if (active_unit == 'percent') {
                vm.sections[0].total_thc = vm.sections[0].percent_total_thc;
                vm.sections[0].total_cbd = vm.sections[0].percent_total_cbd;
                vm.sections[0].total_cannabinoids = vm.sections[0].percent_total;
                vm.sections[0].active_unit_frontend = '%';
            }
            drawCannabinoidChart('resize');
        }

        vm.cannabinoidAnalyteNameMap = {
            s8thc_: 'δ8 THC',
            s9thc_: 'δ9 THC',
            thca_: 'THCA',
            thcv_: 'THCV',
            thcva_: 'THCVA',
            cbd_: 'CBD',
            cbda_: 'CBDA',
            cbdv_: 'CBDV',
            cbn_: 'CBN',
            cbg_: 'CBG',
            cbc_: 'CBC',
            cbga_: 'CBGA',
            cbca_: 'CBCA',
            cbdva_: 'CBDVA',
            cbl_: 'CBL',
            cbt_: 'CBT',
            s8_thcv_: 'δ8 THCV',
            _9r_hexahydrocannabinol_: '9(R)-HHC',            
            _9s_hexahydrocannabinol_: '9(S)-HHC',
            _9r_hhc_acetate_:'9(R)-HHC Acetate',
            _9s_hhc_acetate_:'9(S)-HHC Acetate',
            _1r_thd_: '1(R)-THD',
            _1s_thd_: '1(S)-THD',
            d9_thcb_: 'δ9 THCB',
            d9_thch_: 'δ9 THCH',
            _9r_hhcp_: '9(R)-HHCP',
            _9s_hhcp_: '9(S)-HHCP',
            _9r_hhch_: '9(R)-HHCH',
            _9s_hhch_: '9(S)-HHCH',
            d8_thco_ : 'δ8-THCO',
            _9r_hhco_: '9(R)-HHCO',
            _9s_hhco_: '9(S)-HHCO'
        }

        vm.cannabinoidUnitMap = {
            dry_percent: '% (dry)',
            percent: '%',
            dry_mgpg: 'mg/g',
            mgpg: 'mg/g',
            mgpml: 'mg/ml',
            mgpu: 'mg/serving',
            mgpp: 'mg/pkg'
        }

        vm.cannabinoidColorMap = {
            s8thc_: '#33699a',
            s9thc_: '#4f91cd',
            thca_: '#5db2ff',
            thcv_: '#93ccff',
            thcva_: '#f5b54e',
            cbd_: '#ffd35e',
            cbda_: '#f7cb56',
            cbdv_: '#d4602a',
            cbn_: '#e88050',
            cbg_: '#e99f7c',
            cbc_: '#fab596',
            cbga_: '#33699a',
            cbca_: '#4f91cd',
            s8_thcv_: '#008c47',
            cbca_: '#006b2a',
            cbdva_: '#008844',
            cbl_: '#69f2a4',
            cbt_: '#00a272',
            s8_thcv_: '#d42000',
            _9r_hexahydrocannabinol_: '#8a2a00',            
            _9s_hexahydrocannabinol_: '#cb1515',
            _9r_hhc_acetate_:'#f25555',
            _9s_hhc_acetate_:'#e33b3b',
            _1r_thd_: '#d5231c',
            _1s_thd_: '#bd3600',
            d9_thcb_: '#007a00',
            d9_thch_: '#ab0707',
            _9r_hhcp_: '#cc3623',
            _9s_hhcp_: '#a3160b',
            _9r_hhch_: '#22853e',
            _9s_hhch_: '#2dc257',
            d8_thco_ : '#7ca387',
            _9r_hhco_: '#2d7040',
            _9s_hhco_: '#7de39a'
        }

        vm.cannabinoidsUserToUnitMap = {
            dry_percent: '% (dry)',
            percent:  '%',
            dry_mgpg:' mg/g (dry)',
            mgpg: ' mg/g',
            mgpml: 'mg/ml',
            mgpu: 'mg/serving',
            mgpp: 'mg/pkg'
        }

        function drawCannabinoidChart(method) {
            var data = new google.visualization.DataTable();
            var counter = 0;

            do {
                data = new google.visualization.DataTable();
                counter++;
            } while(!data && counter < 100);

            data.addColumn('string', 'Status');
            data.addColumn('number', 'Count');

            // var colors = [
            //     '#33699a',
            //     '#4f91cd',
            //     '#5db2ff',
            //     '#93ccff',
            //     '#9ab567',
            //     '#f5b54e',
            //     '#ffd35e',
            //     '#f7cb56',
            //     '#d4602a',
            //     '#e88050',
            //     '#e99f7c',
            //     '#fab596',
            // ];
            var colors = vm.cannabinoidChartColorArray

            vm.sections[0].section_data[vm.sections[0].active_unit].forEach( function(analyte) {
                data.addRow([
                    analyte.name,
                    parseFloat(analyte.value)
                ]);
            });

            var options = {
                backgroundColor: 'transparent',
                pieHole: 0.7,
                pieSliceText: 'value',
                pieSliceTextStyle: {
                    color: 'black',
                    fontSize: 1,
                    opacity: 0, // hides text on chart
                    visibility: 'hidden'  // hides text on chart
                },
                tooltip: {
                    text: 'value'
                },
                chartArea: {
                    // top: auto,
                    // right: ,
                    width: '100%',
                    height: '100%'
                },
                height: '100%',
                width: '100%',
                legend: 'none',
                colors: colors
            };

            var formatter = new google.visualization.NumberFormat({
                fractionDigits: 2,
                suffix: vm.sections[0].active_unit_frontend
            });

            formatter.format(data, 1);
			if(data.getNumberOfRows() != 0) {
                if(vm.showWideView) {
                    var chart = null;
                    if(method == 'first_load') {
                        while($('#new-pie-chart').children().length == 0) {
                            chart = new google.visualization.PieChart(angular.element('#new-pie-chart')[0]);
                            chart.draw(data, options);
                        }
                    }
                    else {
                        chart = new google.visualization.PieChart(angular.element('#new-pie-chart')[0]);
                        chart.draw(data, options);
                    }
                }
                else if(vm.showTallView) {
                    var mobileChart = null;

                    if(method == 'first_load') {
                        while($('#mobile-view-cannabis-chart').children().length == 0) {
                            mobileChart = new google.visualization.PieChart(angular.element('#mobile-view-cannabis-chart')[0]);
                            mobileChart.draw(data, options);
                        }
                    }
                    else {
                        mobileChart = new google.visualization.PieChart(angular.element('#mobile-view-cannabis-chart')[0]);
                        mobileChart.draw(data, options);
                    }
                }
			}
        }

        function createTerpenesMap() {
            vm.terpenesMap =  {};
            //GREEN
            vm.terpenesMap["terpinolene"] = {
                slice: 'green',
                color: '#669A8B',
                position: 1
            };
            vm.terpenesMap["eudesmol"] = {
                slice: 'green',
                color: '#669A8B',
                position: 2
            };
            vm.terpenesMap["α-pinene"] = {
                slice: 'green',
                color: '#669A8B',
                position: 3
            };
            vm.terpenesMap["β-pinene"] = {
                slice: 'green',
                color: '#669A8B',
                position: 4
            };
            vm.terpenesMap["camphene"] = {
                slice: 'green',
                color: '#669A8B',
                position: 5
            };

            // BLUE //
            vm.terpenesMap["eucalyptol"] = {
                slice: 'blue',
                color: '#00BFC6',
                position: 6
            };
            vm.terpenesMap["isopulegol"] = {
                slice: 'blue',
                color: '#00BFC6',
                position: 7
            };
            vm.terpenesMap["menthol"] = {
                slice: 'blue',
                color: '#00BFC6',
                position: 8
            };
            vm.terpenesMap["borneol"] = {
                slice: 'blue',
                color: '#00BFC6',
                position: 9
            };

            // PURPLE //
            vm.terpenesMap["trans-beta-ocimene"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 10
            };
            vm.terpenesMap["cis-beta-ocimene"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 11
            };
            vm.terpenesMap["geraniol"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 12
            };
            vm.terpenesMap["linalool"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 13
            };
            vm.terpenesMap["guaiol"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 14
            };
            vm.terpenesMap["α-bisabolol"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 15
            };
            vm.terpenesMap["α-terpineol"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 16
            };
            vm.terpenesMap["citronellol"] = {
                slice: 'purple',
                color: '#F6DBB9',
                position: 17
            };

            // YELLOW //
            vm.terpenesMap["δ-3-carene"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 18
            };
            vm.terpenesMap["cis-nerolidol"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 19
            };
            vm.terpenesMap["trans-nerolidol"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 20
            };
            vm.terpenesMap["limonene"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 21
            };
            vm.terpenesMap["p-cymene"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 22
            };
            vm.terpenesMap["γ-terpinene"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 23
            };
            vm.terpenesMap["α-terpinene"] = {
                slice: 'yellow',
                color: '#F5B54E',
                position: 24
            };

            // RED //
            vm.terpenesMap["ß-myrcene"] = {
                slice: 'red',
                color: '#FF6A59',
                position: 25
            };
            vm.terpenesMap["caryophylleneoxide"] = {
                slice: 'red',
                color: '#FF6A59',
                position: 26
            };
            vm.terpenesMap["α-humulene"] = {
                slice: 'red',
                color: '#FF6A59',
                position: 27
            };
            vm.terpenesMap["ß-caryophyllene"] = {
                slice: 'red',
                color: '#FF6A59',
                position: 28
            };
        }

        function drawTerpenesChart(method) {
            var section_index = findWithAttr(vm.sections, 'title', 'Terpenoids');
            var analyte_index = null;

            angular.forEach(vm.terpenesMap, function(value, key) {
                analyte_index = findWithAttr(vm.sections[section_index].section_data, 'name', key.toLowerCase());

                if(vm.sections[section_index].section_data[analyte_index].value_exists) {
                    vm.sections[section_index].chart_values[value.slice] = vm.sections[section_index].chart_values[value.slice] + vm.sections[section_index].section_data[analyte_index].value;
                }
            });

            var piney_data = vm.sections[section_index].chart_values.green ? +vm.sections[section_index].chart_values.green.toFixed(2) : 0;
            var minty_data = vm.sections[section_index].chart_values.blue ? +vm.sections[section_index].chart_values.blue.toFixed(2) : 0;
            var sweet_data = vm.sections[section_index].chart_values.purple ? +vm.sections[section_index].chart_values.purple.toFixed(2) : 0;
            var citrus_data = vm.sections[section_index].chart_values.yellow ? +vm.sections[section_index].chart_values.yellow.toFixed(2) : 0;
            var spicy_data = vm.sections[section_index].chart_values.red ? +vm.sections[section_index].chart_values.red.toFixed(2) : 0;

            var data = google.visualization.arrayToDataTable([
                ['Terpenes', 'Percentage'],
                ['Piney', piney_data],
                ['Minty', minty_data],
                ['Sweet', sweet_data],
                ['Citrus', citrus_data],
                ['Spicy', spicy_data]
              ]);

            var colors = [
                '#669A8B',
                '#00BFC6',
                '#F6DBB9',
                '#F5B54E',
                '#FF6A59',
            ]

            var options = {
                backgroundColor: 'transparent',
                pieSliceText: 'none',
                chartArea: {
                    width: '100%',
                    height: '100%'
                },
                height: '100%',
                width: '100%',
                legend: 'none',
                colors: colors
            };

            var formatter = new google.visualization.NumberFormat({
                fractionDigits: 2,
                suffix: '%'
            });

            formatter.format(data, 1);

			if(data.getNumberOfRows() != 0) {
                if(vm.showWideView) {
                    var chart = null;

                    if(method == 'first_load') {
                        while($('#new-terpenes-chart').children().length == 0) {
                            chart = new google.visualization.PieChart(angular.element('#new-terpenes-chart')[0]);
                            chart.draw(data, options);
                        }
                    }
                    else {
                        chart = new google.visualization.PieChart(angular.element('#new-terpenes-chart')[0]);
                        chart.draw(data, options);
                    }
                }
                else if(vm.showTallView) {
                    var mobileChart = null;

                    if(method == 'first_load') {
                        while($('#mobile-view-terpenes-chart').children().length == 0) {
                            mobileChart = new google.visualization.PieChart(angular.element('#mobile-view-terpenes-chart')[0]);
                            mobileChart.draw(data, options);
                        }
                    }
                    else {
                        mobileChart = new google.visualization.PieChart(angular.element('#mobile-view-terpenes-chart')[0]);
                        mobileChart.draw(data, options);
                    }
                }
            }
        }

        function alkaloidNameMap(key) {
            var to_return = '';

            if(key.includes('seven_hydroxymitragynine')) {
                to_return  = "7-Hydroxymitragynine";
            }
            else if(key.includes('mitragynine')) {
                to_return  = "Mitragynine";
            }
            else if(key.includes('total_alkaloids')) {
                to_return  = "Total Alkaloids";
            }

            return to_return;
        }

        function generateParsers() {
            parsers.user_configurable = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                if(ws) {
                    test_pf = ws.overall_pf.value;
                }
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                angular.forEach(ws, function(value, key) {
                    if(value.display_name && value.value) {
                        vm.sections[section_index].section_data.push({
                            name: value.display_name ? value.display_name : '',
                            value: value.calculated_value ? value.calculated_value : '',
                            units: determineUnits(null, units),
                            value_exists: unitsRequired(value.twoDecimalFormattedValue),
                            limit: value.limit ? value.limit : '',
                            lod: value.lod ? value.lod : '',
                            loq: value.loq ? value.loq : '',
                            method:  value.method ? value.method : '',
                            status: value.status ? value.status : '',
                        });
                    }
                });
            }
            parsers.dynamic = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);
                var to_push = {};

                angular.forEach(ws, function(value, key) {
                    if(isDynamicAnalyte(key)) {
                        to_push = {
                            name: value.value ? value.value : '',
                            value: parseValueObject(ws[key + '_finding']),
                            units: ws[key + '_units'] ? ws[key + '_units'].value : '',
                            value_exists: ws[key + '_finding'] ? unitsRequired(ws[key + '_finding'].value) : '',
                            lod: ws[key + '_lod'] ? ws[key + '_lod'].value : '',
                            loq: ws[key + '_loq'] ? ws[key + '_loq'].value : '',
                            method: ws[key + '_method'] ? ws[key + '_method'].value : '',
                            limit: ws[key + '_limit'] ? ws[key + '_limit'].value : '',
                        }

                        if(section_index == -1 && to_push.value_exists) {
                            vm.sections.push({
                                title : category,
                                description : null,
                                pf_values: [test_pf],
                                overall_pf: null,
                                section_data : [to_push],
                                expanded : false,
                                acive: false,
                                info_box_open: false,
                                instrument: [instrument],
                                complete_date: [complete_date],
                                estimated_complete_date: [estimated_complete_date]
                            });
                        }
                        else if(to_push.value_exists) {
                            vm.sections[section_index].pf_values.push(test_pf);
                            vm.sections[section_index].section_data.push(to_push);
                        }
                    }
                });
            }
            parsers.heavy_metals = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                angular.forEach(ws, function(value, key) {
                    if(value.display_name && value.value) {
                        vm.sections[section_index].section_data.push({
                            name: value.display_name ? value.display_name : '',
                            value: parseValueObject(value),
                            units: determineUnits(value.units, units),
                            value_exists: unitsRequired(value.twoDecimalFormattedValue),
                            limit: ws[key + '_limit'] ? ws[key + '_limit'].value : '',
                            lod: ws[key + '_lod'] ? ws[key + '_lod'].value : '',
                            loq: ws[key + '_loq'] ? ws[key + '_loq'].value : '',
                            method:  ws[key + '_method'] ? ws[key + '_method'].value : '',
                            status: ws[key + '_pf'] ? ws[key + '_pf'].value : ''
                        });
                    }
                });
            }
            parsers.consulting = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                for(var i = 1; i < 21; i++) {
                    if(ws['analyte_' + i].value) {
                        vm.sections[section_index].section_data.push({
                            name: ws['analyte_' + i].value ? ws['analyte_' + i].value : '',
                            value: ws['findings_' + i].twoDecimalFormattedValue ? parseValue(ws['findings_' + i].twoDecimalFormattedValue) : '',
                            units: unitsRequired(ws['units_' + i].value) ? determineUnits(ws['units_' + i].value, units)  : '',
                            value_exists: unitsRequired(ws['units_' + i].value),
                            limit: ws['limit_' + i].value ? ws['limit_' + i].value : '',
                            lod: ws['lod_' + i].value ? ws['lod_1' + i].value : '',
                            loq: ws['loq_' + i].value ? ws['loq_' + i].value : '',
                            method: ws['method_' + i].value ? ws['method_' + i].value : '',
                        });
                    }
                }
            }
            parsers.generic_food = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                if(ws.findings) {
                    vm.sections[section_index].section_data.push({
                        name: ws.analyte.value,
                        value: ws.findings.twoDecimalFormattedValue ? parseValue(ws.findings.twoDecimalFormattedValue) : '',
                        units: unitsRequired(ws.findings.twoDecimalFormattedValue) ? determineUnits(ws.units.value, units) : '',
                        value_exists: unitsRequired(ws.findings.twoDecimalFormattedValue),
                        limit: '',
                        lod: ws['lod'].value ? ws['lod'].value : '',
                        loq: ws['loq'].value ? ws['loq'].value : '',
                        method: '',
                        status: ws['status'] ? ws['status'].value : ''
                    });
                }
            }
            parsers.vitamin_e = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                if(ws.findings) {
                    vm.sections[section_index].section_data.push({
                        name: 'Vitamin E Acetate',
                        value: ws.findings.twoDecimalFormattedValue ? parseValue(ws.findings.twoDecimalFormattedValue) : '',
                        units: units ? units : 'ppm',
                        value_exists: unitsRequired(ws.findings.twoDecimalFormattedValue)
                    });
                }
            }
            parsers.terpenes = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                createTerpenesMap();
                var section_index = findWithAttr(vm.sections, 'title', category);

                if(section_index == -1) {
                    vm.sections.push({
                        title : category,
                        description : null,
                        overall_pf : null,
                        section_data : [],
                        pf_values: [test_pf],
                        show_results: [show_result],
                        instrument: [instrument],
                        complete_date: [complete_date],
                        estimated_complete_date: [estimated_complete_date],
                        expanded : false,
                        active: false,
                        info_box_open: false,
                        chart_values : {
                            blue: 0,
                            green: 0,
                            yellow: 0,
                            purple: 0,
                            red: 0,
                        }
                    });
                    section_index = vm.sections.length - 1;
                }
                else {
                    vm.sections[section_index].pf_values.push(test_pf);
                }


                var analytes = []
                angular.forEach(ws, function(value, key) {
                    if(value.display_name && value.default_value) {
                        analytes.push({
                            key : key,
                            display_name: value.display_name
                        });
                    }
                });
                analytes.forEach( function(analyte) {
                    if(ws[analyte.key]) {
                        try {
                            vm.sections[section_index].section_data.push({
                                name: analyte.display_name ? analyte.display_name : '',
                                value: parseValue(ws[analyte.key].value),
                                units: unitsRequired(ws[analyte.key].value) ? '%' : '',
                                value_exists: unitsRequired(ws[analyte.key].value),
                                color: analyte.display_name ? { "background-color": vm.terpenesMap[analyte.display_name.toLowerCase()].color } :  { "background-color": '#3a516c' },
                                position: analyte.display_name ? vm.terpenesMap[analyte.display_name.toLowerCase()].position : null
                            });
                        }
                        catch(err) {
                            console.error("Error trying to parse terpenes: " + err);
                            console.error(analyte)
                        }
                    }
                });

                if(ws['total_terpenes']) {
                    vm.sections[section_index].total_terpenes = ws['total_terpenes'].twoDecimalFormattedValue
                }

                vm.sections[section_index].section_data.sort( function(a, b) {
                        return a.position - b.position;
                });

                vm.terpenesTested = true;
            }
            parsers.usp_61_62 = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                microParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.moisture = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findWithAttr(vm.sections, 'title', category);

                if(section_index == -1) {
                    vm.sections.push({
                        title : category,
                        description : null,
                        overall_pf : null,
                        section_data : [],
                        pf_values: [test_pf],
                        show_results: [show_result],
                        expanded : false,
                        active: false,
                        info_box_open: false,
                        instrument: [instrument],
                        complete_date: [complete_date],
                        estimated_complete_date: [estimated_complete_date]
                    });
                    section_index = vm.sections.length - 1;
                }
                else {
                    vm.sections[section_index].pf_values.push(test_pf);
                }

                if(ws.moisture) {
                    if(ws.moisture.value) {
                        vm.sections[section_index].section_data.push({
                            name: ws.moisture.display_name ? ws.moisture.display_name : '',
                            value: ws.moisture.value ? parseValue(ws.moisture.value) : '',
                            units: units ? units : '',
                            value_exists: unitsRequired(ws.moisture.value)
                        });
                    }
                }

                vm.moistureTested = true;
            }
            parsers.mycotoxins = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.microbiology = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.foreign_materials = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.yeast_mold = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                microParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.residual_solvents = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.pesticides = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                angular.forEach(ws, function(value, key) {
                    if(key.includes('_asterisk')) {
                       delete ws[key];
                    }
                });

                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
            parsers.pesticides_mycotoxins = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                vm.pesticidesMycotoxins.tested = true;
                var mycotoxins_ws = {
                    aflatoxin_b1: ws['aflatoxin_b1'] ? ws['aflatoxin_b1'] : '',
                    aflatoxin_b2: ws['aflatoxin_b2'] ? ws['aflatoxin_b2'] : '',
                    aflatoxin_g1: ws['aflatoxin_g1'] ? ws['aflatoxin_g1'] : '',
                    aflatoxin_g2: ws['aflatoxin_g2'] ? ws['aflatoxin_g2'] : '',
                    ochratoxin_a: ws['ochratoxin_a'] ? ws['ochratoxin_a'] : '',
                    ochratoxin_a_limit: {value: vm.pesticidesMycotoxins.ochratoxin_a_limit},
                    ochratoxin_a_pf: ws['ochratoxin_a_pf'] ? ws['ochratoxin_a_pf'] : '',
                    total_aflatoxins: ws['total_aflatoxins'] ? ws['total_aflatoxins'] : '',
                    total_aflatoxins_limit: {value: vm.pesticidesMycotoxins.total_aflatoxins_limit},
                    total_aflatoxins_pf: ws['total_aflatoxins_pf'] ? ws['total_aflatoxins_pf'] : '',
                };

                var mycotoxins_pf = 'in_progress';
                if(ws['overall_pf'] && ws['overall_pf'].value && ws['overall_pf'].value.toLowerCase()) {
                    mycotoxins_pf = ws['overall_pf'].value.toLowerCase();
                }

                var pesticides_pf = 'in_progress';
                if(ws['overall_pf'] && ws['overall_pf_phase2'].value && ws['overall_pf_phase2'].value.toLowerCase()) {
                    pesticides_pf = ws['overall_pf_phase2'].value.toLowerCase();
                }

                if(mycotoxins_pf) delete ws['overall_pf'];
                if(ws['aflatoxin_b1']) delete ws['aflatoxin_b1'];
                if(ws['aflatoxin_b2']) delete ws['aflatoxin_b2'];
                if(ws['aflatoxin_g1']) delete ws['aflatoxin_g1'];
                if(ws['aflatoxin_g2']) delete ws['aflatoxin_g2'];
                if(ws['ochratoxin_a']) delete ws['ochratoxin_a'];
                if(ws['ochratoxin_a_pf']) delete ws['ochratoxin_a_pf'];
                if(ws['total_aflatoxins']) delete ws['total_aflatoxins'];
                if(ws['total_aflatoxins_pf']) delete ws['total_aflatoxins_pf'];

                angular.forEach(ws, function(value, key) {
                    if(key.includes('_asterisk')) {
                       delete ws[key];
                    }
                });

                vm.pesticidesMycotoxins.loq_mycotoxins = ws['loq_mycotoxins'] ? ws['loq_mycotoxins'].value : '';
                vm.pesticidesMycotoxins.loq_pesticides = ws['loq_pesticides'] ? ws['loq_pesticides'].value : '';

                generalParser(mycotoxins_ws, 'Mycotoxins', mycotoxins_pf, '', show_result, '', complete_date, estimated_complete_date, assay);
                generalParser(ws, 'Pesticides', pesticides_pf, '', show_result, '', complete_date, estimated_complete_date, assay);
            }
            parsers.alkaloid = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                var section_index = findSection(category, test_pf, show_result, instrument, complete_date, estimated_complete_date);

                var prefixes = ['mitragynine_', 'seven_hydroxymitragynine_', 'total_alkaloids_'];
                var suffixes = ['percent', 'mgml', 'mgpg', 'mgps']
                var to_push = {};

                prefixes.forEach( function(prefix) {
                    suffixes.forEach( function(suffix) {
                        to_push = {
                            name: ws[prefix + suffix] ? alkaloidNameMap(prefix) : '',
                            value: ws[prefix + suffix] ? parseValue(ws[prefix + suffix].value) : '',
                            units: ws[prefix + suffix] ? ws[prefix + suffix].units : '',
                            value_exists: ws[prefix + suffix] ? parseValue(ws[prefix + suffix].value) : '',
                            limit: ws['limit'] ? ws['limit'].value : '',
                            lod: ws['lod'] ? ws['lod'].value : '',
                            loq: ws['loq'] ? ws['loq'].value : '',
                            method:  ws['method'] ? ws['method'].value : '',
                            status: ws[prefix + suffix + '_pf'] ? ws[prefix + suffix + '_pf'].value : ''
                        }

                        if(to_push.value_exists) {
                            vm.sections[section_index].section_data.push(to_push);
                        }
                    })
                })

            }
            parsers.cannabinoid_profile = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                // Update the description in the sectionDescriptionMap if we have an assay description
                if (assay && assay.analysis_description) {
                    vm.sectionDescriptionMap['Cannabis'] = assay.analysis_description;
                }
                var section_index = 0;
                var analyte_value = '';
                var moisture_tested = '';
                var no_value = ['ND', 'nd', 'nt', 'NT', 'bloq', 'BLOQ', 0, 0.00, '0', '0.00'];
                var cannabinoid_section = {
                    title : category,
                    description : assay && assay.analysis_description ? assay.analysis_description : 'Full spectrum cannabinoid profiling and analysis utilizing High Performance Liquid Chromatography (HPLC/UV).',
                    overall_pf : null,
                    section_data : [],
                    pf_values: [test_pf],
                    show_results: [show_result],
                    instrument: [instrument],
                    complete_date: [complete_date],
                    estimated_complete_date: [estimated_complete_date],
                    expanded : false,
                    active: false,
                    info_box_open: false,
                    active_unit: null,
                    dry_percent_exists: false,
                    percent_exists: false,
                    dry_mgpg_exists: false,
                    mgpg_exists: false,
                    mgpml_exists: false,
                    mgpu_exists: false,
                    mgpp_exists: false,

                    dry_percent_total_thc: null,
                    percent_total_thc: null,
                    dry_mgpg_total_thc: null,
                    mgpg_total_thc: null,
                    mgpml_total_thc: null,
                    mgpu_total_thc: null,
                    mgpp_total_thc: null,

                    dry_percent_total_cbd: null,
                    percent_total_cbd: null,
                    dry_mgpg_total_cbd: null,
                    mgpg_total_cbd: null,
                    mgpml_total_cbd: null,
                    mgpu_total_cbd: null,
                    mgpp_total_cbd: null,

                    dry_percent_total: null,
                    percent_total: null,
                    dry_mgpg_total: null,
                    mgpg_total: null,
                    mgpml_total: null,
                    mgpu_total: null,
                    mgpp_total: null,

                    total_cannabinoids: null,
                }

                if(vm.sections) {
                    vm.sections.unshift(cannabinoid_section);
                }
                else {
                    vm.sections.push(cannabinoid_section)
                }

                var prefixes = [
                    's8thc_', 's9thc_', 'thca_', 'thcv_',
                    'thcva_', 'cbd_', 'cbda_', 'cbdv_',
                    'cbn_', 'cbg_', 'cbc_', 'cbga_', 'cbca_',
                    'cbdva_','cbl_','cbt_','s8_thcv_','_9r_hexahydrocannabinol_',
                    '_9s_hexahydrocannabinol_','_9r_hhc_acetate_','_9s_hhc_acetate_','_1r_thd_','_1s_thd_','d9_thcb_',
                    'd9_thch_','_9r_hhcp_','_9s_hhcp_','_9r_hhch_','_9s_hhch_',
                    'd8_thco_', 'd8_thco_', '_9r_hhco_', '_9s_hhco_'
                ];

                var suffixes = [
                    'dry_percent', 'percent', 'dry_mgpg',
                    'mgpml', 'mgpu', 'mgpp', 'mgpg'
                ]

                vm.cannabinoidChartColorArray = []
                suffixes.forEach( function(suffix) {
                    vm.sections[section_index].section_data[suffix] = [];
                    prefixes.forEach( function(prefix) {
                        analyte_value = 0.00;
                        if(ws[prefix + suffix]) {
                            if(ws[prefix + suffix].twoDecimalFormattedValue) {
                                analyte_value = parseValue(ws[prefix + suffix].twoDecimalFormattedValue);
                            }
                        }
                        vm.cannabinoidChartColorArray.push(vm.cannabinoidColorMap[prefix])
                        vm.sections[section_index].section_data[suffix].push({
                            name: vm.cannabinoidAnalyteNameMap[prefix],
                            value: analyte_value,
                            value_exists: no_value.includes(analyte_value) ? false : true,
                            units: vm.cannabinoidsUserToUnitMap[suffix],
                            color: { 'background-color' : vm.cannabinoidColorMap[prefix] }
                        });
                    });
                });

                if(ws) {
                    vm.sections[section_index].overall_pf = test_pf;

                    if(ws.moisture) {
                        if(ws.moisture.value) {
                            if (ws.moisture.value == '0' || isNaN(ws.moisture.value)){
                                moisture_tested = false;
                            }
                            else {
                                moisture_tested = true;
                            }
                        }
                    }

                    if(ws.total_mgpp) {
                        if(ws.total_mgpp.twoDecimalFormattedValue) {
                        vm.sections[section_index].mgpp_exists = true;
                        vm.sections[section_index].mgpp_total_thc = ws['total_thc_mgpp'] ? ws['total_thc_mgpp'].twoDecimalFormattedValue : '';
                        vm.sections[section_index].mgpp_total_cbd = ws['total_cbd_mgpp'] ? ws['total_cbd_mgpp'].twoDecimalFormattedValue : '';
                        vm.sections[section_index].mgpp_total = ws['total_mgpp'] ? ws['total_mgpp'].twoDecimalFormattedValue : '';

                        if(!vm.sections[section_index].active_unit) {
                            vm.sections[section_index].active_unit = 'mgpp';
                            vm.sections[section_index].active_unit_frontend = ' mg/pkg';
                        }

                        if(!vm.sections[section_index].total_thc) {
                            vm.sections[section_index].total_thc = vm.sections[section_index].mgpp_total_thc;
                        }

                        if(!vm.sections[section_index].total_cbd) {
                            vm.sections[section_index].total_cbd = vm.sections[section_index].mgpp_total_cbd;
                        }

                        if(!vm.sections[section_index].total_cannabinoids) {
                            vm.sections[section_index].total_cannabinoids = vm.sections[section_index].mgpp_total
                        }
                        }
                    }
                    if(ws.total_dry_percent && moisture_tested) {
                        if(ws.total_dry_percent.twoDecimalFormattedValue) {
                            vm.sections[section_index].dry_percent_exists = true;
                            vm.sections[section_index].dry_percent_total_thc = ws['total_thc_dry_percent'] ? ws['total_thc_dry_percent'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].dry_percent_total_cbd =ws['total_cbd_dry_percent'] ? ws['total_cbd_dry_percent'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].dry_percent_total = ws['total_dry_percent'] ? ws['total_dry_percent'].twoDecimalFormattedValue : '';


                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'dry_percent';
                                vm.sections[section_index].active_unit_frontend = '% (dry)';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].dry_percent_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].dry_percent_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].dry_percent_total;
                            }
                        }
                    }
                    if(ws.total_dry_mgpg && moisture_tested) {
                        if(ws.total_dry_mgpg.twoDecimalFormattedValue) {
                            vm.sections[section_index].dry_mgpg_exists = true;
                            vm.sections[section_index].dry_mgpg_total_thc = ws['total_thc_dry_mgpg'] ? ws['total_thc_dry_mgpg'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].dry_mgpg_total_cbd = ws['total_cbd_dry_mgpg'] ? ws['total_cbd_dry_mgpg'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].dry_mgpg_total = ws['total_dry_mgpg'] ? ws['total_dry_mgpg'].twoDecimalFormattedValue : '';

                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'dry_mgpg';
                                vm.sections[section_index].active_unit_frontend = ' mg/g (dry)';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].dry_mgpg_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].dry_mgpg_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].dry_mgpg_total
                            }
                        }
                    }
                    if(ws.total_mgpg) {
                        if(ws.total_mgpg.twoDecimalFormattedValue) {
                            vm.sections[section_index].mgpg_exists = true;
                            vm.sections[section_index].mgpg_total_thc = ws['total_thc_mgpg'] ? ws['total_thc_mgpg'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpg_total_cbd = ws['total_cbd_mgpg'] ? ws['total_cbd_mgpg'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpg_total = ws['total_mgpg'] ? ws['total_mgpg'].twoDecimalFormattedValue : '';

                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'mgpg';
                                vm.sections[section_index].active_unit_frontend = ' mg/g';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].mgpg_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].mgpg_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].mgpg_total
                            }
                        }
                    }

                    if(ws.total_percent) {
                        if(ws.total_percent.twoDecimalFormattedValue) {
                            vm.sections[section_index].percent_exists = true;
                            vm.sections[section_index].percent_total_thc = ws['total_thc_percent'] ? ws['total_thc_percent'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].percent_total_cbd = ws['total_cbd_percent'] ? ws['total_cbd_percent'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].percent_total = ws['total_percent'] ? ws['total_percent'].twoDecimalFormattedValue : ''

                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'percent';
                                vm.sections[section_index].active_unit_frontend = '%';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].percent_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].percent_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].percent_total;
                            }
                        }
                    }
                    if(ws.total_mgpml) {
                        if(ws.total_mgpml.twoDecimalFormattedValue) {
                            vm.sections[section_index].mgpml_exists = true;
                            vm.sections[section_index].mgpml_total_thc = ws['total_thc_mgpml'] ? ws['total_thc_mgpml'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpml_total_cbd = ws['total_cbd_mgpml'] ? ws['total_cbd_mgpml'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpml_total = ws['total_mgpml'] ? ws['total_mgpml'].twoDecimalFormattedValue : '';

                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'mgpml';
                                vm.sections[section_index].active_unit_frontend = ' mg/ml';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].mgpml_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].mgpml_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].mgpml_total;
                            }
                        }
                    }
                    if(ws.total_mgpu) {
                        if(ws.total_mgpu.twoDecimalFormattedValue) {
                            vm.sections[section_index].mgpu_exists = true;
                            vm.sections[section_index].mgpu_total_thc = ws['total_thc_mgpu'] ? ws['total_thc_mgpu'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpu_total_cbd = ws['total_cbd_mgpu'] ? ws['total_cbd_mgpu'].twoDecimalFormattedValue : '';
                            vm.sections[section_index].mgpu_total = ws['total_mgpu'] ? ws['total_mgpu'].twoDecimalFormattedValue : '';

                            if(!vm.sections[section_index].active_unit) {
                                vm.sections[section_index].active_unit = 'mgpu';
                                vm.sections[section_index].active_unit_frontend = ' mg/serving';
                            }

                            if(!vm.sections[section_index].total_thc) {
                                vm.sections[section_index].total_thc = vm.sections[section_index].mgpu_total_thc;
                            }

                            if(!vm.sections[section_index].total_cbd) {
                                vm.sections[section_index].total_cbd = vm.sections[section_index].mgpu_total_cbd;
                            }

                            if(!vm.sections[section_index].total_cannabinoids) {
                                vm.sections[section_index].total_cannabinoids = vm.sections[section_index].mgpu_total;
                            }
                        }
                    }

                }
                console.log(vm.sections[section_index],"vm.sections[section_index]======");
                if(!vm.sections[section_index].overall_pf) vm.sections[section_index].overall_pf = 'in_progress';
                vm.cannabinoidsTested = vm.sections[section_index].overall_pf == 'in_progress' ? false : true;
            }
            parsers.homogeneity = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
                angular.noop;
            }
            parsers.water_activity = function(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
                generalParser(ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
            }
        }

        function parseWorksheet(ws_id, ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay) {
            angular.forEach(vm.parserMap, function(value, key) {
                if(value.includes(ws_id)) {
                    try {
                        parsers[key](ws, category, test_pf, units, show_result, instrument, complete_date, estimated_complete_date, assay);
                    }
                    catch(e) {
                        console.log('Error occured while parsing worksheet id: ', ws_id, category, ws, e);
                    }
                }
            });
        }

        function checkPotencyAndTerpenes() {
            if(angular.equals({}, vm.sample.potencyProfile)) {
                vm.sample.potencyProfile = false;
            }
            if(angular.equals({}, vm.sample.terpeneProfile)) {
                vm.sample.terpeneProfile = false;
            }
        }

        vm.sample = {
                name: null,
                company: null,
                id: null,
                sampleWeight: null,
                sampleType: null,
                starred: null,
                date: null,
                potencyProfile: {},
                terpeneProfile: {},
                canSeeReport: false
        };

        vm[POTENCY] = null;
        vm[TERPENES] = null;

        $scope.collapse = {
                potencyProfile: true,
                terpeneProfile: true,
        };

        if (data) {
        	for (var i = 0; i < data.sampleTests.length; i++){
        		var test = data.sampleTests[i];
        		if (!test.assay) continue;
        		if (test.worksheetData == null) test.worksheetData = {};

        		switch(test.assay.portalIdentifier) {
	        		case "POTENCY_GCFID":
	        		case "POTENCY_LCDAD":
	        		case "POTENCY_LCDAD_NON_PLANT":
	        		case "POTENCY_LCMSMS": setPotencyData(test); break;

	        		case "TERPENE_PROFILE": setTerpeneProfileData(test); break;
	        		default:
	        			if (test.assay.portal_section == "Terpenes") {
	        				setTerpeneProfileData(test);
                        }
	        			break;
        		}
            }
        }

        /* Set paging links (next/previous buttons) */
        vm.prevNext = {prev:null, next:null};
        vm.queryParams = {
        		page: $stateParams.page,
        		size: $stateParams.size,
        		sort: $stateParams.sort,
        		search: $stateParams.search,
        		productCategoryId: $stateParams.productCategoryId
        };
        vm.prevPage = parseInt(vm.queryParams.page);
        vm.nextPage = parseInt(vm.queryParams.page);

        vm.customerType = "";

        CustomerByOrder.get({orderId: data.customerOrderId}).$promise.then(function(c){
            vm.customer = c;
    		var type_of_business = c.businessType.name.toLowerCase();
            
            try {
                vm.customerType = c.businessType.name;
            }
            catch (error) {
                console.log(error);
            }
            
            if (type_of_business.includes('food')) {
                vm.customer.consolidated_business_type = 'food';
            }
            else {
                vm.customer.consolidated_business_type = 'cannabis';
            }
            if(vm.customer.downloadable_file_options){
                vm.customer.canSeeCOASummary = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "coaSummary").selected
                vm.customer.canSeeQRcode = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "qrCode").selected
                vm.customer.canSeeConsolidatedCOA = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "consolidatedCoa").selected
                vm.customer.canSeeTestCOA = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "testCoa").selected
                vm.customer.canSeePictures = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "pictures").selected
                vm.customer.canSeeAll = JSON.parse(vm.customer.downloadable_file_options).find(e => e.value == "all").selected
            }
        	if (c.logo) {
                vm.companyLogo = 'images/logo/' + vm.customer.logo;
        	}
        	if (c.address !== ',,,, ') {
                var splits = c.address.split('@');
        		vm.customerStreet = splits[0] ? splits[0].trim() : null;
        		vm.customerCity = splits[1] ? splits[1].trim() : null;
        		vm.customerState = splits[2] ? splits[2].trim() : null;
        		vm.customerCountry = splits[3] ? splits[3].trim() : null;
        		vm.customerZip = splits[4] ? splits[4].trim() : null;
                
        		if (vm.customerStreet || vm.customerCity || vm.customerState || vm.customerZip){
                    c.address = vm.customerStreet + ', ' + vm.customerCity + ' ' + vm.customerState + ', ' + vm.customerZip;
        		} else {
                    c.address = null;
        		}
                
                
        		vm.mapImageUrl = "/api/public/customers/" + c.id + "/map";
        	}
            
        	// If user is not signed in, set current customer being viewed
        	if (!$localStorage.authenticationToken || !sessionStorage.authenticationToken) {
                $localStorage.customer = {id: c.id, dbaName: c.dbaName};
        	}
            console.log();
        });
        $scope.toggleStarred = function(id, sample) {
        	Sample.toggleStarred({id: id},onSuccess,onError);

        	function onSuccess (data, headers) {
        		sample.starred = data.starred
        	}

        	function onError(error) {

		    }
        }

        function getPrevNextData(params, getPrev, getNext) {
        	var urlParams = {};
            if (params.page) urlParams.page = params.page - 1;
            if (params.size) urlParams.size = params.size;
            if (params.sort) urlParams.sort = params.sort;
            if (params.search) {
            	urlParams.query = params.search;
            } else if (params.productCategoryId) {
            	urlParams.productCategoryId = params.productCategoryId;
            }

            if (urlParams.query) {
                SampleSearch.query(urlParams, function(samples){
                	setPrevNext(samples, params, getPrev, getNext);
                }, function(error){
                    console.log(error);
                });
            } else {
                 return Sample.query(urlParams, function(samples){
                 	setPrevNext(samples, params, getPrev, getNext);
                 }, function(error){
                     console.log(error);
                 });
            }
        }

        function setPrevNext(samples, params, getPrev, getNext) {
        	var sampleHash = data.hash;
        	if (samples) {
        		if (getPrev) {
        			if (samples.length > 0) {
                        vm.prevNext.prev = samples[samples.length - 1].hash;
        			}
        		} else if (getNext) {
        			if (samples.length > 0) {
                        vm.prevNext.next = samples[0].hash;
        			}
        		} else {
            		for (var i = 0; i < samples.length; i++) {
            			if (samples[i].hash.indexOf(sampleHash) !== -1) {
            				if (i > 0) {
            					vm.prevNext.prev = samples[i-1].hash;
            				} else if (i == 0) {
            					vm.prevPage -= 1;
            					var newParams = angular.copy(params);
            					newParams.page = parseInt(params.page) - 1;
            					if (newParams.page > 0) {
            						getPrevNextData(newParams, true, false);
            					}
            				}
            				if (i+1 < samples.length) {
                                vm.prevNext.next = samples[i+1].hash;
            				} else if (i+1 >= samples.length) {
            					vm.nextPage += 1;
            					var newParams = angular.copy(params);
            					newParams.page = parseInt(params.page) + 1;
            					getPrevNextData(newParams, false, true);
            				}
            			}
            		}
        		}
        	}
        }
        getPrevNextData(vm.queryParams);

        setPageTitle();

        $scope.itemArray = [
            {id: 0, name: 'All', value: 'ALL'},
            {id: 1, name: 'Created', value: 'CREATED'},
            {id: 2, name: 'Samples Received', value: 'SAMPLES_RECEIVED'},
            {id: 3, name: 'Preliminary Results', value: 'PRELIMINARY_RESULTS'},
            {id: 4, name: 'Completed', value: 'COMPLETED'},
            {id: 5, name: 'Invoiced', value: 'INVOICED'}
        ];

        $scope.selected = { value: $scope.itemArray[0] };

        vm.potencyUnit = 'percent';

        vm.setPotencyFields = setPotencyFields;

        function setPageTitle(){
            if( $state.current.name === 'customerPublic') {
                $rootScope.pageTitle = "Customer public page";
            }

            if( $state.current.name === 'public') {
                $rootScope.pageTitle = vm.sample && vm.sample.company ? vm.sample.company : "Sample Results";
            }
        }

        if ($stateParams.hideMenu) {
            vm.hideMenu = true;
            $rootScope.$broadcast('hideMenu');

        }


        vm.togglePotencyUnit = togglePotencyUnit;

        google.charts.load('current', {packages: ['corechart']});

        function setPotencyFields(){

            vm.sample.potencyProfile = {};

            var potencyProfileFields = [];
            if (vm.potencyUnit == 'percent_dry') {

                 potencyProfileFields = [
                    's8thc_dry_percent',
                    's9thc_dry_percent',
                    'thca_dry_percent',
                    'thcv_dry_percent',
                    'thcva_dry_percent',
                    'cbd_dry_percent',
                    'cbda_dry_percent',
                    'cbdv_dry_percent',
                    'cbn_dry_percent',
                    'cbg_dry_percent',
                    'cbc_dry_percent',
                    'cbga_dry_percent',
                    'cbca_dry_percent'
                ];
            }
			else if (vm.potencyUnit == 'percent') {

                 potencyProfileFields = [
                    's8thc_percent',
                    's9thc_percent',
                    'thca_percent',
                    'thcv_percent',
                    'thcva_percent',
                    'cbd_percent',
                    'cbda_percent',
                    'cbdv_percent',
                    'cbn_percent',
                    'cbg_percent',
                    'cbc_percent',
                    'cbga_percent',
                    'cbca_percent'
                ];
            }
            else if (vm.potencyUnit == 'mg/g') {
                potencyProfileFields = [
                    's8thc_dry_mgpg',
                    's9thc_dry_mgpg',
                    'thca_dry_mgpg',
                    'thcv_dry_mgpg',
                    'thcva_dry_mgpg',
                    'cbd_dry_mgpg',
                    'cbda_dry_mgpg',
                    'cbdv_dry_mgpg',
                    'cbn_dry_mgpg',
                    'cbg_dry_mgpg',
                    'cbc_dry_mgpg',
                    'cbga_dry_mgpg',
                    'cbca_dry_mgpg'
                ];
            }
            else if (vm.potencyUnit == 'mg/ml') {
            	potencyProfileFields = [
                    's8thc_mgpml',
                    's9thc_mgpml',
                    'thca_mgpml',
                    'thcv_mgpml',
                    'thcva_mgpml',
                    'cbd_mgpml',
                    'cbda_mgpml',
                    'cbdv_mgpml',
                    'cbn_mgpml',
                    'cbg_mgpml',
                    'cbc_mgpml',
                    'cbga_mgpml',
                    'cbca_mgpml'
                ];
            }
            else if (vm.potencyUnit == 'mg/serving') {
            	potencyProfileFields = [
                    's8thc_mgpu',
                    's9thc_mgpu',
                    'thca_mgpu',
                    'thcv_mgpu',
                    'thcva_mgpu',
                    'cbd_mgpu',
                    'cbda_mgpu',
                    'cbdv_mgpu',
                    'cbn_mgpu',
                    'cbg_mgpu',
                    'cbc_mgpu',
                    'cbga_mgpu',
                    'cbca_mgpu'
                ];
            }
            else if (vm.potencyUnit == 'mg/pkg') {
            	potencyProfileFields = [
                    's8thc_mgpp',
                    's9thc_mgpp',
                    'thca_mgpp',
                    'thcv_mgpp',
                    'thcva_mgpp',
                    'cbd_mgpp',
                    'cbda_mgpp',
                    'cbdv_mgpp',
                    'cbn_mgpp',
                    'cbg_mgpp',
                    'cbc_mgpp',
                    'cbga_mgpp',
                    'cbca_mgpp'
                ];
            }

            if (vm[POTENCY]) {
                potencyProfileFields.forEach(function (item) {
                    if (vm[POTENCY].worksheetData[item]) {

                        vm.sample.potencyProfile[item] = vm[POTENCY].worksheetData[item];

						if (vm[POTENCY].worksheetData[item].value % 1 != 0){
							vm.sample.potencyProfile[item].twoDecimalFormattedValue = fmtTwoDecimals(vm[POTENCY].worksheetData[item]);
						} else {
							vm.sample.potencyProfile[item].twoDecimalFormattedValue = fmtTwoDecimals(vm[POTENCY].worksheetData[item])
						}

                        if (item.includes("s8thc")) {
                        	vm.sample.potencyProfile[item].display_name = '\u03B48 THC';
                        } else if (item.includes("s9thc")) {
                        	vm.sample.potencyProfile[item].display_name = '\u03B49 THC';
                        }

                    }
                });
            }

            checkPotencyAndTerpenes();
        }

        function togglePotencyUnit(){
            drawChart(vm.sample);
        }

		function fmtTwoDecimals(object) {
			if (object){
				if (object.value) {
        			return parseFloat(object.value).toFixed(2);
        		}
				else {
        			return '';
        		}
			}
			return '';
        }

        function drawChart(sample) {

            //moved into separate function so that we can switch units from drop down box
            vm.setPotencyFields();

            var data = new google.visualization.DataTable();

            data.addColumn('string', 'Status');
            data.addColumn('number', 'Count');

            var colors = [
                '#33699a',
                '#4f91cd',
                '#5db2ff',
                '#93ccff',
                '#f5b54e',
                '#ffd35e',
                '#f7cb56',
                '#d4602a',
                '#e88050',
                '#e99f7c',
                '#fab596'
            ];

            for (var i in sample.potencyProfile) {

                if (sample.potencyProfile[i]) {
                    var temp = [];

					if(!sample.potencyProfile[i].display_name){
						if (i.includes("thca_")){
							sample.potencyProfile[i].display_name = 'THCA';
						} else if (i.includes("thcv_")){
							sample.potencyProfile[i].display_name = 'THCV';
						} else if (i.includes("thcva_")){
							sample.potencyProfile[i].display_name = 'THCVA';
						} else if (i.includes("cbd_")){
							sample.potencyProfile[i].display_name = 'CBD';
						} else if (i.includes("cbda_")){
							sample.potencyProfile[i].display_name = 'CBDA';
						} else if (i.includes("cbdv_")){
							sample.potencyProfile[i].display_name = 'CBDV';
						} else if (i.includes("cbn_")){
							sample.potencyProfile[i].display_name = 'CBN';
						} else if (i.includes("cbg_")){
							sample.potencyProfile[i].display_name = 'CBG';
						} else if (i.includes("cbc_")){
							sample.potencyProfile[i].display_name = 'CBC';
						} else if (i.includes("cbga_")){
							sample.potencyProfile[i].display_name = 'CBGA';
						} else if (i.includes("cbca_")){
							sample.potencyProfile[i].display_name = 'CBCA';
						} else {
							sample.potencyProfile[i].display_name = 'ND';
						}
					}

					temp.push(sample.potencyProfile[i].display_name);
					temp.push(parseFloat(sample.potencyProfile[i].value));
                    data.addRow(temp);
                }
            }

            var options = {
                backgroundColor: 'transparent',
                pieHole: 0.7,
                pieSliceText: 'value',
                pieSliceTextStyle: {
                    color: 'black',
                    fontSize: 1,
                    opacity: 0, // hides text on chart
                    visibility: 'hidden'  // hides text on chart
                },
                tooltip: {
                    text: 'value'
                },
                chartArea: {
                    top: 30,
                    right: 25,
                    width: '100%',
                    height: '100%'
                },
                height: '100%',
                width: '100%',
                legend: {
                    position: 'left',
                    alignment: 'center',
                    maxLines: 2,
                    textStyle: {
                        color: '#003f7f',
                        fontSize: 12,
                        fontName: 'OpenSans-Regular',
                        marginBottom: 50,
                        bold: true,
                        toolText: '%'
                    }

                },
                colors: colors

            };

            var optionsMobile = {
                backgroundColor: 'transparent',
                pieHole: 0.7,
                pieSliceText: 'value',
                pieSliceTextStyle: {
                    color: 'black',
                    fontSize: 1,
                    opacity: 0, // hides text on chart
                    visibility: 'hidden'  // hides text on chart
                },
                tooltip: {
                    text: 'value'
                },
                chartArea: {
                    top: 30,
                    left: 0,
                    width: '100%',
                    height: 260
                },
                height: 400,
                width: 270,
                legend: {
                    position: 'bottom',
                    alignment: 'left',
                    maxLines: 1,
                    textStyle: {
                        color: '#003f7f',
                        fontSize: 12,
                        fontName: 'Open Sans',
                        bold: true,
                        toolText: '%'
                    }

                },
                colors: colors//,

            };

            var pre_defined_suffix = '%';

            if (vm.potencyUnit != 'percent') {
            	pre_defined_suffix = vm.potencyUnit;
            }


            var formatter = new google.visualization.NumberFormat({
                fractionDigits: 2,
                suffix: pre_defined_suffix
            });

            formatter.format(data, 1);

            if(data.getNumberOfRows() != 0){
                var chart = new google.visualization.PieChart(angular.element('#pie-chart')[0]);
                chart.draw(data, options);

                var chartSmall = new google.visualization.PieChart(angular.element('#pie-chart-mobile')[0]);
                chartSmall.draw(data, optionsMobile);
            }
        }

        var sampleHash = $stateParams.hash;

        if (data) {
            //show cached picture is exists
            if (data.cachedPictures != null && data.cachedPictures.length>0) {
                 vm.sample.picture = data.cachedPictures[data.cachedPictures.length - 1].url;
            }

            //call service to fetch new pictures
            var samplePictures = SampleService.getPublicPictures({hash: data.hash});
            samplePictures.$promise.then(function (data) {

                if (data.length > 0 && data[0]) {

                    vm.sample.picture = data[data.length - 1].url;
                }
                else {
                    vm.sample.picture = "content/images/no_image_available-small.png";

                }
            }, function (error) {



            });

            if(data.dueDate) {
                vm.due_date = new Date(data.dueDate);
            }

            // GET SOME BASIC DATA ABOUT SAMPLE
            if(data.starred){
                vm.sample.starred = data.starred
            }

            if (data.id) {
                vm.sample.id = data.id;
            }

            if (data.hash) {
                vm.sample.hash = data.hash;
            }

            if (data.name) {
                vm.sample.name = data.name;
            }

            if (data.canSeeReport) {
                vm.sample.canSeeReport = data.canSeeReport;
            }

            if (data.company) {
                vm.sample.company = data.company;
                $rootScope.pageTitle = vm.sample && vm.sample.company ? vm.sample.company : "Sample";
            }

            if (data.sampleWeight) {
                vm.sample.sampleWeight = data.sampleWeight;
            }

            if (data.productCategory && data.productCategory.name){
            	vm.sample.sampleType = data.productCategory.name;
            }
            if (data.productType && data.productType.name) {
                vm.sample.sampleType += ' (' + data.productType.name + ')';
            }

            if (data.date_created) {
                vm.sample.date = new Date(data.date_created);
            }

            if (data.lotBatchNumber) {
            	vm.sample.lotBatchNumber = data.lotBatchNumber;
            }

            if (data.customerOrderId) {
            	vm.sample.customerOrderId = data.customerOrderId;
            }

            var margin = {top: 40, right: 0, bottom: 0, left: 40},
                width = Math.min(300, window.innerWidth - 10) - margin.left - margin.right,
                height = Math.min(width, window.innerHeight - margin.top - margin.bottom - 20);

            /* Circle around the chart */
            /* Values */
            var values = [
                // GREEN
                parseFloat(vm.sample.terpeneProfile.terpinolene && vm.sample.terpeneProfile.terpinolene.value ? vm.sample.terpeneProfile.terpinolene.value : 0) || 0,
                parseFloat(vm.sample.terpeneProfile.eudesmol && vm.sample.terpeneProfile.eudesmol.value ? vm.sample.terpeneProfile.eudesmol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.alpha_pinene && vm.sample.terpeneProfile.alpha_pinene.value ? vm.sample.terpeneProfile.alpha_pinene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.bete_pinene && vm.sample.terpeneProfile.bete_pinene.value ? vm.sample.terpeneProfile.bete_pinene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.camphene && vm.sample.terpeneProfile.camphene.value ? vm.sample.terpeneProfile.camphene.value : 0) || 0,

                // BLUE
                parseFloat(vm.sample.terpeneProfile.eucalyptol && vm.sample.terpeneProfile.eucalyptol.value ? vm.sample.terpeneProfile.eucalyptol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.isopulegol && vm.sample.terpeneProfile.isopulegol.value ? vm.sample.terpeneProfile.isopulegol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.menthol && vm.sample.terpeneProfile.menthol.value ? vm.sample.terpeneProfile.menthol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.borneol && vm.sample.terpeneProfile.borneol.value ? vm.sample.terpeneProfile.borneol.value : 0)|| 0,

                // PURPLE
                parseFloat(vm.sample.terpeneProfile.trans_beta_ocimene && vm.sample.terpeneProfile.trans_beta_ocimene.value ? vm.sample.terpeneProfile.trans_beta_ocimene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.cis_beta_ocimene && vm.sample.terpeneProfile.cis_beta_ocimene.value ? vm.sample.terpeneProfile.cis_beta_ocimene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.geraniol && vm.sample.terpeneProfile.geraniol.value ? vm.sample.terpeneProfile.geraniol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.linalool && vm.sample.terpeneProfile.linalool.value ? vm.sample.terpeneProfile.linalool.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.guaiol && vm.sample.terpeneProfile.guaiol.value ? vm.sample.terpeneProfile.guaiol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.alpha_bisabolol && vm.sample.terpeneProfile.alpha_bisabolol.value ? vm.sample.terpeneProfile.alpha_bisabolol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.alpha_terpinene && vm.sample.terpeneProfile.alpha_terpinene.value ? vm.sample.terpeneProfile.alpha_terpinene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.citronellol && vm.sample.terpeneProfile.citronellol.value ? vm.sample.terpeneProfile.citronellol.value : 0)|| 0,

                // YELLOW
                parseFloat(vm.sample.terpeneProfile.delta_3_carene && vm.sample.terpeneProfile.delta_3_carene.value ? vm.sample.terpeneProfile.delta_3_carene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.cis_nerolidol && vm.sample.terpeneProfile.cis_nerolidol.value ? vm.sample.terpeneProfile.cis_nerolidol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.trans_nerolidol && vm.sample.terpeneProfile.trans_nerolidol.value ? vm.sample.terpeneProfile.trans_nerolidol.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.limonene && vm.sample.terpeneProfile.limonene.value ? vm.sample.terpeneProfile.limonene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.p_cymene && vm.sample.terpeneProfile.p_cymene.value ? vm.sample.terpeneProfile.p_cymene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.gamma_terpinene && vm.sample.terpeneProfile.gamma_terpinene.value ? vm.sample.terpeneProfile.gamma_terpinene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.aplha_terpineol && vm.sample.terpeneProfile.aplha_terpineol.value ? vm.sample.terpeneProfile.aplha_terpineol.value : 0)|| 0,

                // RED
                parseFloat(vm.sample.terpeneProfile.eszett_myrcene && vm.sample.terpeneProfile.eszett_myrcene.value ? vm.sample.terpeneProfile.eszett_myrcene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.caryophylleneoxide && vm.sample.terpeneProfile.caryophylleneoxide.value ? vm.sample.terpeneProfile.caryophylleneoxide.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.alpha_Humulene && vm.sample.terpeneProfile.alpha_Humulene.value ? vm.sample.terpeneProfile.alpha_Humulene.value : 0)|| 0,
                parseFloat(vm.sample.terpeneProfile.eszett_caryophyllene && vm.sample.terpeneProfile.eszett_caryophyllene.value ? vm.sample.terpeneProfile.eszett_caryophyllene.value : 0)|| 0

            ];

            /* get max number */
            var max = Math.max.apply(Math, values);
            if (max % 1 === 0) {
                max = max + 0.01;
            }

            var maxNew = Math.ceil(max);

            if (maxNew - 0.5 > max) {
                max = maxNew - 0.5;
            } else {
                max = maxNew;
            }

            var radarChartData = [
                [
                    // GREEN //
                    {axis: "Terpinolene", value: values[0]},
                    {axis: "Eudesmol", value: values[1]},
                    {axis: "α-Pinene", value: values[2]},
                    {axis: "β-Pinene", value: values[3]},
                    {axis: "Camphene", value: values[4]},

                    // BLUE //
                    {axis: "Eucalyptol", value: values[5]},
                    {axis: "Isopulegol", value: values[6]},
                    {axis: "Menthol", value: values[7]},
                    {axis: "Borneol", value: values[8]},

                    // PURPLE //
                    {axis: "Trans-beta-Ocimene", value: values[9]},
                    {axis: "Cis-beta-Ocimene", value: values[10]},
                    {axis: "Geraniol", value: values[11]},
                    {axis: "Linalool", value: values[12]},
                    {axis: "Guaiol", value: values[13]},
                    {axis: "α-Bisabolol", value: values[14]},
                    {axis: "α-Terpineol", value: values[15]},
                    {axis: "Citronellol", value: values[16]},

                    // YELLOW //
                    {axis: "δ-3-Carene", value: values[17]},
                    {axis: "Cis-Nerolidol", value: values[18]},
                    {axis: "Trans-Nerolidol", value: values[19]},
                    {axis: "Limonene", value: values[20]},
                    {axis: "P-Cymene", value: values[21]},
                    {axis: "γ-Terpinene", value: values[22]},
                    {axis: "α-Terpinene", value: values[23]},

                    // RED //
                    {axis: "ß-Myrcene", value: values[24]},
                    {axis: "Caryoph. Oxide", value: values[25]},
                    {axis: "α-Humulen", value: values[26]},
                    {axis: "ß-Caryophyllene", value: values[27]},

                ]
            ];

            var color = d3.scale.ordinal()
                .range(["#440e62", "#ffffff"]);

            var radarChartOptions = {
                w: width,
                h: height,
                margin: margin,
                maxValue: max,
                levels: max * 2,
                roundStrokes: true,
                color: color,
                format: '%'
            };


            //Call function to draw the Radar chart
            RadarChart(".chart2", radarChartData, radarChartOptions);

            var canvas = document.getElementsByTagName("canvas")[0];


            if (canvas) {
                var context = canvas.getContext("2d");

                context.beginPath();
                context.arc(170, 170, 145, 341 * (Math.PI / 180), 271 * (Math.PI / 180), true);
                context.strokeStyle = "#ffffff";
                context.stroke();

                context.beginPath();
                context.arc(170, 170, 145, 269 * (Math.PI / 180), 199 * (Math.PI / 180), true); // top left
                context.stroke();

                context.beginPath();
                context.arc(170, 170, 145, 197 * (Math.PI / 180), 127 * (Math.PI / 180), true); // bottom left
                context.stroke();

                context.beginPath();
                context.arc(170, 170, 145, 125 * (Math.PI / 180), 56 * (Math.PI / 180), true); // bottom left
                context.stroke();

                context.beginPath();
                context.arc(170, 170, 145, 54 * (Math.PI / 180), 343 * (Math.PI / 180), true); // bottom right
                context.stroke();

                /* Circle quadrants */

                context.beginPath();
                context.arc(170, 170, 130, 342 * (Math.PI / 180), 270 * (Math.PI / 180), true);
                context.fillStyle = "#669A8B";
                context.lineTo(170, 170);
                context.closePath();
                context.fill();

                context.beginPath();
                context.arc(170, 170, 130, 29 * (Math.PI / 180), 342 * (Math.PI / 180), true); // bottom right
                context.fillStyle = "#00BFC6";
                context.lineTo(170, 170);
                context.closePath();
                context.fill();

                context.beginPath();
                context.arc(170, 170, 130, 120 * (Math.PI / 180), 29 * (Math.PI / 180), true); // bottom right
                context.fillStyle = "#F6DBB9";
                context.lineTo(170, 170);
                context.closePath();
                context.fill();

                context.beginPath();
                context.arc(170, 170, 130, 198 * (Math.PI / 180), 120 * (Math.PI / 180), true); // bottom left
                context.fillStyle = "#F5B54E";
                context.lineTo(170, 170);
                context.closePath();
                context.fill();

                context.beginPath();
                context.arc(170, 170, 130, 270 * (Math.PI / 180), 198 * (Math.PI / 180), true); // top left
                context.fillStyle = "#FF6A59";
                context.lineTo(170, 170);
                context.closePath();
                context.fill();
            }

            google.charts.setOnLoadCallback(function () {

                drawChart(vm.sample);

            });
            getAttachments();

        }


        function setTerpeneProfileData(test) {
    		vm[TERPENES] = test;
    		$scope.collapse[TERPENES] = false;

        	if (test.worksheetData) {
                // TERPENE PROFILE DATA STARTS HERE
                var terpeneProfileFields = [

                    'terpinolene',      'alpha_bisabolol',
                    'eudesmol',         'alpha_terpinene',
                    'alpha_pinene',     'citronellol',
                    'bete_pinene',      'delta_3_carene',
                    'camphene',         'cis_nerolidol',
                    'eucalyptol',       'trans_nerolidol',
                    'isopulegol',       'limonene',
                    'menthol',          'p_cymene',
                    'borneol',          'gamma_terpinene',
                    'trans_beta_ocimene','aplha_Terpineol',
                    'cis_beta_ocimene', 'eszett_myrcene',
                    'geraniol',         'caryophylleneoxide',
                    'Linalool',         'alpha_Humulene',
                    'guaiol',           'eszett_caryophyllene'

                ];

                var taste = [

                    'piney',	'sweet',
                    'piney',	'sweet',
                    'piney',	'sweet',
                    'piney',	'citrus',
                    'piney',	'citrus',
                    'minty',	'citrus',
                    'minty',	'citrus',
                    'minty',	'citrus',
                    'minty',	'citrus',
                    'sweet',	'citrus',
                    'sweet',	'spicy',
                    'sweet',	'spicy',
                    'sweet',	'spicy',
                    'sweet',    'spicy'

                ];

                terpeneProfileFields.forEach(function (item, index) {
                    if (test.worksheetData[item]) {
                    	if (test.worksheetData[item].display_name.includes("ÃŸ"))
                    		test.worksheetData[item].display_name = test.worksheetData[item].display_name.replace("ÃŸ","ß")

                        vm.sample.terpeneProfile[item] = test.worksheetData[item];
                        vm.sample.terpeneProfile[item].taste = taste[index];

                    } else {

                        vm.sample.terpeneProfile[item] = {display_name: 'Missing ' + item};
                        vm.sample.terpeneProfile[item].taste = taste[index];

                    }



					// if terpene data contains 'ND' and a number then set the value to just the number
					if (vm.sample.terpeneProfile[item].value){
						if (vm.sample.terpeneProfile[item].value.includes('ND') && hasNumber(vm.sample.terpeneProfile[item].value)){
							vm.sample.terpeneProfile[item].value = vm.sample.terpeneProfile[item].value.replace('ND','')
						}
					}

                });

        	}

        	if (test.results)
        		vm.sample.terpeneProfile[test.assay.title] = {'display_name': test.assay.title, 'value': test.results}
        }

		function hasNumber(myString) {
  			return /\d/.test(myString);
        }

        function setPotencyData(test) {
    		vm[POTENCY] = test;
    		$scope.collapse[POTENCY] = false;

        	if (test.worksheetData) {

            	vm.sample.totalThcPercent = fmtTwoDecimals(test.worksheetData.total_thc_dry_percent);
                vm.sample.totalThcMgpg =  fmtTwoDecimals(test.worksheetData.total_thc_mgpg);
                vm.sample.totalThcDryPercent = fmtTwoDecimals(test.worksheetData.total_thc_dry_percent);
                vm.sample.totalThcDryMgpg = fmtTwoDecimals(test.worksheetData.total_thc_dry_mgpg);
                vm.sample.totalThcMgpu = fmtTwoDecimals(test.worksheetData.total_thc_mgpu);
                vm.sample.totalThcMgpml = fmtTwoDecimals(test.worksheetData.total_thc_mgpml);
                vm.sample.totalThcMgpp = fmtTwoDecimals(test.worksheetData.total_thc_mgpp);

                vm.sample.totalCbdPercent = fmtTwoDecimals(test.worksheetData.total_cbd_percent);
                vm.sample.totalCbdMgpg = fmtTwoDecimals(test.worksheetData.total_cbd_mgpg);
                vm.sample.totalCbdDryPercent = fmtTwoDecimals(test.worksheetData.total_cbd_dry_percent);
                vm.sample.totalCbdDryMgpg = fmtTwoDecimals(test.worksheetData.total_cbd_dry_mgpg);
                vm.sample.totalCbdMgpu = fmtTwoDecimals(test.worksheetData.total_cbd_mgpu);
                vm.sample.totalCbdMgpml = fmtTwoDecimals(test.worksheetData.total_cbd_mgpml);
                vm.sample.totalCbdMgpp = fmtTwoDecimals(test.worksheetData.total_cbd_mgpp);

                vm.sample.totalPercent = fmtTwoDecimals(test.worksheetData.total_percent);
                vm.sample.totalMgpg = fmtTwoDecimals(test.worksheetData.total_mgpg);
                vm.sample.totalDryPercent = fmtTwoDecimals(test.worksheetData.total_dry_percent);
                vm.sample.totalDryMgpg = fmtTwoDecimals(test.worksheetData.total_dry_mgpg);
                vm.sample.totalMgpu = fmtTwoDecimals(test.worksheetData.total_mgpu);
                vm.sample.totalMgpml = fmtTwoDecimals(test.worksheetData.total_mgpml);
                vm.sample.totalMgpp = fmtTwoDecimals(test.worksheetData.total_mgpp);

        	}
        }


        $scope.getTotalThc = getTotalThc;

        function getTotalThc(sample) {
        	if (!sample.sampleTests) return;
        	for (var i = 0; i < sample.sampleTests.length; i++){
        		var test = sample.sampleTests[i];
        		if (!test.assay || !test.assay.portalIdentifier) continue;
        		var assay = test.assay.portalIdentifier;
        		if (assay === 'POTENCY_LCDAD' || assay === 'POTENCY_GCFID' || assay === 'POTENCY_LCMSMS' || assay === 'POTENCY_LCDAD_NON_PLANT') {
        			if (test.worksheetData && test.worksheetData.total_dry_percent && test.worksheetData.total_dry_percent.twoDecimalFormattedValue) {
        				return test.worksheetData.total_dry_percent.twoDecimalFormattedValue + ' %';
        			}
        		}
        	}
        	return 'N/A';
        }

        vm.downloadReport = function () {
            var sample = vm.sample;

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            SampleCoaPdfService.downloadPdf(sample.hash).then(function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });

                a.href = window.URL.createObjectURL(file);
                a.download = sample.id + '_COA_Summary.pdf';
                a.click();
            });
		}

        $('#testtaginput').tagsinput({
			tagClass: function(item) {
				return 'custom-label label-email'
			}
		});

		$('#testtaginput').on('beforeItemAdd', function(event) {
			var email_re = "^\S+@\S+$";
			if (!event.item.match(/\S+@\S+\.\S+/)) {
				$('#emailValidation').show();
				event.cancel = true;
			} else {
				$('#emailValidation').hide();
				$('#testtaginput').tagsinput('input')[0].placeholder = '';
			}
		});

		$('#testtaginput').on('beforeItemRemove', function(event) {
			if ($('#testtaginput').tagsinput('input')[0].value.length > 0)
				event.cancel = true;
			  // event.item: contains the item
			  // event.cancel: set to true to prevent the item getting removed
		});

        $('#testtaginput').tagsinput('input')[0].type = 'email'

        vm.emailsPending = [""];
        vm.emailMessage = "";

        vm.sendPublicEmail = function (form) {
        	vm.emailsPending = $('#testtaginput').tagsinput('items')
        	vm.emailSubject = $('#emailSubject').val();
        	//Send The Email
        	if (form.$valid && vm.emailsPending.length > 0){
        		$('#sendPublicSampleButton').attr("disabled", true);
        		Sample.sendEmail({
        			emails: vm.emailsPending,
        			message: vm.emailMessage,
        			public_link: vm.emailSamplePublicLink,
        			pic_url: vm.sample.picture,
        			id: vm.sample.id,
        			totalTHC: getTotalThc(vm.sample),
        			subject: vm.emailSubject
        		}, onSuccess, onError);
        	}
        	else {
        		if (vm.emailsPending.length == 0)
        			$('#emailValidation').show();
        		return
        	}
        	function onSuccess(data, headers) {
        		$('#emailModal').modal('hide');
        		$('#emailModalSuccess').modal('show');
        		$('#sendPublicSampleButton').attr("disabled", false);

	        }

	        function onError(error) {
	        	AlertService.error(error.data.message);
	        	vm.emailsPending = [""];
	            vm.emailMessage = "";
	            $('#sendPublicSampleButton').attr("disabled", false);

	        }
        }

    $('#emailModalSuccess').on('hidden.bs.modal', function() {
        	$('#testtaginput').tagsinput('removeAll');
        	$('#testtaginput').tagsinput('input')[0].placeholder = 'email@example.com';
        })

        vm.copyLink = function(id) {
    	  /* Get the text field */
    	  var copyText = document.getElementById(id);
    	  /* Select the text field */
    	  copyText.select();
    	  copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    	  /* Copy the text inside the text field */
    	  document.execCommand("copy");
        }

        vm.currentPublicSample = null

        vm.createSamplePublicLink = function(sample) {
        	var hash = $stateParams.hash;
        	var fullUrl = window.location.origin +"/#/ps/"+ $stateParams.hash;
        	vm.currentPublicSample = sample;
        	return fullUrl;
        }

        
        vm.downloadAttachment = function (id_hash ,sample) {
            if(event.target.value == "reportSummary"){
                if(vm.sample.canSeeReport && vm.customerType.length > 0 && !vm.customerType.includes('Food')){
                    vm.downloadReport()
                }
            }
            else if(id_hash) {
                if(id_hash == 'coa') {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    SampleCoaPdfService.downloadPdf(sample.hash).then( function(result) {
                        var file = new Blob([result.data], {
                            type: 'application/pdf'
                        });

                        a.href = window.URL.createObjectURL(file);
                        a.download = sample.id + '_COA_Summary.pdf';
                        a.click();
                    });
                }
                else if(['california', 'colorado'].includes(id_hash)) {
                    SampleAttachDownload.getBadge({text: window.location.host + '/#/ps/' + sample.hash, state: id_hash}, onSuccess, onError);
                    var capitalizedState = id_hash.charAt(0).toUpperCase() + id_hash.slice(1);

                    function onSuccess(data, headers) {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";

                        var fileURL = window.URL.createObjectURL(data.data);
                        a.href = fileURL;
                        a.download = sample.id + '_' + capitalizedState + '_QR_Code_Badge.png';
                        a.click();
                    }
                    function onError(error) {
                        console.log(error);
                    }

                }
                else {
                    SampleAttachDownload.get({id: id_hash}, onSuccess, onError);

                    function onSuccess(data, headers) {
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";

                        var fileURL = window.URL.createObjectURL(data.data);
                        a.href = fileURL;
                        a.download = headers('content-disposition').substring(21);
                        a.click();
                    }
                    function onError(error) {
                        console.log(error);
                    }
                }
            }
            sample.select = "";
        }
    }

    function transition() {
        $rootScope.sampleLogCategoryId = vm.productCategoryId;
        $rootScope.sampleSelectedFilter = $scope.selected;

        $state.transitionTo($state.$current, {
            page: vm.page,
            sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
            search: vm.currentSearch,
            items: vm.itemsPerPage,
            productCategoryId: vm.productCategoryId
        });
    }

    angular.element(document).ready(function() {
        setTimeout(function() {

          $(".chart-legend span").each(function(index, element) {
              index++;
              $(this).addClass("span-col-" + index);
          });

          $(window).on('resize load', function() {

            var window = $( window ).width();
            var num_col = $(".chart-legend span").length;

            if(window <= 600) {
              var col_width = 100 / num_col;
              var width = col_width - 1;
              $(".chart-legend span").css("width", width + "%");
            }

          });

          $("#col-prev").click(function() {
              $('.span-col-1').show();
              $('.span-col-2').show();
              $('.span-col-3').show();

              $('.span-col-4').hide();
              $('.span-col-5').hide();

              $('.chart-swiper div').text(' 1/2 ');

              $("#col-prev").attr('disabled', 'disabled');
              $("#col-next").prop("disabled", false);
          });

          $("#col-next").click(function() {

            $('.span-col-4').show();
            $('.span-col-5').show();

            $('.span-col-1').hide();
            $('.span-col-2').hide();
            $('.span-col-3').hide();

            $('.chart-swiper div').html('2/2 ');

            $("#col-next").attr('disabled', 'disabled');
            $("#col-prev").prop("disabled", false);

        });

        }, 1000);
    });


})();
